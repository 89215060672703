import { ExclamationCircleFilled, PlusOutlined } from "@ant-design/icons";
import type { ColumnsState } from "@ant-design/pro-components";
import { useMutation } from "@apollo/client";
import { Button, Modal, Space, Tag, Tooltip } from "antd";
import { useEffect, useState } from "react";
import { MdDelete, MdEdit, MdPublish, MdPushPin } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { ProColumnsProps } from "../../../../components/table/pro-table/ProTable";
import { useAuth } from "../../../../contexts/auth";
import { useMain } from "../../../../contexts/main";
import { SimpleModel } from "../../../../models/simplemodel.interface";
import { clientEvent } from "../../../../services/graphql.service";
import { EventsRoutes } from "../../routes.enum";
import {
  deleteEventGroup,
  publishEventGroup,
  setActualEventGroup,
} from "../../services/events.service";

export type EventGroupModel = {
  id: string;
  code: string;
  name: string;
  description: string;
  date: Date;
  start: Date;
  end: Date;
  actual: boolean;
  status: EventGroupStatusEnum;
  regional: SimpleModel;
};

export interface Response<T> {
  nodes: T[];
  pageInfo: {
    hasNextPage: boolean;
    hasPreviousPage: boolean;
  };
  totalCount: number;
}

enum Sort {
  ASC = "ASC",
  DESC = "DESC",
}

export enum EventGroupStatusEnum {
  Pending = "PENDING",
  Doing = "MAKING_THE_LIST",
  ListApproval = "LIST_APPROVAL",
  ListValidation = "LIST_VALIDATION",
  Published = "PUBLISHED",
}

export const getDescriptionStatus = (status: EventGroupStatusEnum) => {
  if (status == EventGroupStatusEnum.Pending) return "Pendente";
  if (status == EventGroupStatusEnum.Doing) return "Em preenchimento";
  if (status == EventGroupStatusEnum.ListApproval) return "Aprovada";
  if (status == EventGroupStatusEnum.ListValidation) return "Validada";
  if (status == EventGroupStatusEnum.Published) return "Publicada";
  return status;
};

export const getColorStatus = (status: EventGroupStatusEnum) => {
  if (status == EventGroupStatusEnum.Pending) return "processing";
  if (status == EventGroupStatusEnum.Published) return "success";

  return "warning";
};

const { confirm } = Modal;

export const EventGroupsFunctions = () => {
  const navigate = useNavigate();
  const { errorMessage, handleChangeBreadcumb, setTitlePage } = useMain();
  const { hasPermission } = useAuth();
  const title = "Reuniões";

  useEffect(() => {
    handleChangeBreadcumb([
      {
        title: "Home",
        path: "/",
      },
      {
        title: title,
      },
    ]);
    setTitlePage(title);
  }, []);

  const onClickCreate = () => {
    navigate(EventsRoutes.EventGroupCreate);
  };

  const onClickEdit = (id: string) => {
    const url = EventsRoutes.EventGroupEdit.replace(":id", id);
    navigate(url);
  };

  const [publishMutation] = useMutation(publishEventGroup(), {
    client: clientEvent,
    errorPolicy: "all",
    onCompleted: () => {
      setVariables({ ...variables });
    },
    onError: ({ graphQLErrors, networkError }) => {
      if (graphQLErrors)
        graphQLErrors.forEach(({ message, locations, path }) =>
          errorMessage(message)
        );
      if (networkError)
        errorMessage("Ocorreu um erro inesperado. Tente novamente mais tarde!");
    },
  });

  const onClickPublish = (id: string) => {
    return publishMutation({
      variables: {
        id,
      },
    });
  };

  const [deleteMutation] = useMutation(deleteEventGroup(), {
    client: clientEvent,
    errorPolicy: "all",
    onCompleted: () => {
      setVariables({ ...variables });
    },
    onError: ({ graphQLErrors, networkError }) => {
      if (graphQLErrors)
        graphQLErrors.forEach(({ message, locations, path }) =>
          errorMessage(message)
        );
      if (networkError)
        errorMessage("Ocorreu um erro inesperado. Tente novamente mais tarde!");
    },
  });

  const onClickDelete = (id: string) => {
    return deleteMutation({
      variables: {
        id,
      },
    });
  };

  const showDeleteConfirm = (id: string, name: string) => {
    confirm({
      title: `Deseja deletar a reunião: ${name}?`,
      icon: <ExclamationCircleFilled />,
      content: "Você realmente deseja deletar este item?",
      okText: "Sim",
      okButtonProps: {
        style: { background: "var(--primary-color)" },
      },
      cancelText: "Não",
      onOk() {
        return onClickDelete(id);
      },
      onCancel() {},
    });
  };

  const [setActualMutation] = useMutation(setActualEventGroup(), {
    client: clientEvent,
    errorPolicy: "all",
    onCompleted: () => {
      setVariables({ ...variables });
    },
    onError: ({ graphQLErrors, networkError }) => {
      if (graphQLErrors)
        graphQLErrors.forEach(({ message, locations, path }) =>
          errorMessage(message)
        );
      if (networkError)
        errorMessage("Ocorreu um erro inesperado. Tente novamente mais tarde!");
    },
  });

  const onClickSetActual = (id: string) => {
    return setActualMutation({
      variables: {
        id,
      },
    });
  };

  const showPublishedConfirm = (id: string, name: string) => {
    confirm({
      title: `Deseja publicar a reunião: ${name}?`,
      icon: <ExclamationCircleFilled />,
      content: "Você realmente deseja publicar este item?",
      okText: "Sim",
      okButtonProps: {
        style: { background: "var(--primary-color)" },
      },
      cancelText: "Não",
      onOk() {
        return onClickPublish(id);
      },
      onCancel() {},
    });
  };

  const showSetActualConfirm = (id: string, name: string) => {
    confirm({
      title: `Deseja tornar a reunião ${name} como atual?`,
      icon: <ExclamationCircleFilled />,
      content: "Você realmente deseja tornar este item como atual?",
      okText: "Sim",
      okButtonProps: {
        style: { background: "var(--primary-color)" },
      },
      cancelText: "Não",
      onOk() {
        return onClickSetActual(id);
      },
      onCancel() {},
    });
  };

  const toolBarRender = () => [
    hasPermission(["admin", "admin-system"]) && (
      <Button
        key="button"
        icon={<PlusOutlined />}
        onClick={onClickCreate}
        type="primary">
        Novo
      </Button>
    ),
  ];

  const columns = (): ProColumnsProps<EventGroupModel>[] => [
    {
      title: "Nome",
      dataIndex: "name",
      filters: true,
      onFilter: true,
      ellipsis: true,
      sorter: true,
    },
    {
      title: "Status",
      dataIndex: "status",
      filters: true,
      onFilter: true,
      ellipsis: true,
      sorter: true,
      renderFormItem: (_, { defaultRender }) => {
        return defaultRender(_);
      },
      render: (_, record) => (
        <Tag color={getColorStatus(record?.status)}>
          {getDescriptionStatus(record?.status)}
        </Tag>
      ),
    },
    {
      title: "Data",
      dataIndex: "date",
      filters: true,
      onFilter: true,
      ellipsis: true,
      sorter: true,
      renderFormItem: (_, { defaultRender }) => {
        return defaultRender(_);
      },
      render: (_, record) => new Date(record.date).toLocaleDateString(),
    },
    {
      title: "Regional",
      dataIndex: "regional",
      search: false,
      renderFormItem: (_, { defaultRender }) => {
        return defaultRender(_);
      },
      render: (_, record) => record.regional?.name,
    },
    {
      title: "Ações",
      key: "actions",
      search: false,
      width: 180,
      align: "center",
      permission: ["admin", "admin-system"],
      render: (_, record) => [
        <Space>
          {record.status !== EventGroupStatusEnum.Published && (
            <Tooltip title="Publicar">
              <Button
                type="link"
                shape="circle"
                icon={<MdPublish />}
                onClick={() => showPublishedConfirm(record.id, record.name)}
              />
            </Tooltip>
          )}
          {record.status === EventGroupStatusEnum.Published && !record.actual &&<Tooltip title="Tornar atual">
            <Button
              type="link"
              shape="circle"
              icon={<MdPushPin />}
              onClick={() => showSetActualConfirm(record.id, record.name)}
            />
          </Tooltip>}         
          {record.status === EventGroupStatusEnum.Published && record.actual &&<Tooltip title="Tornar atual">
            <Button
              type="link"
              shape="circle"
              icon={<MdPushPin />}
              disabled
            />
          </Tooltip>}     
          <Tooltip title="Editar reunião">
            <Button
              type="link"
              shape="circle"
              icon={<MdEdit />}
              onClick={() => onClickEdit(record.id)}
            />
          </Tooltip>
          <Tooltip title="Deletar reunião">
            <Button
              type="link"
              shape="circle"
              icon={<MdDelete />}
              onClick={() => showDeleteConfirm(record.id, record.name)}
            />
          </Tooltip>
        </Space>,
      ],
    },
  ];

  const whereDefault = {
    name: {
      contains: "",
    },
  };

  const orderDefault = { date: Sort.DESC };

  const [variables, setVariables] = useState({
    skip: 0,
    take: 10,
    where: whereDefault,
    order: { date: Sort.DESC },
  });

  const columnsMap = {
    name: {
      disable: true,
      show: true,
    },
    status: {
      show: true,
    },
    date: {
      show: true,
    },
    regional: {
      show: true,
    },
  } as Record<string, ColumnsState>;

  return {
    columns,
    columnsMap,
    clientEvent,
    whereDefault,
    orderDefault,
    variables,
    setVariables,
    toolBarRender
  };
};
