import { Content } from "antd/es/layout/layout";
import React, { useState } from "react";
import { Navigate } from "react-router-dom";
import { AppRoute } from "../../models/appRoute.model";
import { useAuth } from "../../contexts/auth";
import { Layout } from "../layout/Layout";

interface PrivateRouteProps {
  routes: AppRoute[];
  component: any;
  redirectPath?: string;
}

const PrivateRoute: React.FC<PrivateRouteProps> = ({
  redirectPath = "/login",
  routes,
  component,
  ...rest
}) => {
  const { isAuthentication } = useAuth();
  if (!isAuthentication()) {
    return <Navigate to={redirectPath} replace />;
  }

  return (
    <Layout
      routes={routes}
      children={
        <Content {...rest}>
          {component}
        </Content>
      }
    />
  );
};

export default PrivateRoute;
