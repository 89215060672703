import { Skeleton } from "antd";
import { UpdateAvatar } from "../../../../../components/avatar";
import { useAuth } from "../../../../../contexts/auth";

export const UserAvatar = () => {
  const { auth } = useAuth();

  return (
    <>
    {!auth.currentUser && <Skeleton.Avatar active size={150} />}
    {auth.currentUser && (
      <UpdateAvatar
        src={
          auth.currentUser?.photoURL ??
          "https://sge.ccbbh.com.br/images/logo.svg"
        }
        onCancel={() => {}}
      />
    )}
    </>
  );
};
