import { AppRoute } from "../../models/appRoute.model";
import { MyUser } from "./pages/my-user/MyUser";
import { MyUserRoutes } from "./routes.enum";

const getMyUserRoutes = (): AppRoute[] => [
  new AppRoute({
    path: MyUserRoutes.MyUser,
    component: <MyUser />,
    permission: ["admin-system", "admin", "user"],
  }),
];

export { getMyUserRoutes };
