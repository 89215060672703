import { Button, Card, Col, Form, Input, Row, Select, Skeleton } from "antd";
import { CkEditorFull } from "../../../../../components/editor/ckeditor/Ckeditor";
import { BlogFormFunctions } from "./BlogForm.functions";
import style from "./BlogForm.module.scss";

export const BlogForm = () => {
  const {
    id,
    form,
    formRef,
    onFinish,
    loadingGet,
    loading,
    categorias,
    loadingCategoriassGet,
    tags,
    loadingTagsGet,
    content,
    setContent,
  } = BlogFormFunctions();

  return (
    <Card className={style["form-card"]}>
      <Skeleton loading={loadingGet} active />
      <Form
        form={form}
        ref={formRef}
        hidden={loadingGet}
        className={style["form"]}
        name="user-form"
        layout="vertical"
        onFinish={onFinish}
        autoComplete="off"
      >
        <Form.Item>
          <Row gutter={[16, 16]}>
            <Col span={24} lg={8}>
              <Form.Item
                label="Título curto"
                name="breadCrumb"
                rules={[{ required: true, message: "O título é obrigatório!" }]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={24} lg={16}>
              <Form.Item
                label="Título"
                name="title"
                rules={[{ required: true, message: "O título é obrigatório!" }]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={24} lg={6}>
              <Form.Item
                label="Categoria"
                name="categoryId"
                rules={[
                  { required: true, message: "A categoria é obrigatório!" },
                ]}
              >
                <Select
                  options={categorias}
                  loading={loadingCategoriassGet}
                  style={{
                    width: "100%",
                  }}
                />
              </Form.Item>
            </Col>
            <Col span={24} lg={8}>
              <Form.Item
                label="URL"
                name="code"
                help={
                  !id &&
                  "A URL é gerada automaticamente com base no título dado para o artigo."
                }
              >
                <Input disabled={!id} />
              </Form.Item>
            </Col>
            <Col span={24} lg={10}>
              <Form.Item label="TAGs" name="tags">
                <Select
                  mode="tags"
                  options={tags}
                  loading={loadingTagsGet}
                  style={{
                    width: "100%",
                  }}
                />
              </Form.Item>
            </Col>
          </Row>
        </Form.Item>

        <Form.Item>
          <Form.Item
            label="Conteúdo"
            rules={[{ required: true, message: "O título é obrigatório!" }]}
          >
            <CkEditorFull
              data={content ?? "<p></p>"}
              onChange={(event: any, editor: any) => {
                setContent(editor.getData());
              }}
            />
          </Form.Item>
        </Form.Item>

        <Form.Item className={style["button-save"]} wrapperCol={{ span: 16 }}>
          <Button type="primary" htmlType="submit" loading={loading}>
            Salvar
          </Button>
        </Form.Item>
      </Form>
    </Card>
  );
};
