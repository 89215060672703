import { CKEditor } from "@ckeditor/ckeditor5-react";
import { useEffect, useRef, useState } from "react";

import {
  Alignment,
  Autoformat,
  AutoLink,
  Autosave,
  BalloonToolbar,
  BlockQuote,
  BlockToolbar,
  Bold,
  CloudServices,
  Code,
  CodeBlock,
  DecoupledEditor,
  EventInfo,
  FindAndReplace,
  FontBackgroundColor,
  FontColor,
  FontFamily,
  FontSize,
  Heading,
  Highlight,
  HtmlComment,
  Indent,
  Italic,
  Link,
  List,
  Paragraph,
  SelectAll,
  TodoList,
  Underline,
  Undo
} from "ckeditor5";

import translations from './pt-br.js';

import "ckeditor5/ckeditor5.css";

import "./Ckeditor.css";

export interface CkEditorFullProps {
  data: string;
  onChange?: ((event: EventInfo, editor: DecoupledEditor) => void) | undefined;
}

export const CkEditorFull: React.FC<CkEditorFullProps> = ({
  data,
  onChange,
}) => {
  const editorContainerRef = useRef<any>(null);
  const editorMenuBarRef = useRef<any>(null);
  const editorToolbarRef = useRef<any>(null);
  const editorRef = useRef<any>(null);
  const editorMinimapRef = useRef<any>(null);
  const [isLayoutReady, setIsLayoutReady] = useState(true);

  useEffect(() => {
    setIsLayoutReady(true);

    return () => setIsLayoutReady(false);
  }, []);

  const editorConfig = {
    toolbar: {
      items: [
        "undo",
        "redo",
        "|",
        "showBlocks",
        "|",
        "heading",
        "style",
        "|",
        "fontSize",
        "fontFamily",
        "fontColor",
        "fontBackgroundColor",
        "|",
        "bold",
        "italic",
        "underline",
        "|",
        "link",
        "insertImage",
        "insertTable",
        "highlight",
        "blockQuote",
        "codeBlock",
        "|",
        "alignment",
        "|",
        "bulletedList",
        "numberedList",
        "todoList",
        "outdent",
        "indent",
      ],
      shouldNotGroupWhenFull: false,
    },
    plugins: [
      Alignment,
      Autoformat,
      AutoLink,
      Autosave,
      BalloonToolbar,
      BlockQuote,
      BlockToolbar,
      Bold,
      CloudServices,
      Code,
      CodeBlock,
      FindAndReplace,
      FontBackgroundColor,
      FontColor,
      FontFamily,
      FontSize,
      Heading,
      Highlight,
      HtmlComment,
      Indent,
      Italic,
      Link,
      List,
      Paragraph,
      SelectAll,
      TodoList,
      Underline,
      Undo,
    ],
    balloonToolbar: [
      "bold",
      "italic",
      "|",
      "link",
      "insertImage",
      "|",
      "bulletedList",
      "numberedList",
    ],
    blockToolbar: [
      "fontSize",
      "fontColor",
      "fontBackgroundColor",
      "|",
      "bold",
      "italic",
      "|",
      "link",
      "insertImage",
      "insertTable",
      "|",
      "bulletedList",
      "numberedList",
      "outdent",
      "indent",
    ],
    fontFamily: {
      supportAllValues: true,
    },
    fontSize: {
      options: [10, 12, 14, "default", 18, 20, 22],
      supportAllValues: true,
    },
    heading: {
      options: [
        {
          model: "paragraph",
          title: "Parágrafo",
          class: "ck-heading_paragraph",
        },
        {
          model: "heading1",
          view: "h1",
          title: "Título 1",
          class: "ck-heading_heading1",
        },
        {
          model: "heading2",
          view: "h2",
          title: "Título 2",
          class: "ck-heading_heading2",
        },
        {
          model: "heading3",
          view: "h3",
          title: "Título 3",
          class: "ck-heading_heading3",
        },
        {
          model: "heading4",
          view: "h4",
          title: "Título 4",
          class: "ck-heading_heading4",
        },
        {
          model: "heading5",
          view: "h5",
          title: "Título 5",
          class: "ck-heading_heading5",
        },
        {
          model: "heading6",
          view: "h6",
          title: "Título 6",
          class: "ck-heading_heading6",
        },
      ],
    },
    htmlSupport: {
      allow: [
        {
          name: /^.*$/,
          styles: true,
          attributes: true,
          classes: true,
        },
      ],
    },
    language: "pt",
    link: {
      addTargetToExternalLinks: true,
      defaultProtocol: "https://",
      decorators: {
        toggleDownloadable: {
          mode: "manual",
          label: "Downloadable",
          attributes: {
            download: "file",
          },
        },
      },
    },
    list: {
      properties: {
        styles: true,
        startIndex: true,
        reversed: true,
      },
    },
    menuBar: {
      isVisible: true,
    },
    placeholder: "Insira o texto para impressão",
    table: {
      contentToolbar: [
        "tableColumn",
        "tableRow",
        "mergeTableCells",
        "tableProperties",
        "tableCellProperties",
      ],
    },
    translations: [translations],
  } as any;

  return (
    <div>
      <div className="main-container">
        <div
          className="editor-container editor-container_document-editor editor-container_include-minimap editor-container_include-style"
          ref={editorContainerRef}
        >
          <div
            className="editor-container__menu-bar"
            ref={editorMenuBarRef}
          ></div>
          <div
            className="editor-container__toolbar"
            ref={editorToolbarRef}
          ></div>
          <div className="editor-container__minimap-wrapper">
            <div className="editor-container__editor-wrapper">
              <div className="editor-container__editor">
                <div ref={editorRef}>
                  {isLayoutReady && (
                    <CKEditor
                      onReady={(editor) => {
                        if (editor?.ui) {
                          editorToolbarRef.current.appendChild(
                            editor.ui.view.toolbar.element
                          );
                          editorMenuBarRef.current.appendChild(
                            editor.ui.view.menuBarView.element
                          );
                        }
                      }}
                      onAfterDestroy={() => {
                        Array.from(editorToolbarRef.current.children).forEach(
                          (child: any) => child.remove()
                        );
                        Array.from(editorMenuBarRef.current.children).forEach(
                          (child: any) => child.remove()
                        );
                      }}
                      data={data}
                      editor={DecoupledEditor}
                      onChange={onChange}
                      config={editorConfig}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
