enum LocationsRoutes {
  Regionals = '/regionals',
  RegionalCreate = '/regional',
  RegionalEdit = '/regional/:id',
  
  Administrations = '/administrations',
  AdministrationCreate = '/administration',
  AdministrationEdit = '/administration/:id',
  
  Sectors = '/sectors',
  SectorCreate = '/sector',
  SectorEdit = '/sector/:id',
  
  Locals = '/locals',
  LocalCreate = '/local',
  LocalEdit = '/local/:id',  
  
  Cities = '/cities',
  CityCreate = '/city',
  CityEdit = '/city/:id',  
}

export { LocationsRoutes };
