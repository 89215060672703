import { SectorModel, SectorsFunctions } from "./Sectors.functions";
import { ProTable } from "../../../../components/table/pro-table/ProTable";

export const Sectors = () => {
  const {
    queryName,
    columns,
    columnsMap,
    clientLocal,
    whereDefault,
    variables,
    setVariables,
    toolBarRender,
  } = SectorsFunctions();

  return (
    <>
      <ProTable<SectorModel>
        client={clientLocal}
        queryName={queryName}
        whereType="SectorModelFilterInput"
        whereDefault={whereDefault}
        orderType="SectorModelSortInput"
        variables={variables}
        setVariables={setVariables}
        columns={columns()}
        defaultColumnsMap={columnsMap}
        toolBarRender={toolBarRender}
        onFormatColumnsMap={(columns) => {
          columns = columns?.replace(
            "administrations",
            `administration {
              id
              name
              parent {
                id
                name
              }
            }`
          );

          return columns;
        }}
      />
    </>
  );
};
