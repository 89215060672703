import React, { useEffect, useState } from "react";
import { useMain } from "../../../../../contexts/main";
import { useNavigate, useParams } from "react-router-dom";
import { clientLocal } from "../../../../../services/graphql.service";
import { useMutation, useQuery } from "@apollo/client";
import {
  createRegional,
  getRegionalByIdQuery,
  updateRegional,
} from "../../../services/locations.service";
import { Form, FormInstance } from "antd";
import { LocationsRoutes } from "../../../routes.enum";

export const RegionalCreateFunctions = () => {
  const { errorMessage, loading, setLoading, setTitlePage } = useMain();
  const { handleChangeBreadcumb } = useMain();
  const { id } = useParams();
  const navigate = useNavigate();
  const title = !id ? "Nova regional" : "Alterar a regional";

  useEffect(() => {
    setLoading(false);
    handleChangeBreadcumb([
      {
        title: "Home",
        path: "/",
      },
      {
        title: "Módulos",
        path: LocationsRoutes.Regionals,
      },
      {
        title: title,
      },
    ]);
    setTitlePage(title);
  }, [id]);

  const [form] = Form.useForm<{
    name: string;
    description: string;
    required: boolean;
    price: string;
  }>();
  const formRef = React.useRef<FormInstance>(null);

  const { loading: loadingGet } = useQuery(getRegionalByIdQuery(), {
    client: clientLocal,
    variables: { id },
    fetchPolicy: "no-cache",
    onCompleted: (data) => {
      const user = data.regionalById;
      formRef.current?.setFieldsValue(user);
    },
    errorPolicy: "all",
    onError: ({ graphQLErrors, networkError }) => {
      if (!id) return;

      if (graphQLErrors)
        graphQLErrors.forEach(({ message, locations, path }) =>
          errorMessage(message)
        );
      if (networkError)
        errorMessage("Ocorreu um erro inesperado. Tente novamente mais tarde!");
    },
  });

  const [sendUpdateUserMutation] = useMutation(updateRegional(), {
    client: clientLocal,
  });
  const [sendCreateUserMutation] = useMutation(createRegional(), {
    client: clientLocal,
  });

  const onFinish = (values: any) => {
    setLoading(true);
    const { name, description, required, price } = values;

    if (id) {
      sendUpdateUserMutation({
        variables: {
          id,
          request: {
            name,
            description,
            required,
            price,
          },
        },
      }).then((result) => {
        if (result.errors) {
          console.log({ errors: result.errors });
          errorMessage("Houve um erro ao realizar a alterar a regional.");
          setLoading(false);
          return;
        }

        const { success } = result.data.updateRegional;
        if (!success) {
          errorMessage("Houve um erro ao realizar a alterar a regional.");
          setLoading(false);
          return;
        }

        setLoading(false);
        navigate(LocationsRoutes.Regionals);
      });
    } else {
      sendCreateUserMutation({
        variables: {
          request: {
            name,
            description,
            required,
            price,
          },
        },
      }).then((result) => {
        if (result.errors) {
          console.log({ errors: result.errors });
          errorMessage("Houve um erro ao realizar a alterar a regional.");
          setLoading(false);
          return;
        }

        const { success, message } = result.data.createRegional;
        if (!success) {
          errorMessage(
            message ?? "Houve um erro ao realizar a alterar a regional."
          );
          setLoading(false);
          return;
        }

        setLoading(false);
        navigate(LocationsRoutes.Regionals);
      });
    }
  };

  return { id, title, form, formRef, onFinish, loadingGet, loading };
};
