import { EventTypeModel, EventTypesFunctions } from "./EventTypes.functions";
import { ProTable } from "../../../../components/table/pro-table/ProTable";

export const EventTypes = () => {
  const {
    columns,
    columnsMap,
    clientEvent,
    whereDefault,
    variables,
    setVariables,
    toolBarRender,
  } = EventTypesFunctions();

  return (
    <>
      <ProTable<EventTypeModel>
        headerTitle="Serviços"
        client={clientEvent}
        queryName="eventTypes"
        whereType="EventTypeModelFilterInput"
        whereDefault={whereDefault}
        orderType="EventTypeModelSortInput"
        variables={variables}
        setVariables={setVariables}
        columns={columns()}
        defaultColumnsMap={columnsMap}
        toolBarRender={toolBarRender}
      />
    </>
  );
};
