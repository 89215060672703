import { useQuery } from "@apollo/client";
import { getAnalytics } from "firebase/analytics";
import { initializeApp } from "firebase/app";
import {
  Auth,
  getAuth,
  GoogleAuthProvider,
  ParsedToken,
  signInWithEmailAndPassword,
  signInWithPopup,
  User,
} from "firebase/auth";
import { FirebaseStorage, getStorage } from "firebase/storage";
import React, { createContext, useContext, useEffect, useState } from "react";
import { SimpleModel } from "../models/simplemodel.interface";
import { UserFilterResult } from "../modules/account/pages/users/Users.functions";
import { getUserFilterByIdQuery } from "../modules/account/services/account.service";
import { AuthRoutes } from "../modules/auth/routes.enum";
import { EnvironmentService } from "../services/envoriment.service";
import { clientAuth } from "../services/graphql.service";
import { LocalStorageService } from "../services/localStorage.service";
import { useThemeDetector } from "../theme";
import { useMain } from "./main";

const firebaseConfig = {
  apiKey: "AIzaSyBonKpzs-y6Ku1nwKa5THJBYApv8nslRU0",
  authDomain: "ccb-bh.firebaseapp.com",
  projectId: "ccb-bh",
  storageBucket: "ccb-bh.appspot.com",
  messagingSenderId: "793613497805",
  appId: "1:793613497805:web:e244a35dbe966c1f060084",
  measurementId: "G-2RY198M07G"
};

interface AuthContextData {
  login(values: any): Promise<void>;
  loginWithGoogle(): Promise<void>;
  signOut(): void;
  isAuthentication(): boolean;
  hasPermission(permission?: string[]): boolean;
  auth: Auth;
  storage: FirebaseStorage;
  handleRenewTokens(user: User): void;
  principalRegional?: SimpleModel;
  regionals: SimpleModel[];
  principalAdministration?: SimpleModel;
  administrations: SimpleModel[];
  principalSector?: SimpleModel;
  sectors: SimpleModel[];
  isDarkTheme: boolean;
  handleGetFilterUser(id: string): void;
}

const AuthContext = createContext<AuthContextData>({} as AuthContextData);

interface AuthProviderProps {
  children: React.ReactNode;
}

const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const { setLoading, errorMessage } = useMain();

  // Initialize Firebase
  const app = initializeApp(firebaseConfig);
  const analytics = getAnalytics(app);
  const auth = getAuth(app);
  const storage = getStorage();

  const [claims, setClaims] = useState<ParsedToken>();

  const isDarkThemeWindows = useThemeDetector();
  const [isDarkTheme, setIsDarkTheme] = useState(
    (!claims || !claims["theme"]) && isDarkThemeWindows
  );

  const [principalRegional, setPrincipalRegional] = useState<SimpleModel>();
  const [regionals, setRegionals] = useState<SimpleModel[]>([]);

  const [principalAdministration, setPrincipalAdministration] =
    useState<SimpleModel>();
  const [administrations, setAdministrations] = useState<SimpleModel[]>([]);

  const [principalSector, setPrincipalSector] = useState<SimpleModel>();
  const [sectors, setSectors] = useState<SimpleModel[]>([]);

  useEffect(() => {
    const token = LocalStorageService.getLocalStorage(
      LocalStorageService.localStorageToken
    );
    if (
      (!token || token === "undefined") &&
      !Object.values(AuthRoutes).includes(
        window.location.pathname as AuthRoutes
      )
    ) {
      signOut();
    }
  }, []);

  const { refetch } = useQuery<UserFilterResult>(getUserFilterByIdQuery(), {
    client: clientAuth,
    variables: { id: auth.currentUser?.uid },
    fetchPolicy: "cache-and-network",
    errorPolicy: "ignore",
    onError: () => {},
    skip: true,
  });

  const handleGetFilterUser = (id: string) => {
    refetch({ id }).then((result) => {
      if (!result.data?.userFilterById?.data) {
        window.location.href = AuthRoutes.UNREGISTERED_USER;
        return;
      }

      const { regionals, administrations, sectors } =
        result.data.userFilterById.data;

      if (!regionals || regionals.length == 0) {
        window.location.href = AuthRoutes.UNREGISTERED_USER;
        return;
      }

      const regionalsUser = regionals as SimpleModel[];
      setRegionals(regionalsUser);

      const principal = regionalsUser.find((_) => _.isPrincipal);
      setPrincipalRegional(principal);

      const administrationsUser = administrations?.filter(
        (_: SimpleModel) =>
          _.parent?.id === principal?.id || _.id === "todos-adm"
      ) as SimpleModel[];
      setAdministrations(administrationsUser);

      const principalAdministration = administrationsUser?.find(
        (_) => _.isPrincipal
      );
      setPrincipalAdministration(principalAdministration);

      const sectorsUser = sectors?.filter(
        (_: SimpleModel) =>
          _.parent?.id === principalAdministration?.id ||
          _.id === "todos-sec"
      ) as SimpleModel[];
      setSectors(sectorsUser);

      const principalSector = sectorsUser?.find((_) => _.isPrincipal);
      setPrincipalSector(principalSector);
    });
  };

  useEffect(() => {
    if (
      auth.currentUser?.uid &&
      !Object.values(AuthRoutes).includes(
        window.location.pathname as AuthRoutes
      )
    )
      handleGetFilterUser(auth.currentUser?.uid);
  }, [auth.currentUser]);

  useEffect(() => {
    if (claims) {
      setClaims(claims);
      setIsDarkTheme(claims["theme"] === "dark" && isDarkThemeWindows);
    }
  }, [claims]);

  const successLogin = (): string => {
    const environment = EnvironmentService.isDev
      ? "dev"
      : EnvironmentService.isStg
      ? "stg"
      : "";

    const searchParams = new URLSearchParams(window.location.search);
    if (searchParams && searchParams.get("redirect")) {
      const redirect: string = searchParams.get("redirect") as string;
      const app: string = redirect.split("//")[1].split(".")[0];
      const environmentUrl: string = redirect
        .split("//")[1]
        .split(".")[0]
        .split("-")[1];

      if (app.split(":")[0] === "localhost") {
        return `http://${app}`;
      }

      return !environment
        ? `https://sge.ccbbh.com.br`
        : `https://sge-${
            environmentUrl === "canary" ? "canary" : environment
          }.ccbbh.com.br/auto-login`;
    }

    if (origin.includes("localhost")) {
      return `${origin}`;
    } else {
      return !environment
        ? `https://sge.ccbbh.com.br`
        : `https://sge-${environment}.ccbbh.com.br`;
    }
  };

  const handleRenewTokens = (user: User) => {
    if (user) {
      user.getIdTokenResult().then((responseToken) => {
        LocalStorageService.setLocalStorage(
          LocalStorageService.localStorageToken,
          responseToken.token
        );
        LocalStorageService.setLocalStorageJSON(
          LocalStorageService.localStoragePermissions,
          responseToken.claims["roles"]
        );
        LocalStorageService.setLocalStorageJSON(
          LocalStorageService.localStorageClaims,
          responseToken.claims
        );
      });
    }
  };

  const googleProvider = new GoogleAuthProvider();
  async function loginWithGoogle() {
    setLoading(true);
    try {
      const result = await signInWithPopup(auth, googleProvider);
      const user = result.user;
      handleRenewTokens(user);
      const redirect = successLogin();
      window.location.href = redirect;
    } catch (error: any) {
      const errorCode = error.code;
      const message = error.message;
      if (errorCode === "auth/popup-closed-by-user") {
        errorMessage("Login cancelado pelo usuário.");
      } else {
        errorMessage(message);
      }
    } finally {
      setLoading(false);
    }
  }

  async function login(values: any) {
    setLoading(true);
    const { userName, password } = values;
    signInWithEmailAndPassword(auth, userName, password)
      .then((response) => {
        handleRenewTokens(response.user);
        if (response.user) {
          const redirect = successLogin();
          window.location.href = redirect;
        }
      })
      .catch((error) => {
        // Handle Errors here.
        var errorCode = error.code;
        var message = error.message;
        if (
          errorCode === "auth/wrong-password" ||
          errorCode === "auth/invalid-credential"
        ) {
          errorMessage("Usuário ou senha incorreta.");
        } else if (errorCode === "auth/too-many-requests") {
          errorMessage(
            "O acesso a esta conta foi temporariamente desativado devido a muitas tentativas de login malsucedidas. Você pode restaurá-lo imediatamente redefinindo sua senha ou tentar novamente mais tarde. (autenticação/muitas solicitações)."
          );
        } else {
          errorMessage(message);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }

  function signOut() {
    LocalStorageService.removeAllLocalStorage();
    if (
      !window.location.pathname.includes(AuthRoutes.LOGIN) &&
      !window.location.pathname.includes(AuthRoutes.LOGOUT) &&
      !window.location.pathname.includes(AuthRoutes.FORGOT_PASSWORD) &&
      !window.location.pathname.includes(AuthRoutes.RESET_PASSWORD) &&
      !window.location.pathname.includes(AuthRoutes.REGISTER)
    ) {
      auth.signOut();
      window.location.href = `/logout`;
    }
  }

  const isAuthentication = () => {
    const token = LocalStorageService.getLocalStorage(
      LocalStorageService.localStorageToken
    );
    return !!token;
  };

  const hasPermission = (permission?: string[]) => {
    var permissionString = LocalStorageService.getLocalStorage(
      LocalStorageService.localStoragePermissions
    );
    if (!permissionString || permissionString == "undefined") return false;

    const permissions = LocalStorageService.getLocalStorageJSON<string[]>(
      LocalStorageService.localStoragePermissions
    );
    return (
      ((permission &&
        (permissions?.findIndex((_) => permission.includes(_)) ?? -1) >= 0) ||
        permissions?.includes("admin-system")) ??
      false
    );
  };

  return (
    <AuthContext.Provider
      value={{
        login,
        loginWithGoogle,
        signOut,
        isAuthentication,
        hasPermission,
        auth,
        storage,
        isDarkTheme,
        handleRenewTokens,
        principalRegional,
        regionals,
        principalAdministration,
        administrations,
        principalSector,
        sectors,
        handleGetFilterUser,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

function useAuth() {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider.");
  }

  return context;
}

export { AuthContext, AuthProvider, useAuth };

