import {
  Button,
  Card,
  Col,
  Form,
  FormInstance,
  Input,
  Row,
  Select,
  Skeleton,
} from "antd";
import style from "./PeopleGeneralData.module.scss";
import { useState } from "react";
import { MdEdit } from "react-icons/md";
import { PeoplesGeneralDataFunctions } from "./PeopleGeneralData.functions";

interface PeopleGeneralDataProps {
  setSurname: React.Dispatch<React.SetStateAction<string | undefined>>;
}

export const PeopleGeneralData = (props: PeopleGeneralDataProps) => {
  const { setSurname } = props;
  const {
    form,
    formRef,
    onFinish,
    loading,
    loadingGet,
    edit,
    setEdit,
    peopleRoles,
    loadingPeopleRolesGet,
    locals,
    loadingLocalsGet,
  } = PeoplesGeneralDataFunctions({ setSurname });

  return (
    <Card
      title={"Dados Gerais"}
      className={style["card-border"]}
      extra={
        <Button
          type="link"
          shape="circle"
          icon={<MdEdit />}
          onClick={() => setEdit(!edit)}
        />
      }>
      <Skeleton loading={loadingGet} active />
      <Form
        form={form}
        ref={formRef}
        hidden={loadingGet}
        name="people-form"
        layout="vertical"
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
        style={{ maxWidth: 1200 }}
        disabled={!edit}
        onFinish={onFinish}
        autoComplete="off">
        <Form.Item
          label="Apelido"
          name="surname"
          rules={[
            { required: true, message: "O apelido é obrigatório!" },
            {
              max: 30,
              message: "O apelido pode ter no máximo 30 caracteres!",
            },
          ]}>
          <Input />
        </Form.Item>

        <Form.Item
          label="Nome"
          name="name"
          rules={[
            {
              max: 30,
              message: "O nome pode ter no máximo 30 caracteres!",
            },
          ]}>
          <Input />
        </Form.Item>

        <Form.Item
          label="E-mail"
          name="email"
          rules={[
            {
              max: 30,
              message: "O e-mail pode ter no máximo 30 caracteres!",
            },
          ]}>
          <Input />
        </Form.Item>

        <Form.Item>
          <Row gutter={[16, 16]}>
            <Col span={24} lg={16}>
              <Form.Item
                label="Comum"
                name="localId">
                <Select
                  options={locals}
                  loading={loadingLocalsGet}
                  style={{
                    width: "100%",
                  }}
                />
              </Form.Item>
            </Col>
            <Col span={24} lg={8}>
              <Form.Item
                label="Função"
                name="roleId"
                rules={[
                  { required: true, message: "A função é obrigatório!" },
                ]}>
                <Select
                  options={peopleRoles}
                  loading={loadingPeopleRolesGet}
                  style={{
                    width: "100%",
                  }}
                />
              </Form.Item>
            </Col>
          </Row>
        </Form.Item>

        {edit && (
          <Form.Item className={style["button-save"]} wrapperCol={{ span: 16 }}>
            <Button type="primary" htmlType="submit" loading={loading}>
              Salvar
            </Button>
          </Form.Item>
        )}
      </Form>
    </Card>
  );
};
