import { useMutation } from "@apollo/client";
import { Form } from "antd";
import { useState } from "react";
import { useAuth } from "../../../../../contexts/auth";
import { useMain } from "../../../../../contexts/main";
import { clientAuth } from "../../../../../services/graphql.service";
import { changePassword } from "../../../services/user.service";

export const UserSecurityFunctions = () => {
  const [form] = Form.useForm();
  const { successMessage, errorMessage, loading, setLoading } = useMain();
  const { auth } = useAuth();
  const [edit, setEdit] = useState<boolean>();
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [resetPasswordMutation, { loading: loadingRegister }] = useMutation(
    changePassword(),
    {
      client: clientAuth,
    }
  );

  const validateMessages = {
    required: "${label} é obrigatorio!",
    types: {
      email: "${label} não é válido",
    },
  };

  const onSubmit = (values: any) => {
    setLoading(true);
    resetPasswordMutation({
      variables: {
        request: {
          ...values,
          email: auth.currentUser?.email,
        },
      },
      onCompleted: (result) => {
        const { success, message } = result.changePassword;
        if (success) successMessage("Sua senha foi alterada com sucesso.");
        else errorMessage(message);
        setLoading(false);
      },
      errorPolicy: "all",
      onError: ({ graphQLErrors, networkError }) => {
        if (graphQLErrors)
          graphQLErrors.forEach(({ message, locations, path }) =>
            errorMessage(message)
          );
        if (networkError)
          errorMessage(
            "Ocorreu um erro inesperado. Tente novamente mais tarde!"
          );
        setLoading(false);
      },
    });
  };

  return {
    validateMessages,
    form,
    passwordVisible,
    setPasswordVisible,
    onSubmit,
    loading,
    loadingRegister,
    edit,
    setEdit,
  };
};
