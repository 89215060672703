import react from 'react';

const useUtils = () => {

    const formatDateWithDayMonth = (date: string): string => {
        const formattedDate = new Date(date).toLocaleDateString('pt-BR', {
            day: '2-digit',
            month: '2-digit',
            timeZone: 'UTC'
        })
        return formattedDate
    };

    return {
        formatDateWithDayMonth
    };
};

export default useUtils;