import style from "./ForgotPassword.module.scss";
import ImageLogin from "../../../../components/images/login/image-login.svg";
import { ForgotPasswordForm } from "./forgot-password-form/ForgotPasswordForm";
import { Col, ConfigProvider, Row, theme } from "antd";

const { defaultAlgorithm } = theme;

export const ForgotPassword = () => {

  return (
    <ConfigProvider
      theme={{
        algorithm: defaultAlgorithm,
        token: {
          colorPrimary: "#1890FF",
          colorLink: "#1890FF",
          borderRadius: 10,
        },
      }}>
      <Row justify='center' align='middle' className={style.main}>
        <img className={style.image} src={ImageLogin} alt="" />
        <Col xs={24} sm={18} md={16} lg={10} className={style.box}>
          <ForgotPasswordForm />
        </Col>
      </Row>
    </ConfigProvider>
  );
};
