import { ExclamationCircleFilled, PlusOutlined } from "@ant-design/icons";
import type { ColumnsState } from "@ant-design/pro-components";
import { useMutation } from "@apollo/client";
import { Button, Modal, Space, Tooltip } from "antd";
import { useEffect, useState } from "react";
import { MdDelete, MdEdit } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { ProColumnsProps } from "../../../../components/table/pro-table/ProTable";
import { useAuth } from "../../../../contexts/auth";
import { useMain } from "../../../../contexts/main";
import { SimpleModel } from "../../../../models/simplemodel.interface";
import { clientPeople } from "../../../../services/graphql.service";
import { PeopleRoutes } from "../../routes.enum";
import { deletePeople } from "../../services/people.service";

export type PeopleModel = {
  id: string;
  name: string;
  surname: string;
  email: string;
  local: SimpleModel;
  role: SimpleModel;
};

export interface Response<T> {
  nodes: T[];
  pageInfo: {
    hasNextPage: boolean;
    hasPreviousPage: boolean;
  };
  totalCount: number;
}

enum Sort {
  ASC = "ASC",
  DESC = "DESC",
}

const { confirm } = Modal;

export const PeoplesFunctions = () => {
  const navigate = useNavigate();
  const { errorMessage, handleChangeBreadcumb, setTitlePage } = useMain();
  const { hasPermission } = useAuth();

  useEffect(() => {
    const title = "Pessoas";
    handleChangeBreadcumb([
      {
        title: "Home",
        path: "/",
      },
      {
        title: "Pessoas",
      },
    ]);

    setTitlePage(title);
  }, []);

  const onClickCreate = () => {
    navigate(PeopleRoutes.PeopleCreate);
  };

  const onClickEdit = (id: string) => {
    const url = PeopleRoutes.PeopleEdit.replace(":id", id);
    navigate(url);
  };

  const [deleteMutation] = useMutation(deletePeople(), {
    client: clientPeople,
    errorPolicy: "all",
    onCompleted: () => {
      setVariables({ ...variables });
    },
    onError: ({ graphQLErrors, networkError }) => {
      if (graphQLErrors)
        graphQLErrors.forEach(({ message, locations, path }) =>
          errorMessage(message)
        );
      if (networkError)
        errorMessage("Ocorreu um erro inesperado. Tente novamente mais tarde!");
    },
  });

  const onClickDelete = (id: string) => {
    return deleteMutation({
      variables: {
        id,
      },
    });
  };

  const toolBarRender = () => [
    hasPermission(["admin", "admin-system"]) && (
      <Button
        key="button"
        icon={<PlusOutlined />}
        onClick={onClickCreate}
        type="primary">
        Novo
      </Button>
    ),
  ];

  const columns = (): ProColumnsProps<PeopleModel>[] => [
    {
      title: "Apelido",
      dataIndex: "surname",
      filters: true,
      onFilter: true,
      ellipsis: true,
      sorter: true,
    },
    {
      title: "Nome",
      dataIndex: "name",
      filters: true,
      onFilter: true,
      ellipsis: true,
      sorter: true,
    },
    {
      title: "E-mail",
      dataIndex: "email",
      filters: true,
      onFilter: true,
      ellipsis: true,
      sorter: true,
    },
    {
      title: "Comum",
      dataIndex: "local",
      search: false,
      ellipsis: true,
      renderFormItem: (_, { defaultRender }) => {
        return defaultRender(_);
      },
      render: (_, record) => record.local?.name,
    },
    {
      title: "Ministério/Função",
      dataIndex: "role",
      search: false,
      ellipsis: true,
      renderFormItem: (_, { defaultRender }) => {
        return defaultRender(_);
      },
      render: (_, record) => record.role?.name,
    },
    {
      title: "Ações",
      key: "actions",
      search: false,
      width: 180,
      align: "center",
      permission: ["admin", "admin-system"],
      render: (_, record) => [
        <Space>
          <Tooltip title="Editar pessoa">
            <Button
              type="link"
              shape="circle"
              icon={<MdEdit />}
              onClick={() => onClickEdit(record.id)}
            />
          </Tooltip>
          <Tooltip title="Deletar pessoa">
            <Button
              type="link"
              shape="circle"
              icon={<MdDelete />}
              onClick={() => showDeleteConfirm(record.id, record.name)}
            />
          </Tooltip>
        </Space>,
      ],
    },
  ];

  const whereDefault = {
    surname: {
      contains: "",
    },
  };

  const [variables, setVariables] = useState({
    skip: 0,
    take: 10,
    where: whereDefault,
    order: { name: Sort.ASC },
  });

  const columnsMap = {
    name: {
      show: false,
    },
    surname: {
      disable: true,
      show: true,
    },
    email: {
      show: false,
    },
    local: {
      show: true,
    },
    role: {
      show: true,
    },
  } as Record<string, ColumnsState>;

  const showDeleteConfirm = (id: string, name: string) => {
    confirm({
      title: `Deseja deletar a pessoa: ${name}?`,
      icon: <ExclamationCircleFilled />,
      content: "Você realmente deseja deletar este item?",
      okText: "Sim",
      okButtonProps: {
        style: { background: "var(--primary-color)" },
      },
      cancelText: "Não",
      onOk() {
        return onClickDelete(id);
      },
      onCancel() {},
    });
  };

  return {
    columns,
    columnsMap,
    clientPeople,
    whereDefault,
    variables,
    setVariables,
    toolBarRender,
  };
};
