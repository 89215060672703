import React from "react";
import { AppRoute } from "../../models/appRoute.model";
import { HomeRoutes } from "./routes.enum";
import { Navigate } from "react-router-dom";
import { PendingEventsList } from "./pages/pending-events-list/PendingEventsList";
import { GoAlertFill } from "react-icons/go";

const getHomeRoutes = (): AppRoute[] => [
  new AppRoute({
    path: HomeRoutes.PENDING_EVENTS,
    component: <PendingEventsList />,
    icon: <GoAlertFill />,
    name: "Eventos pendentes",
    permission: ["admin-system", "admin", "user"],
  }),
  new AppRoute({
    path: "*",
    component: <Navigate to="/home" />,
    permission: ["admin-system", "admin", "user"],
  }),
];

export { getHomeRoutes };
