import type { ActionType, ColumnsState } from "@ant-design/pro-components";
import {
  ApolloClient,
  DocumentNode,
  NormalizedCacheObject,
  gql,
  useQuery,
} from "@apollo/client";
import { SortOrder } from "antd/lib/table/interface";
import { useCallback, useEffect, useRef, useState } from "react";
import { useAuth } from "../../../contexts/auth";
import { useMain } from "../../../contexts/main";
import { ApiQueryResult } from "../../../models/apiResult.interface";
import { LocalStorageService } from "../../../services/localStorage.service";
import { ProColumnsProps } from "./ProTable";

enum Sort {
  ASC = "ASC",
  DESC = "DESC",
}

export interface ProTableFunctionsProps<T> {
  client: ApolloClient<NormalizedCacheObject>;
  queryName: string;
  whereType: string;
  whereDefault: {};
  orderDefault?: {};
  orderType: string;
  variables: React.SetStateAction<any>;
  setVariables: React.Dispatch<React.SetStateAction<any>>;
  defaultColumnsMap: Record<string, ColumnsState>;
  columns: ProColumnsProps<T>[];
  onFormatColumnsMap?: (columns: string | undefined) => string | undefined;
}

function getProTableQuery(
  queryName: string,
  whereType: string,
  orderType: string,
  columnsMap: string[] | undefined,
  onFormatColumnsMap?: (columns: string | undefined) => string | undefined
): DocumentNode {
  let columnsResponse = columnsMap?.join() ?? "";

  if (onFormatColumnsMap) {
    columnsResponse = onFormatColumnsMap(columnsResponse) ?? "";
  }

  return gql`
      query ProTable${queryName}($skip: Int!, $take: Int!, $where: ${whereType}!, $order: [${orderType}!]) {
        ${queryName}(skip: $skip, take: $take, where: $where, order: $order) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          totalCount
          items {
            id
            ${columnsResponse}
          }
        }
      }
    `;
}

export const ProTableFunctions = <T extends {}>(
  props: ProTableFunctionsProps<T>
) => {
  const {
    variables,
    client,
    queryName,
    whereType,
    whereDefault,
    orderDefault,
    orderType,
    columns,
    defaultColumnsMap,
    onFormatColumnsMap,
  } = props;

  const actionRef = useRef<ActionType>();
  const { errorMessage } = useMain();
  const { hasPermission } = useAuth();

  var defaultColumns = [];
  if (defaultColumnsMap) {
    for (var column in defaultColumnsMap) {
      if (defaultColumnsMap[column].show) {
        defaultColumns.push(column);
      }
    }
  }

  const [columnsMap, setColumnsMap] = useState<string[]>(defaultColumns);

  const [query, setQuery] = useState(
    getProTableQuery(queryName, whereType, orderType, columnsMap)
  );

  const { refetch } = useQuery(query, {
    client: client,
    variables,
    fetchPolicy: "no-cache",
    errorPolicy: "all",
    onError: ({ graphQLErrors, networkError }) => {
      if (graphQLErrors)
        graphQLErrors.forEach(({ message, locations, path }) =>
          errorMessage(message)
        );
      if (networkError)
        errorMessage("Ocorreu um erro inesperado. Tente novamente mais tarde!");
    },
  });

  useEffect(() => {
    setQuery(
      getProTableQuery(
        queryName,
        whereType,
        orderType,
        columnsMap,
        onFormatColumnsMap
      )
    );
  }, [columnsMap]);

  useEffect(() => {
    actionRef.current?.reload();
  }, [query, variables]);

  const request = useCallback(
    async (
      params: any,
      sort: Record<string, SortOrder>,
      filter: Record<string, (string | number)[] | null>
    ): Promise<ApiQueryResult<any>> => {
      let newVariable = {
        skip: variables?.skip ?? 1 * variables?.take ?? 10,
        take: variables?.take ?? 10,
      } as any;
      let newSort = (sort as any) ?? {};
      const sortList = Object.keys(newSort);
      if (sortList?.length > 0) {
        sortList.forEach(function (key, index) {
          newSort[key] = newSort[key] === "ascend" ? Sort.ASC : Sort.DESC;
        });
      } else {
        newSort = orderDefault || { id: Sort.ASC };
      }

      let where = {} as any;

      delete params.current;
      delete params.pageSize;

      Object.keys(localStorage)
        .filter((l) => l.includes(queryName))
        .forEach((item) => {
          LocalStorageService.removeLocalStorage(item);
        });

      for (const item in params) {
        const columnsFilter = columns.filter(
          (_: any) =>
            _.dataIndex === item || (_.dataIndex as string[])?.includes(item)
        ) as any[];
        columnsFilter.forEach((column) => {
          if (column?.valueType === "select") {
            const keys = column.dataIndex as string[];
            const indexs = keys.length;
            let eqValue;
            for (let i = 0; i < indexs; i++) {
              const lastIndex = indexs - 1;
              if (indexs === 1 && i === lastIndex) {
                eqValue = params[keys[i]];
                if (eqValue)
                  where[keys[i]] = {
                    eq: eqValue,
                    ...where[item]
                  };
              } else if (indexs === 2 && i === lastIndex) {
                if (params[keys[lastIndex - 1]])
                  eqValue = params[keys[lastIndex - 1]][keys[i]];
                if (eqValue)
                  where[item] = {
                    [keys[i]]: {
                      eq: params[keys[lastIndex - 1]][keys[i]],
                    },
                    ...where[item]
                  };
              } else if (indexs === 3 && i === lastIndex) {
                if (
                  params[keys[lastIndex - 2]] &&
                  params[keys[lastIndex - 2]][keys[lastIndex - 1]]
                )
                  eqValue =
                    params[keys[lastIndex - 2]][keys[lastIndex - 1]][keys[i]];
                if (eqValue)
                  where[item] = {
                    [keys[lastIndex - 1]]: {
                      [keys[i]]: {
                        eq: eqValue,
                      },
                    },
                    ...where[item]
                  };
              }
            }

            const filterLocalStorage = `${queryName}.${keys.join(".")}`;
            if (eqValue)
              LocalStorageService.setLocalStorage(filterLocalStorage, eqValue);
            else LocalStorageService.removeLocalStorage(filterLocalStorage);
          } else if (column?.valueType === "date") {
            where[item] = {
              eq: new Date(`${params[item]}T12:00`).toLocaleDateString(),
            };
          } else {
            where[item] = {
              contains: params[item],
            };
          }
        });
      }

      if (Object.keys(where)?.length === 0 && where?.constructor === Object) {
        where = whereDefault;
      }

      newVariable = {
        ...newVariable,
        where,
        order: newSort,
      } as any;
      const response = await refetch(newVariable);
      const result = response?.data[queryName];

      return {
        data: result?.items,
        success: !!result,
        total: result?.totalCount,
      };
    },
    [columnsMap, query, variables]
  );

  return {
    request,
    setColumnsMap,
    actionRef,
    hasPermission,
  };
};
