import {
  ApolloClient,
  ApolloLink,
  createHttpLink,
  from,
  InMemoryCache,
} from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import { EnvironmentService } from "./envoriment.service";
import { LocalStorageService } from "./localStorage.service";

const getBaseUrl = (service: string) => {
  let portService = "5001";
  if (EnvironmentService.isLocalhost) {
    switch (service) {
      case "auth":
        portService = "5001";
        break;
      case "blogs":
        portService = "5002";
        break;
      case "events":
        portService = "5003";
        break;
      case "locations":
        portService = "5004";
        break;
      case "peoples":
        portService = "5005";
        break;
      case "systems":
        portService = "5006";
        break;
    }
  }

  if (EnvironmentService.isProd)
    return `https://api.ccbbh.com.br/${service}/graphql`;
  if (EnvironmentService.isStg)
    return `https://api-stg.ccbbh.com.br/${service}/graphql`;
  if (EnvironmentService.isDev)
    return `https://api-dev.ccbbh.com.br/${service}/graphql`;
  return `http://localhost:${portService}/graphql`;
};

const authMiddleware = new ApolloLink((operation, forward) => {
  const token = LocalStorageService.getLocalStorage(
    LocalStorageService.localStorageToken
  );
  if (!token) return forward(operation);

  operation.setContext(({ headers = {} }) => ({
    headers: {
      ...headers,
      authorization: `Bearer ${token}` || null,
    },
  }));

  return forward(operation);
});

const errorLink = onError(({ networkError }) => {
  if (
    !window.location.pathname.includes("login") &&
    networkError &&
    (networkError as any).statusCode == 401
  ) {
    window.location.href = `/logout`;
    return;
  }
});

const httpLinkAuth = createHttpLink({
  uri: getBaseUrl("auth"),
});

const clientAuth = new ApolloClient({
  link: errorLink.concat(from([authMiddleware, httpLinkAuth])),
  cache: new InMemoryCache(),
});

const httpLinkSystem = createHttpLink({
  uri: getBaseUrl("systems"),
});

const clientSystem = new ApolloClient({
  link: errorLink.concat(from([authMiddleware, httpLinkSystem])),
  cache: new InMemoryCache(),
});

const httpLinkPeople = createHttpLink({
  uri: getBaseUrl("peoples"),
});

const clientPeople = new ApolloClient({
  link: errorLink.concat(from([authMiddleware, httpLinkPeople])),
  cache: new InMemoryCache(),
});

const httpLinkLocal = createHttpLink({
  uri: getBaseUrl("locations"),
});

const clientLocal = new ApolloClient({
  link: errorLink.concat(from([authMiddleware, httpLinkLocal])),
  cache: new InMemoryCache(),
});

const httpLinkEvent = createHttpLink({
  uri: getBaseUrl("events"),
});

const clientEvent = new ApolloClient({
  link: errorLink.concat(from([authMiddleware, httpLinkEvent])),
  cache: new InMemoryCache(),
});

const httpLinkBlog = createHttpLink({
  uri: getBaseUrl("blogs"),
});

const clientBlog = new ApolloClient({
  link: errorLink.concat(from([authMiddleware, httpLinkBlog])),
  cache: new InMemoryCache(),
});

export {
  clientAuth,
  clientBlog,
  clientEvent,
  clientLocal,
  clientPeople,
  clientSystem
};

