import { ProTable } from "../../../../components/table/pro-table/ProTable";
import { EventModel, EventsFunctions } from "./Events.functions";

export const Events = () => {
  const {
    queryName,
    columns,
    columnsMap,
    clientEvent,
    whereDefault,
    orderDefault,
    variables,
    setVariables,
    toolBarRender,
  } = EventsFunctions();

  return (
    <>
      <ProTable<EventModel>
        client={clientEvent}
        queryName={queryName}
        whereType="EventModelFilterInput"
        whereDefault={whereDefault}
        orderType="EventModelSortInput"
        orderDefault={orderDefault}
        variables={variables}
        setVariables={setVariables}
        columns={columns()}
        defaultColumnsMap={columnsMap}
        toolBarRender={toolBarRender}
        onFormatColumnsMap={(columns) => {
          columns = columns?.replace(
            "group",
            `group {
              id
              name
            }`
          );

          columns = columns?.replace("sector", ``);

          columns = columns?.replace(
            "type",
            `type {
              id
              name
            }`
          );

          columns = columns?.replace(
            "local",
            `local {
              id
              name
              city {
                cityName
                surname
              }
              sector {
                id
                name
                parent {
                  name
                }
              }
            }`
          );

          columns = columns?.replace(
            "people",
            `people {
              id
              name
            }`
          );

          return columns;
        }}
      />
    </>
  );
};
