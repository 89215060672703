import { Form } from "antd";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../../../../contexts/auth";
import { useMain } from "../../../../../contexts/main";
import { LocalStorageService } from "../../../../../services/localStorage.service";
import { AuthRoutes } from "../../../routes.enum";

const LoginFormFunctions = () => {
  const { login, loginWithGoogle, signOut } = useAuth();
  const { loading } = useMain();
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [passwordVisible, setPasswordVisible] = useState(false);

  useEffect(() => {
    LocalStorageService.removeAllLocalStorage();
    signOut();
  }, []);

  const handleRegister = () => {
    navigate(AuthRoutes.REGISTER);
  };

  const handleForgotPassword = () => {
    navigate(AuthRoutes.FORGOT_PASSWORD);
  };

  const validateMessages = {
    required: "${label} é obrigatorio!",
    types: {
      email: "${label} não é válido",
    },
  };

  return {
    validateMessages,
    form,
    passwordVisible,
    setPasswordVisible,
    handleRegister,
    handleForgotPassword,
    loading,
    login,
    loginWithGoogle
  };
};

export { LoginFormFunctions };

