enum PeopleRoutes {
  Peoples = '/peoples',
  PeopleCreate = '/people',
  PeopleEdit = '/people/:id',
  
  PeopleRoles = '/people-roles',
  PeopleRoleCreate = '/people-role',
  PeopleRoleEdit = '/people-role/:id',
}

export { PeopleRoutes };
