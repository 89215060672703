import { Avatar, Card, Col, Row, Skeleton, Space } from "antd";
import style from "./PeopleForm.module.scss";
import { UserOutlined } from "@ant-design/icons";
import Title from "antd/es/typography/Title";
import { PeopleCreateAccountFunctions } from "./PeopleForm.functions";
import { PeopleGeneralData } from "./people-general-data/PeopleGeneralData";
import { PeopleAvatar } from "./people-avatar/PeopleAvatar";

export const PeopleForm = () => {
  const { surname, setSurname, id } = PeopleCreateAccountFunctions();

  return (
    <Row gutter={[24, 0]}>
      <Col span={24} lg={18} push={6}>
        <Col className={style["cards"]}>
          <PeopleGeneralData setSurname={setSurname} />
        </Col>
      </Col>
      <Col span={24} lg={6} pull={18}>
        <Card className={style["card-border"]}>
          <Row justify={"center"}>
            <Col span={24}>
              <Space direction="vertical">
                <Row justify={"center"}>
                  {!surname && id && <Skeleton.Avatar active size={100} />}
                  {(surname || !id) && <PeopleAvatar id={id || ""} />}
                </Row>
                <Row justify={"center"}>
                  {!surname && <Skeleton.Button />}
                  {surname && (
                    <Title level={3} style={{ textAlign: "center" }}>
                      {surname}
                    </Title>
                  )}
                </Row>
              </Space>
            </Col>
          </Row>
        </Card>
      </Col>
    </Row>
  );
};
