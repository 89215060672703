import React, { useEffect, useState } from "react";
import { useMain } from "../../../../../contexts/main";
import { useNavigate, useParams } from "react-router-dom";
import { clientBlog } from "../../../../../services/graphql.service";
import { useMutation, useQuery } from "@apollo/client";
import { Form, FormInstance } from "antd";
import { BlogRoutes } from "../../../routes.enum";
import {
  createBlog,
  getBlogByIdQuery,
  getCategoriesSimpleSelectQuery,
  getTagsSelectQuery,
  updateBlog,
} from "../../../services/blog.service";
import { CategoryModel } from "../../category/Categories.functions";
import { OptionProps } from "antd/es/select";

export const BlogFormFunctions = () => {
  const { errorMessage, loading, setLoading, setTitlePage } = useMain();
  const { handleChangeBreadcumb } = useMain();
  const { id } = useParams();
  const navigate = useNavigate();
  const title = !id ? "Novo blog" : "Alterar o blog";
  const [content, setContent] = useState<string | null>();

  useEffect(() => {
    setLoading(false);
    handleChangeBreadcumb([
      {
        title: "Home",
        path: "/",
      },
      {
        title: "Blogs",
        path: BlogRoutes.Blogs,
      },
      {
        title: title,
      },
    ]);
    setTitlePage(title);
  }, [id]);

  const [form] = Form.useForm<{
    name: string;
    date: Date;
    category: CategoryModel;
  }>();
  const formRef = React.useRef<FormInstance>(null);

  const { loading: loadingGet } = useQuery(getBlogByIdQuery(), {
    client: clientBlog,
    variables: { id },
    fetchPolicy: "no-cache",
    onCompleted: (data) => {
      const result = data.blogById;
      result.categoryId = result.category?.code;
      formRef.current?.setFieldsValue(result);
      setContent(result.content);
    },
    errorPolicy: "all",
    onError: ({ graphQLErrors, networkError }) => {
      if (!id) return;

      if (graphQLErrors)
        graphQLErrors.forEach(({ message, locations, path }) =>
          errorMessage(message)
        );
      if (networkError)
        errorMessage("Ocorreu um erro inesperado. Tente novamente mais tarde!");
    },
  });

  const [categorias, setCategorias] = useState<CategoryModel[]>([]);
  const { loading: loadingCategoriassGet } = useQuery(
    getCategoriesSimpleSelectQuery(),
    {
      client: clientBlog,
      fetchPolicy: "no-cache",
      variables: { skip: 0, take: 50 },
      onCompleted: (data) => {
        const { items } = data.category;
        if (items) {
          const list = items?.map((_: CategoryModel) => ({
            label: _.title,
            value: _.code,
          }));
          setCategorias(list);
        }
      },
      errorPolicy: "all",
      onError: ({ graphQLErrors, networkError }) => {
        if (!id) return;

        if (graphQLErrors)
          graphQLErrors.forEach(({ message, locations, path }) =>
            errorMessage(message)
          );
        if (networkError)
          errorMessage(
            "Ocorreu um erro inesperado. Tente novamente mais tarde!"
          );
      },
    }
  );

  const [tags, setTags] = useState<OptionProps[]>([]);
  const { loading: loadingTagsGet } = useQuery(getTagsSelectQuery(), {
    client: clientBlog,
    fetchPolicy: "no-cache",
    variables: { skip: 0, take: 50 },
    onCompleted: (data) => {
      const items = data.allTagsAsync.data;
      if (items) {
        const list = items?.map((_: string) => ({
          label: _,
          value: _,
        }));
        setTags(list);
      }
    },
    errorPolicy: "all",
    onError: ({ graphQLErrors, networkError }) => {
      if (!id) return;

      if (graphQLErrors)
        graphQLErrors.forEach(({ message, locations, path }) =>
          errorMessage(message)
        );
      if (networkError)
        errorMessage("Ocorreu um erro inesperado. Tente novamente mais tarde!");
    },
  });

  const [sendUpdateUserMutation] = useMutation(updateBlog(), {
    client: clientBlog,
  });
  const [sendCreateUserMutation] = useMutation(createBlog(), {
    client: clientBlog,
  });

  const onFinish = (values: any) => {
    setLoading(true);
    const { code, title, categoryId, breadCrumb, tags } = values;

    if (id) {
      sendUpdateUserMutation({
        variables: {
          id,
          request: {
            code,
            title,
            categoryId,
            content,
            breadCrumb,
            tags,
          },
        },
      }).then((result) => {
        if (result.errors) {
          console.log({ errors: result.errors });
          errorMessage("Houve um erro ao realizar a alterar o blog.");
          setLoading(false);
          return;
        }

        const { success } = result.data.updateBlog;
        if (!success) {
          errorMessage("Houve um erro ao realizar a alterar o blog.");
          setLoading(false);
          return;
        }

        setLoading(false);
        navigate(BlogRoutes.Blogs);
      });
    } else {
      sendCreateUserMutation({
        variables: {
          request: {
            title,
            categoryId,
            content,
            breadCrumb,
            tags,
          },
        },
      }).then((result) => {
        if (result.errors) {
          console.log({ errors: result.errors });
          errorMessage("Houve um erro ao realizar a alterar o blog.");
          setLoading(false);
          return;
        }

        const { success, message } = result.data.createBlog;
        if (!success) {
          errorMessage(
            message ?? "Houve um erro ao realizar a alterar o blog."
          );
          setLoading(false);
          return;
        }

        setLoading(false);
        navigate(BlogRoutes.Blogs);
      });
    }
  };

  return {
    id,
    title,
    form,
    formRef,
    onFinish,
    loadingGet,
    loading,
    categorias,
    loadingCategoriassGet,
    content,
    setContent,
    tags,
    loadingTagsGet,
  };
};
