import { useEffect, useState } from "react";
import { useAuth } from "../../../../contexts/auth";
import { useMain } from "../../../../contexts/main";

export const UserCreateAccountFunctions = () => {
  const [name, setName] = useState<string>();
  const { handleChangeBreadcumb, setTitlePage } = useMain();
  const { regionals, administrations, sectors } = useAuth();

  useEffect(() => {
    const title = "Meu perfil";
    handleChangeBreadcumb([
      {
        title: "Home",
        path: "/",
      },
      {
        title: title,
      },
    ]);

    setTitlePage(title);
  }, []);

  return { name, setName, regionals, administrations, sectors };
};
