import React, { useEffect, useState } from "react";
import { useMain } from "../../../../../contexts/main";
import { useNavigate, useParams } from "react-router-dom";
import { useMutation, useQuery } from "@apollo/client";
import { Form, FormInstance } from "antd";
import { BlogRoutes } from "../../../routes.enum";
import {
  createCategory,
  getCategoryByIdQuery,
  updateCategory,
} from "../../../services/blog.service";
import { SimpleModel } from "../../../../../models/simplemodel.interface";
import dayjs from "dayjs";
import { clientBlog } from "../../../../../services/graphql.service";

export const CategoryFormFunctions = () => {
  const { errorMessage, loading, setLoading, setTitlePage } = useMain();
  const { handleChangeBreadcumb } = useMain();
  const { id } = useParams();
  const navigate = useNavigate();
  const title = !id ? "Nova categoria" : "Alterar a categoria";
  const [description, setDescription] = useState();

  useEffect(() => {
    setLoading(false);
    handleChangeBreadcumb([
      {
        title: "Home",
        path: "/",
      },
      {
        title: "Categorias",
        path: BlogRoutes.Categories,
      },
      {
        title: title,
      },
    ]);
    setTitlePage(title);
  }, [id]);

  const [form] = Form.useForm<{
    name: string;
    date: Date;
    regional: SimpleModel;
  }>();
  const formRef = React.useRef<FormInstance>(null);

  const { loading: loadingGet } = useQuery(getCategoryByIdQuery(), {
    client: clientBlog,
    variables: { id },
    fetchPolicy: "no-cache",
    onCompleted: (data) => {
      const result = data.categoryById;
      formRef.current?.setFieldsValue(result);
    },
    errorPolicy: "all",
    onError: ({ graphQLErrors, networkError }) => {
      if (!id) return;

      if (graphQLErrors)
        graphQLErrors.forEach(({ message, locations, path }) =>
          errorMessage(message)
        );
      if (networkError)
        errorMessage("Ocorreu um erro inesperado. Tente novamente mais tarde!");
    },
  });

  const [sendUpdateUserMutation] = useMutation(updateCategory(), {
    client: clientBlog,
  });
  const [sendCreateUserMutation] = useMutation(createCategory(), {
    client: clientBlog,
  });

  const onFinish = (values: any) => {
    setLoading(true);
    const { title, description } = values;

    if (id) {
      sendUpdateUserMutation({
        variables: {
          id,
          request: {
            title,
            description: description,
          },
        },
      }).then((result) => {
        if (result.errors) {
          console.log({ errors: result.errors });
          errorMessage("Houve um erro ao realizar a alterar a reunião.");
          setLoading(false);
          return;
        }

        const { success } = result.data.updateCategory;
        if (!success) {
          errorMessage("Houve um erro ao realizar a alterar a reunião.");
          setLoading(false);
          return;
        }

        setLoading(false);
        navigate(BlogRoutes.Categories);
      });
    } else {
      sendCreateUserMutation({
        variables: {
          request: {
            title,
            description: description,
          },
        },
      }).then((result) => {
        if (result.errors) {
          console.log({ errors: result.errors });
          errorMessage("Houve um erro ao realizar a alterar a reunião.");
          setLoading(false);
          return;
        }

        const { success, message } = result.data.createCategory;
        if (!success) {
          errorMessage(
            message ?? "Houve um erro ao realizar a alterar a reunião."
          );
          setLoading(false);
          return;
        }

        setLoading(false);
        navigate(BlogRoutes.Categories);
      });
    }
  };

  return {
    id,
    title,
    form,
    formRef,
    onFinish,
    loadingGet,
    loading,
    description,
    setDescription,
  };
};
