import { ExclamationCircleFilled } from "@ant-design/icons";
import { useMutation, useQuery } from "@apollo/client";
import { Modal } from "antd";
import { OptionProps } from "antd/es/select";
import { useCallback, useEffect, useState } from "react";
import { useMain } from "../../../../contexts/main";
import useUtils from "../../../../hooks/useUtils";
import { clientEvent, clientLocal } from "../../../../services/graphql.service";
import { LocalStorageService } from "../../../../services/localStorage.service";
import { EventGroupModel } from "../../../events/pages/event-group/EventGroups.functions";
import {
    EventModel,
    EventStatusEnum,
    getEventName,
} from "../../../events/pages/events/Events.functions";
import {
    deleteEvent,
    getEventGroupsSelectQuery,
} from "../../../events/services/events.service";
import { SectorModel } from "../../../locations/pages/sector/Sectors.functions";
import { getSectorsSelectQuery } from "../../../locations/services/locations.service";
import {
    getPendingBySectorStatisticsAsync,
    getPendingEventsQuery,
    getPendingStatisticsAsync,
    pendingConfirmStatistics,
    updateStatusEvent,
} from "../../services/home.service";

const { confirm } = Modal;

export const PendingEventsListFunctions = () => {
  const {
    errorMessage,
    handleChangeBreadcumb,
    setTitlePage,
    loading,
    setLoading,
  } = useMain();
  const [list, setList] = useState<any[]>([]);
  const [total, setTotal] = useState<number>(0);
  const [skip, setSkip] = useState<number>(0);
  const [take, setTake] = useState<number>(10);
  const { formatDateWithDayMonth } = useUtils();

  useEffect(() => {
    const title = "Eventos pendentes";
    handleChangeBreadcumb([
      {
        title: "Home",
        path: "/",
      },
      {
        title: title,
      },
    ]);

    setTitlePage(title);
  }, []);

  const [sectorsId, setSectorsId] = useState<string[] | null>(
    LocalStorageService.getLocalStorageJSON<string[]>("sectors") ?? null
  );

  const [group, setGroup] = useState<EventGroupModel | null>(
    LocalStorageService.getLocalStorageJSON<EventGroupModel>("group") ?? null
  );

  const [status, setStatus] = useState<EventStatusEnum | null>();

  const getFilterList = useCallback(() => {
    let where = {
      status: {
        in: !status
          ? [EventStatusEnum.Pending, EventStatusEnum.Confirmed]
          : [status],
      },
    } as any;
    if (group) {
      where = {
        status: {
          in: !status
            ? [EventStatusEnum.Pending, EventStatusEnum.Confirmed]
            : [status],
        },
        group: { id: { eq: group?.id } },
      };

      if (sectorsId && sectorsId.length > 0 && sectorsId.findIndex(s => s === "todos-sec") < 0) {
        where = {
          status: {
            in: !status
              ? [EventStatusEnum.Pending, EventStatusEnum.Confirmed]
              : [status],
          },
          group: { id: { eq: group?.id } },
          local: { sector: { id: { in: sectorsId } } },
        };
      }
    }
    return where;
  }, [group, sectorsId, status]);

  const { refetch, loading: loadingGet } = useQuery(getPendingEventsQuery(), {
    client: clientEvent,
    variables: {
      skip: skip * take,
      take,
      where: getFilterList(),
    },
    onCompleted: (data) => {
      setList(data.events.items);
      setTotal(data.events.totalCount);
    },
    errorPolicy: "all",
    onError: ({ graphQLErrors, networkError }) => {
      if (graphQLErrors)
        graphQLErrors.forEach(({ message }) => errorMessage(message));
      if (networkError)
        errorMessage("Ocorreu um erro inesperado. Tente novamente mais tarde!");
    },
  });

  const [groups, setGroups] = useState<OptionProps[]>([]);
  const { loading: loadingGroupsGet } = useQuery(getEventGroupsSelectQuery(), {
    client: clientEvent,
    fetchPolicy: "cache-and-network",
    variables: { skip: 0, take: 50 },
    onCompleted: (data) => {
      const { items } = data.eventGroups;
      if (items) {
        const list = items?.map((_: EventGroupModel) => ({
          label: `${_.regional?.name} - ${_.name}`,
          value: _.id,
          item: _,
        }));
        setGroups(list);
      }
    },
    errorPolicy: "all",
    onError: ({ graphQLErrors, networkError }) => {
      if (graphQLErrors)
        graphQLErrors.forEach(({ message, locations, path }) =>
          errorMessage(message)
        );
      if (networkError)
        errorMessage("Ocorreu um erro inesperado. Tente novamente mais tarde!");
    },
  });

  const [sectors, setSectors] = useState<OptionProps[]>([]);
  const { loading: loadingSectorsGet } = useQuery(getSectorsSelectQuery(), {
    client: clientLocal,
    fetchPolicy: "cache-and-network",
    variables: { skip: 0, take: 50 },
    onCompleted: (data) => {
      const { items } = data.sectors;
      if (items) {
        const list = items?.map((_: SectorModel) => ({
          label: `${_.administration?.parent?.name} - ${_.administration?.name} - ${_.name}`,
          value: _.id,
        }));
        setSectors([
          {
            label: "Todos-sec",
            value: "",
          },
          ...list,
        ]);
      }
    },
    errorPolicy: "all",
    onError: ({ graphQLErrors, networkError }) => {
      if (graphQLErrors)
        graphQLErrors.forEach(({ message, locations, path }) =>
          errorMessage(message)
        );
      if (networkError)
        errorMessage("Ocorreu um erro inesperado. Tente novamente mais tarde!");
    },
  });

  const loadMore = () => {
    const newSkip = skip + 1;
    setSkip(newSkip);
    refetch({ skip: newSkip * take, take }).then((response) => {
      if (response.data) {
        const data = response.data.events;
        setList([...list, ...data.items]);
        setTotal(data.totalCount);
      }
    });
  };

  const [updateStatusMutation] = useMutation(updateStatusEvent(), {
    client: clientEvent,
    errorPolicy: "all",
    onCompleted: async () => {
      filter();
    },
    onError: ({ graphQLErrors, networkError }) => {
      if (graphQLErrors)
        graphQLErrors.forEach(({ message, locations, path }) =>
          errorMessage(message)
        );
      if (networkError)
        errorMessage("Ocorreu um erro inesperado. Tente novamente mais tarde!");

      setLoading(false);
    },
  });

  const onClickValidateEvent = (item: EventModel) => {
    const content = `Evento: ${formatDateWithDayMonth(item?.dateNotFormat)} às ${item?.hour} em ${getEventName(item)}`;
    confirm({
      title: `Deseja realmente validar os dados do evento ?`,
      icon: <ExclamationCircleFilled />,
      content: content,
      okText: "Sim",
      cancelText: "Não",
      onOk() {
        return updateStatusMutation({
          variables: {
            id: item?.id,
            status: EventStatusEnum.Validated,
          },
        });
      },
      onCancel() {
        setLoading(false);
      },
    });
  };

  const onClickConfirmedEvent = (item: EventModel) => {
    const content = `Evento: ${formatDateWithDayMonth(item?.dateNotFormat)} às ${item?.hour} em ${getEventName(item)}`;
    confirm({
      title: `Deseja realmente confirmar os dados do evento ?`,
      icon: <ExclamationCircleFilled />,
      content: content,
      okText: "Sim",
      cancelText: "Não",
      onOk() {
        return updateStatusMutation({
          variables: {
            id: item.id,
            status: EventStatusEnum.Confirmed,
          },
        });
      },
      onCancel() {
        setLoading(false);
      },
    });
  };

  const [dataChart, setDataChart] = useState();
  const { refetch: refetchChart, loading: loadingChart } = useQuery(
    getPendingBySectorStatisticsAsync(),
    {
      client: clientEvent,
      variables: { groupId: group?.id ?? "", sectorsId: sectorsId ?? "" },
      onCompleted: (result) => {
        const data = result.pendingBySectorStatistics.data;
        setDataChart(data);
      },
      errorPolicy: "all",
      onError: ({ graphQLErrors, networkError }) => {
        if (graphQLErrors)
          graphQLErrors.forEach(({ message }) => errorMessage(message));
        if (networkError)
          errorMessage(
            "Ocorreu um erro inesperado. Tente novamente mais tarde!"
          );
      },
    }
  );

  const [percent, setPercent] = useState(0);
  const { refetch: refetchChartPercent, loading: loadingChartPercent } =
    useQuery(getPendingStatisticsAsync(), {
      client: clientEvent,
      variables: { groupId: group?.id ?? "", sectorsId: sectorsId ?? "" },
      onCompleted: (result) => {
        const data = result.pendingStatistics.data;
        if (data.length > 0) setPercent(data[0]?.value);
        else setPercent(0);
      },
      errorPolicy: "all",
      onError: ({ graphQLErrors, networkError }) => {
        if (graphQLErrors)
          graphQLErrors.forEach(({ message }) => errorMessage(message));
        if (networkError)
          errorMessage(
            "Ocorreu um erro inesperado. Tente novamente mais tarde!"
          );
      },
    });

  const [percentConfirm, setPercentConfirm] = useState(0);
  const { refetch: refetchPercentConfirm, loading: loadingPercentConfirm } =
    useQuery(pendingConfirmStatistics(), {
      client: clientEvent,
      variables: { groupId: group?.id ?? "", sectorsId: sectorsId ?? "" },
      onCompleted: (result) => {
        const data = result.pendingConfirmStatistics.data;
        if (data.length > 0) setPercentConfirm(data[0]?.value);
        else setPercentConfirm(0);
      },
      errorPolicy: "all",
      onError: ({ graphQLErrors, networkError }) => {
        if (graphQLErrors)
          graphQLErrors.forEach(({ message }) => errorMessage(message));
        if (networkError)
          errorMessage(
            "Ocorreu um erro inesperado. Tente novamente mais tarde!"
          );
      },
    });

  const filter = useCallback(() => {
    setLoading(true);
    const newSkip = skip === 0 ? 1 : skip;
    refetch({
      skip: 0,
      take: newSkip * take,
      where: getFilterList(),
    })
      .then((response) => {
        if (response.data) {
          const data = response.data.events;
          setList([...data.items]);
          setTotal(data.totalCount);
        }
      })
      .finally(() => {
        setLoading(false);
      });
    refetchChart({ groupId: group?.id ?? "", sectorsId: sectorsId ?? "" })
      .then((result) => {
        const data = result.data.pendingBySectorStatistics.data;
        setDataChart(data);
      })
      .finally(() => {
        setLoading(false);
      });
    refetchChartPercent({
      groupId: group?.id ?? "",
      sectorsId: sectorsId ?? "",
    })
      .then((result) => {
        const data = result.data.pendingStatistics.data;
        if (data.length > 0) setPercent(data[0]?.value);
        else setPercent(0);
      })
      .finally(() => {
        setLoading(false);
      });
    refetchPercentConfirm({
      groupId: group?.id ?? "",
      sectorsId: sectorsId ?? "",
    })
      .then((result) => {
        const data = result.data.pendingConfirmStatistics.data;
        if (data.length > 0) setPercentConfirm(data[0]?.value);
        else setPercentConfirm(0);
      })
      .finally(() => {
        setLoading(false);
      });

    if (group) LocalStorageService.setLocalStorageJSON("group", group);
    else LocalStorageService.removeLocalStorage("group");

    if (sectorsId)
      LocalStorageService.setLocalStorageJSON("sectors", sectorsId);
    else LocalStorageService.removeLocalStorage("sector.id");
  }, [group, sectorsId, skip, take]);

  const [deleteEventMutation] = useMutation(deleteEvent(), {
    client: clientEvent,
    errorPolicy: "all",
    onCompleted: async () => {
      filter();
    },
    onError: ({ graphQLErrors, networkError }) => {
      if (graphQLErrors)
        graphQLErrors.forEach(({ message, locations, path }) =>
          errorMessage(message)
        );
      if (networkError)
        errorMessage("Ocorreu um erro inesperado. Tente novamente mais tarde!");

      setLoading(false);
    },
  });

  const onClickDeleteEvent = (item: EventModel) => {
    const content = `Evento: ${formatDateWithDayMonth(item?.dateNotFormat)} às ${item?.hour} em ${getEventName(item)}`;
    confirm({
      title: `Deseja realmente deletar o evento ?`,
      icon: <ExclamationCircleFilled />,
      content: content,
      okText: "Sim",
      cancelText: "Não",
      onOk() {
        return deleteEventMutation({
          variables: {
            id: item?.id,
          },
        });
      },
      onCancel() {
        setLoading(false);
      },
    });
  };

  useEffect(() => {
    setLoading(true);
    const newSkip = skip === 0 ? 1 : skip;
    refetch({
      skip: 0,
      take: newSkip * take,
      where: getFilterList(),
    })
      .then((response) => {
        if (response.data) {
          const data = response.data.events;
          setList([...data.items]);
          setTotal(data.totalCount);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }, [status]);

  return {
    list,
    loadingGet,
    loading,
    total,
    loadMore,
    onClickValidateEvent,
    onClickConfirmedEvent,
    onClickDeleteEvent,
    dataChart,
    loadingChart,
    percent,
    loadingChartPercent,
    groups,
    loadingGroupsGet,
    group,
    setGroup,
    sectors,
    loadingSectorsGet,
    sectorsId,
    setSectorsId,
    filter,
    percentConfirm,
    loadingPercentConfirm,
    status,
    setStatus,
  };
};
