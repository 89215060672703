import { Modal } from "antd";
import React from "react";
import { IAvatarRequest } from "../../../../models/AvatarRequest.interface";
import { AvatarField } from "./avatar-field/AvatarField";
import { UpdateAvatarModalFunctions } from "./UpdateAvatarModal.functions";

interface UpdateAvatarModalProps {
  bucketName?: string;
  id?: string;
  collectionName?: string;
  fieldName?: string;
  src: string;
  setSrc?: React.Dispatch<React.SetStateAction<string | undefined>>;
  onCancel: () => void;
  open: boolean;
  updateAvatar?: (data: IAvatarRequest, params?: any) => Promise<Response>;
  params?: any;
}

const UpdateAvatarModal: React.FC<UpdateAvatarModalProps> = (
  props: UpdateAvatarModalProps
) => {
  const {
    bucketName,
    id,
    collectionName,
    fieldName,
    src,
    setSrc,
    onCancel,
    open,
    updateAvatar,
    params,
  } = props;
  const { } = UpdateAvatarModalFunctions(
    updateAvatar,
    params
  );

  return (
    <Modal
      title={"Atualizar avatar"}
      onCancel={onCancel}
      open={open}
      centered
      maskClosable={false}
      width={290}
      footer={null}>
      <AvatarField
        bucketName={bucketName}
        id={id}
        collectionName={collectionName}
        fieldName={fieldName}
        src={src}
        setSrc={setSrc}
      />
    </Modal>
  );
};

export { UpdateAvatarModal };
