import { ProConfigProvider } from "@ant-design/pro-components";
import { ConfigProvider, theme } from "antd";
import pt_BR from "antd/es/locale/pt_BR";
import "normalize.css";
import React, { useEffect, useState } from "react";
import { useAuth } from "./contexts/auth";
import "./index.scss";

export const useThemeDetector = () => {
  const getCurrentTheme = () =>
    window.matchMedia("(prefers-color-scheme: dark)").matches;
  const [isDarkTheme, setIsDarkTheme] = useState(getCurrentTheme());
  const mqListener = (e: any) => {
    setIsDarkTheme(e.matches);
  };

  useEffect(() => {
    const darkThemeMq = window.matchMedia("(prefers-color-scheme: dark)");
    darkThemeMq.addListener(mqListener);
    return () => darkThemeMq.removeListener(mqListener);
  }, []);
  return isDarkTheme;
};
const { darkAlgorithm, defaultAlgorithm } = theme;

interface ThemeProviderProps {
  children: React.ReactNode;
}

export const ThemeProvider: React.FC<ThemeProviderProps> = ({ children }) => {
  const { isDarkTheme } = useAuth();

  return (
    <ConfigProvider
      locale={pt_BR}
      theme={{
        algorithm: isDarkTheme ? darkAlgorithm : defaultAlgorithm,
        token: {
          colorPrimary: "#1890FF",
          colorPrimaryHover: "#003e79",
          colorLink: "#1890FF",
          colorLinkHover: "#003e79",
          borderRadius: 6,
        },
      }}>
      <ProConfigProvider
        dark={isDarkTheme ? true : false}
        token={{
          colorPrimary: "#1890FF",
          colorPrimaryHover: "#003e79",
          colorLink: "#1890FF",
          colorLinkHover: "#003e79",
          borderRadius: 6,
        }}>
        {children}
      </ProConfigProvider>
    </ConfigProvider>
  );
};
