import { ExclamationCircleFilled, PlusOutlined } from "@ant-design/icons";
import type { ColumnsState } from "@ant-design/pro-components";
import { useMutation, useQuery } from "@apollo/client";
import { Button, Modal, Space, Tooltip } from "antd";
import { useEffect, useState } from "react";
import { MdDelete, MdEdit } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { ProColumnsProps } from "../../../../components/table/pro-table/ProTable";
import { useAuth } from "../../../../contexts/auth";
import { useMain } from "../../../../contexts/main";
import { SimpleModel } from "../../../../models/simplemodel.interface";
import {
  clientEvent,
  clientLocal,
  clientPeople,
} from "../../../../services/graphql.service";
import { LocalStorageService } from "../../../../services/localStorage.service";
import { SimpleUserModel } from "../../../account/pages/users/Users.functions";
import { LocalModel } from "../../../locations/pages/local/Locals.functions";
import { SectorModel } from "../../../locations/pages/sector/Sectors.functions";
import {
  getLocalsSelectQuery,
  getSectorsSelectQuery,
} from "../../../locations/services/locations.service";
import { PeopleModel } from "../../../peoples/pages/people-management/Peoples.functions";
import { getPeoplesSelectQuery } from "../../../peoples/services/people.service";
import { EventsRoutes } from "../../routes.enum";
import {
  deleteEvent,
  getEventGroupsSimpleSelectQuery,
  getEventTypesSelectQuery,
} from "../../services/events.service";
import { EventGroupModel } from "../event-group/EventGroups.functions";
import { EventTypeModel } from "../event-type/EventTypes.functions";

export enum EventStatusEnum {
  Pending = "PENDING",
  Confirmed = "CONFIRMED",
  Validated = "VALIDATED",
}

export const getDescriptionEventStatus = (status: EventStatusEnum) => {
  if (status == EventStatusEnum.Pending) return "Pendente";
  if (status == EventStatusEnum.Confirmed) return "Confirmado";
  if (status == EventStatusEnum.Validated) return "Validado";
};

export const getColorEventStatus = (status: EventStatusEnum) => {
  if (status == EventStatusEnum.Confirmed) return "success";
  if (status == EventStatusEnum.Validated) return "success";

  return "warning";
};

export const getEventName = (item: EventModel) => {
  return `${item?.local?.city?.cityName ?? item?.local?.city?.surname} - ${
    item?.local?.name ?? item?.local?.name
  }`;
};

export type HistoryModel = {
  updatedAt: Date;
  updatedBy: SimpleUserModel;
  detailedCompare: {
    propertyName: string;
    before: string;
    after: string;
  }[];
};

export type EventModel = {
  id: string;
  name: string;
  date: string;
  status: EventStatusEnum;
  dateNotFormat: string;
  hour: string;
  group: EventGroupModel;
  type: SimpleModel;
  local: LocalModel;
  people: SimpleModel;
  histories: HistoryModel[];
};

export interface Response<T> {
  nodes: T[];
  pageInfo: {
    hasNextPage: boolean;
    hasPreviousPage: boolean;
  };
  totalCount: number;
}

enum Sort {
  ASC = "ASC",
  DESC = "DESC",
}

const { confirm } = Modal;
const queryName = "events";

export const EventsFunctions = () => {
  const navigate = useNavigate();
  const { errorMessage, handleChangeBreadcumb, setTitlePage, setLoading } =
    useMain();
  const { hasPermission } = useAuth();

  useEffect(() => {
    handleChangeBreadcumb([
      {
        title: "Home",
        path: "/",
      },
      {
        title: "Eventos",
      },
    ]);
    setTitlePage("Eventos");
  }, []);

  const onClickCreate = () => {
    navigate(EventsRoutes.EventCreate);
  };

  const onClickEdit = (id: string) => {
    const url = EventsRoutes.EventEdit.replace(":id", id);
    navigate(url);
  };

  const [deleteMutation] = useMutation(deleteEvent(), {
    client: clientEvent,
    errorPolicy: "all",
    onCompleted: () => {
      setVariables({ ...variables });

      setLoading(false);
    },
    onError: ({ graphQLErrors, networkError }) => {
      if (graphQLErrors)
        graphQLErrors.forEach(({ message, locations, path }) =>
          errorMessage(message)
        );
      if (networkError)
        errorMessage("Ocorreu um erro inesperado. Tente novamente mais tarde!");

      setLoading(false);
    },
  });

  const onClickDelete = (id: string) => {
    return deleteMutation({
      variables: {
        id,
      },
    });
  };

  const toolBarRender = () => [
    hasPermission(["admin", "admin-system"]) && (
      <Button
        key="button"
        icon={<PlusOutlined />}
        onClick={onClickCreate}
        type="primary"
      >
        Novo
      </Button>
    ),
  ];

  const { refetch: refetchLocals } = useQuery(getLocalsSelectQuery(), {
    client: clientLocal,
    fetchPolicy: "no-cache",
    variables: {
      skip: 0,
      take: 500,
    },
    errorPolicy: "all",
    onError: ({ graphQLErrors, networkError }) => {
      if (graphQLErrors)
        graphQLErrors.forEach(({ message, locations, path }) =>
          errorMessage(message)
        );
      if (networkError)
        errorMessage("Ocorreu um erro inesperado. Tente novamente mais tarde!");

      setLoading(false);
    },
  });

  const { refetch: refetchPeoples } = useQuery(getPeoplesSelectQuery(), {
    client: clientPeople,
    fetchPolicy: "no-cache",
    variables: {
      skip: 0,
      take: 500,
      role: "Ancião",
    },
    errorPolicy: "all",
    onError: ({ graphQLErrors, networkError }) => {
      if (graphQLErrors)
        graphQLErrors.forEach(({ message, locations, path }) =>
          errorMessage(message)
        );
      if (networkError)
        errorMessage("Ocorreu um erro inesperado. Tente novamente mais tarde!");

      setLoading(false);
    },
  });

  const { refetch: refetchEventGroups } = useQuery(
    getEventGroupsSimpleSelectQuery(),
    {
      client: clientEvent,
      fetchPolicy: "no-cache",
      variables: {
        skip: 0,
        take: 100,
      },
      errorPolicy: "all",
      onError: ({ graphQLErrors, networkError }) => {
        if (graphQLErrors)
          graphQLErrors.forEach(({ message, locations, path }) =>
            errorMessage(message)
          );
        if (networkError)
          errorMessage(
            "Ocorreu um erro inesperado. Tente novamente mais tarde!"
          );

        setLoading(false);
      },
    }
  );

  const { refetch: refetchSectors } = useQuery(getSectorsSelectQuery(), {
    client: clientLocal,
    fetchPolicy: "no-cache",
    variables: {
      skip: 0,
      take: 100,
    },
    errorPolicy: "all",
    onError: ({ graphQLErrors, networkError }) => {
      if (graphQLErrors)
        graphQLErrors.forEach(({ message, locations, path }) =>
          errorMessage(message)
        );
      if (networkError)
        errorMessage("Ocorreu um erro inesperado. Tente novamente mais tarde!");

      setLoading(false);
    },
  });

  const { refetch: refetchEventTypes } = useQuery(getEventTypesSelectQuery(), {
    client: clientEvent,
    fetchPolicy: "no-cache",
    variables: {
      skip: 0,
      take: 100,
    },
    errorPolicy: "all",
    onError: ({ graphQLErrors, networkError }) => {
      if (graphQLErrors)
        graphQLErrors.forEach(({ message, locations, path }) =>
          errorMessage(message)
        );
      if (networkError)
        errorMessage("Ocorreu um erro inesperado. Tente novamente mais tarde!");

      setLoading(false);
    },
  });

  const columns = (): ProColumnsProps<EventModel>[] => [
    {
      title: "Reunião",
      dataIndex: ["group", "id"],
      valueType: "select",
      params: {},
      fieldProps: {
        showSearch: true,
      },
      initialValue: LocalStorageService.getLocalStorage(
        `${queryName}.group.id`
      ),
      request: async (params) => {
        const result = await refetchEventGroups();
        const { items } = result.data.eventGroups;
        if (items) {
          const list = items
            ?.map((_: EventGroupModel) => ({
              label: `${_.regional?.name} - ${_.name}`,
              value: _.id,
            }))
            .sort((objA: any, objB: any) =>
              objB?.label?.localeCompare(objA?.label)
            );
          return list;
        }
        return [];
      },
      render: (_, record) => {
        return record.group?.name;
      },
    },
    {
      title: "Setor",
      dataIndex: ["local", "sector", "id"],
      valueType: "select",
      params: {},
      initialValue: LocalStorageService.getLocalStorage(
        `${queryName}.local.sector.id`
      ),
      fieldProps: {
        showSearch: true,
      },
      request: async (params) => {
        const result = await refetchSectors();
        const { items } = result.data.sectors;
        if (items) {
          const list = items
            ?.map((_: SectorModel) => ({
              label: `${_?.administration?.name} - ${_.name}`,
              value: _.id,
            }))
            .sort((objA: any, objB: any) =>
              objA?.label?.localeCompare(objB?.label)
            );
          return list;
        }
        return [];
      },
      renderFormItem: (_, { defaultRender }) => {
        return defaultRender(_);
      },
      render: (_, record) =>
        !record.local?.sector
          ? ""
          : `${record.local?.sector?.parent?.name} - ${record.local?.sector?.name}`,
    },
    {
      title: "Serviço",
      dataIndex: ["type", "id"],
      valueType: "select",
      params: {},
      initialValue: LocalStorageService.getLocalStorage(`${queryName}.type.id`),
      fieldProps: {
        showSearch: true,
      },
      request: async (params) => {
        const result = await refetchEventTypes();
        const { items } = result.data.eventTypes;
        if (items) {
          const list = items
            ?.map((_: EventTypeModel) => ({
              label: _.name,
              value: _?.id,
            }))
            .sort((objA: any, objB: any) =>
              objA?.label?.localeCompare(objB?.label)
            );
          return list;
        }
        return [];
      },
      renderFormItem: (_, { defaultRender }) => {
        return defaultRender(_);
      },
      render: (_, record) => record.type?.name,
    },
    {
      title: "Data",
      valueType: "date",
      dataIndex: "date",
      filters: true,
      ellipsis: true,
      sorter: true,
      width: 100,
      renderFormItem: (_, { defaultRender }) => {
        return defaultRender(_);
      },
      render: (_, record) => {
        var dateString = record.date;

        var dateParts = dateString.split("/");

        var date = new Date(
          +dateParts[2],
          Number.parseInt(dateParts[1]) - 1,
          +dateParts[0]
        );
        return date.toLocaleDateString();
      },
    },
    {
      title: "Hora",
      dataIndex: "hour",
      filters: true,
      onFilter: true,
      ellipsis: true,
      sorter: true,
      width: 80,
      renderFormItem: (_, { defaultRender }) => {
        return defaultRender(_);
      },
      render: (_, record) => `${record.hour}`,
    },
    {
      title: "Nome",
      dataIndex: "name",
      search: false,
      ellipsis: true,
      sorter: true,
    },
    {
      title: "Localidade",
      dataIndex: ["local", "id"],
      valueType: "select",
      params: {},
      initialValue: LocalStorageService.getLocalStorage(
        `${queryName}.local.id`
      ),
      fieldProps: {
        showSearch: true,
      },
      request: async (params) => {
        const result = await refetchLocals();
        const { items } = result.data.locals;
        if (items) {
          const list = items
            ?.map((_: LocalModel) => ({
              label: `${_.city?.surname || _.city?.cityName} - ${_.name}`,
              value: _?.id,
            }))
            .sort((objA: any, objB: any) =>
              objA?.label?.localeCompare(objB?.label)
            );
          return list;
        }
        return [];
      },
      renderFormItem: (_, { defaultRender }) => {
        return defaultRender(_);
      },
      render: (_, record) =>
        `${record.local?.city?.surname || record.local?.city?.cityName} - ${
          record.local?.name
        }`,
    },
    {
      title: "Ancião",
      dataIndex: ["people", "id"],
      valueType: "select",
      params: {},
      initialValue: LocalStorageService.getLocalStorage(
        `${queryName}.people.id`
      ),
      fieldProps: {
        showSearch: true,
      },
      request: async (params) => {
        const result = await refetchPeoples();
        const { items } = result.data.peoples;
        if (items) {
          const list = items
            ?.map((_: PeopleModel) => ({
              label: _.surname || _.name,
              value: _?.id,
            }))
            .sort((objA: any, objB: any) =>
              objA?.label?.localeCompare(objB?.label)
            );
          return list;
        }
        return [];
      },
      renderFormItem: (_, { defaultRender }) => {
        return defaultRender(_);
      },
      render: (_, record) => record.people?.name,
    },
    {
      title: "Ações",
      key: "actions",
      search: false,
      width: 180,
      align: "center",
      permission: ["admin", "admin-system"],
      render: (_, record) => [
        <Space>
          <Tooltip title="Editar evento">
            <Button
              type="link"
              shape="circle"
              icon={<MdEdit />}
              onClick={() => onClickEdit(record.id)}
            />
          </Tooltip>
          <Tooltip title="Deletar evento">
            <Button
              type="link"
              shape="circle"
              icon={<MdDelete />}
              onClick={() => showDeleteConfirm(record.id, record.name)}
            />
          </Tooltip>
        </Space>,
      ],
    },
  ];

  const whereDefault = {
    group: {
      id: {
        neq: null,
      },
    },
  };

  const orderDefault = {
    date: Sort.ASC,
  };

  const [variables, setVariables] = useState({
    skip: 0,
    take: 10,
    where: whereDefault,
    order: { name: Sort.ASC },
  });

  const columnsMap = {
    group: {
      show: true,
    },
    sector: {
      show: true,
    },
    type: {
      disable: true,
      show: true,
    },
    date: {
      disable: true,
      show: true,
    },
    hour: {
      disable: true,
      show: true,
    },
    name: {
      show: false,
    },
    local: {
      disable: true,
      show: true,
    },
    people: {
      disable: true,
      show: true,
    },
  } as Record<string, ColumnsState>;

  const showDeleteConfirm = (id: string, name: string) => {
    confirm({
      title: `Deseja deletar o evento: ${name}?`,
      icon: <ExclamationCircleFilled />,
      content: "Você realmente deseja deletar este item?",
      okText: "Sim",
      okButtonProps: {
        style: { background: "var(--primary-color)" },
      },
      cancelText: "Não",
      onOk() {
        return onClickDelete(id);
      },
      onCancel() {},
    });
  };

  return {
    queryName,
    columns,
    columnsMap,
    clientEvent,
    whereDefault,
    orderDefault,
    variables,
    setVariables,
    toolBarRender,
  };
};
