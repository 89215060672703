import { StyleSheet, Text, View } from "@react-pdf/renderer";
import { FC, Fragment } from "react";
import { EventPrintResponse } from "../../../pages/print-report/PrintReports.functions";

const styles = StyleSheet.create({
  row: {
    flexDirection: "row",
    alignItems: "center",
    fontSize: "2.5mm",
    textTransform: "uppercase",
    letterSpacing: "-0.1mm",
    height: 10
  },
  date: {
    width: "8%",
  },
  hour: {
    width: "8%",
  },
  local: {
    width: "44%",
  },
  people: {
    width: "40%",
  },
});

interface TableRowProps {
  items: EventPrintResponse[];
}

const TableRow: FC<TableRowProps> = ({ items }) => {
  const rows = items.map((item, index) => (
    <View style={styles.row} key={`row-${index}`}>
      <Text style={styles.date}>
        {item.date.split("/")[0]}/{item.date.split("/")[1]}
      </Text>
      <Text style={styles.hour}>{item.hour}</Text>
      <Text style={styles.local}>{item.local}</Text>
      <Text style={styles.people}>{item.people}</Text>
    </View>
  ));
  return <Fragment>{rows}</Fragment>;
};

export default TableRow;
