import { useMutation } from "@apollo/client";
import { Button, Drawer, List, Typography } from "antd";
import classNames from "classnames";
import { AiOutlineSelect } from "react-icons/ai";
import { useAuth } from "../../../contexts/auth";
import { useMain } from "../../../contexts/main";
import { SimpleModel } from "../../../models/simplemodel.interface";
import {
  updateUserPrincipalAdministration,
  updateUserPrincipalRegional,
  updateUserPrincipalSector,
} from "../../../modules/account/services/account.service";
import { clientAuth } from "../../../services/graphql.service";
import styles from "./RegionalDrawer.module.scss";

interface RegionalDrawerProps {
  regionalDrawer: boolean;
  setRegionalDrawer: React.Dispatch<React.SetStateAction<boolean>>;
}

export const RegionalDrawer = (props: RegionalDrawerProps) => {
  const { regionalDrawer, setRegionalDrawer } = props;
  const { loading, errorMessage, setLoading } = useMain();

  const { auth, regionals, administrations, sectors, handleGetFilterUser } =
    useAuth();
  const id = auth.currentUser?.uid;

  const [sendUpdatePrincipalRegional] = useMutation(
    updateUserPrincipalRegional(),
    {
      client: clientAuth,
    }
  );

  const setPrincipalRegional = (regional: SimpleModel) => {
    setLoading(true);
    sendUpdatePrincipalRegional({
      variables: {
        id,
        request: {
          regionalId: regional.id,
        },
      },
    })
      .then((result) => {
        if (result.errors) {
          console.log({ errors: result.errors });
          errorMessage("Houve um erro ao atualizar a regional principal");
          setLoading(false);
          return;
        }

        const { success, message } = result.data.updateUserPrincipalRegional;
        if (!success) {
          errorMessage(
            `Houve um erro ao atualizar a regional principal: ${message}`
          );
          return;
        }
      })
      .finally(() => {
        auth.currentUser?.uid && handleGetFilterUser(auth.currentUser?.uid);
        setLoading(false);
      });
  };

  const [sendUpdatePrincipalAdministration] = useMutation(
    updateUserPrincipalAdministration(),
    {
      client: clientAuth,
    }
  );

  const setPrincipalAdministration = (administration: SimpleModel) => {
    setLoading(true);
    sendUpdatePrincipalAdministration({
      variables: {
        id,
        request: {
          administrationId: administration.id,
        },
      },
    })
      .then((result) => {
        if (result.errors) {
          console.log({ errors: result.errors });
          errorMessage("Houve um erro ao atualizar a administração principal");
          setLoading(false);
          return;
        }

        const { success, message } =
          result.data.updateUserPrincipalAdministration;
        if (!success) {
          errorMessage(
            `Houve um erro ao atualizar a regional administração: ${message}`
          );
          return;
        }
      })
      .finally(() => {
        auth.currentUser?.uid && handleGetFilterUser(auth.currentUser?.uid);
        setLoading(false);
      });
  };

  const [sendUpdatePrincipalSector] = useMutation(updateUserPrincipalSector(), {
    client: clientAuth,
  });

  const setPrincipalSector = (sector: SimpleModel) => {
    setLoading(true);
    sendUpdatePrincipalSector({
      variables: {
        id,
        request: {
          sectorId: sector.id,
        },
      },
    })
      .then((result) => {
        if (result.errors) {
          console.log({ errors: result.errors });
          errorMessage("Houve um erro ao atualizar a setor principal");
          setLoading(false);
          return;
        }

        const { success, message } = result.data.updateUserPrincipalSector;
        if (!success) {
          errorMessage(
            `Houve um erro ao atualizar a setor principal: ${message}`
          );
          return;
        }
      })
      .finally(() => {
        auth.currentUser?.uid && handleGetFilterUser(auth.currentUser?.uid);
        setLoading(false);
      });
  };

  return (
    <Drawer
      width={300}
      onClose={() => {
        setRegionalDrawer(false);
      }}
      destroyOnClose
      footer={false}
      open={regionalDrawer}>
      <List
        header={<Typography.Title level={4}>Regionais</Typography.Title>}
        loading={loading}
        itemLayout="horizontal"
        dataSource={regionals}
        renderItem={(item, index) => (
          <List.Item
            actions={[
              !item.isPrincipal && (
                <Button
                  type="text"
                  onClick={() => setPrincipalRegional(item)}
                  icon={<AiOutlineSelect />}
                />
              ),
            ]}>
            <List.Item.Meta
              title={
                <Typography.Text
                  className={classNames({
                    [styles["principal"]]: item.isPrincipal,
                  })}
                  ellipsis={{
                    tooltip: {
                      children: `${item.name}`,
                    },
                  }}>
                  {item.name}
                </Typography.Text>
              }
            />
          </List.Item>
        )}
      />

      <List
        header={<Typography.Title level={4}>Administrações</Typography.Title>}
        loading={loading}
        itemLayout="horizontal"
        dataSource={administrations}
        renderItem={(item, index) => (
          <List.Item
            actions={[
              !item.isPrincipal && (
                <Button
                  type="text"
                  onClick={() => setPrincipalAdministration(item)}
                  icon={<AiOutlineSelect />}
                />
              ),
            ]}>
            <List.Item.Meta
              title={
                <Typography.Text
                  className={classNames({
                    [styles["principal"]]: item.isPrincipal,
                  })}
                  ellipsis={{
                    tooltip: {
                      children: `${item.name}`,
                    },
                  }}>
                  {item.name}
                </Typography.Text>
              }
            />
          </List.Item>
        )}
      />

      <List
        header={<Typography.Title level={4}>Setores</Typography.Title>}
        loading={loading}
        itemLayout="horizontal"
        dataSource={sectors}
        renderItem={(item, index) => (
          <List.Item
            actions={[
              !item.isPrincipal && (
                <Button
                  type="text"
                  onClick={() => setPrincipalSector(item)}
                  icon={<AiOutlineSelect />}
                />
              ),
            ]}>
            <List.Item.Meta
              title={
                <Typography.Text
                  className={classNames({
                    [styles["principal"]]: item.isPrincipal,
                  })}
                  ellipsis={{
                    tooltip: {
                      children: `${item.name}`,
                    },
                  }}>
                  {item.name}
                </Typography.Text>
              }
            />
          </List.Item>
        )}
      />
    </Drawer>
  );
};
