import { DocumentNode, gql } from "@apollo/client";

function getPeopleQuery(columnsResponse?: string): DocumentNode {
  const whereType = "PeopleModelFilterInput";
  const orderType = "PeopleModelSortInput";
  if (!columnsResponse) {
    columnsResponse = `
    surname
    name
    email`;
  }

  return gql`
    query Peoples($skip: Int!, $take: Int!, $where: ${whereType}!, $order: [${orderType}!]) {
      peoples(skip: $skip, take: $take, where: $where, order: $order) {
        pageInfo {
          hasNextPage
          hasPreviousPage
        }
        totalCount
        items {
          id
          ${columnsResponse}
        }
      }
    }
  `;
}

function getPeoplesSelectQuery(columnsResponse?: string): DocumentNode {
  if (!columnsResponse) {
    columnsResponse = `
    name
    surname`;
  }

  return gql`
    query People($skip: Int!, $take: Int!, $role: String!) {
      peoples(
        skip: $skip
        take: $take
        where: { role: { name: { eq: $role } } }
      ) {
        pageInfo {
          hasNextPage
          hasPreviousPage
        }
        totalCount
        items {
          id
          ${columnsResponse}
        }
      }
    }
  `;
}

function getPeopleByIdQuery(columnsResponse?: string): DocumentNode {
  if (!columnsResponse) {
    columnsResponse = `
    surname
    name
    email`;
  }

  return gql`
    query Peoples($id: ID!) {
      peopleById(id: $id) {
        id
        ${columnsResponse}
      }
    }
  `;
}

function getPeoplesStatisticsQuery(): DocumentNode {
  return gql`
    query GetPeoplesStatistics {
      statistics {
        data {
          name
          value
          description
        }
      }
    }
  `;
}

function createPeople(): DocumentNode {
  return gql`
    mutation CreatePeople($request: CreatePeopleRequestInput!) {
      createPeople(request: $request) {
        data {
          id
        }
        success
        message
        errorMessage
      }
    }
  `;
}

function updatePeople(): DocumentNode {
  const CREATE = gql`
    mutation UpdatePeople($id: ID!, $request: UpdatePeopleRequestInput!) {
      updatePeople(id: $id, request: $request) {
        success
        message
        errorMessage
      }
    }
  `;

  return CREATE;
}

function deletePeople(): DocumentNode {
  const CREATE = gql`
    mutation DeletePeople($id: ID!) {
      deletePeople(id: $id) {
        success
        message
        errorMessage
      }
    }
  `;

  return CREATE;
}

function getPeopleAvatarByIdQuery(): DocumentNode {
  return gql`
    query Peoples($id: ID!) {
      avatarById(id: $id) {
        success
        errorMessage
        message
        data {
          collectionName
          fieldName
          url
        }
      }
    }
  `;
}

function getPeopleRoleQuery(columnsResponse?: string): DocumentNode {
  const whereType = "PeopleRoleModelFilterInput";
  const orderType = "PeopleRoleModelSortInput";
  if (!columnsResponse) {
    columnsResponse = `
    name
    description`;
  }

  return gql`
    query PeopleRoles($skip: Int!, $take: Int!, $where: ${whereType}!, $order: [${orderType}!]) {
      peopleRoles(skip: $skip, take: $take, where: $where, order: $order) {
        pageInfo {
          hasNextPage
          hasPreviousPage
        }
        totalCount
        items {
          id
          ${columnsResponse}
        }
      }
    }
  `;
}

function getPeopleRoleByIdQuery(columnsResponse?: string): DocumentNode {
  if (!columnsResponse) {
    columnsResponse = `
    name
    description`;
  }

  return gql`
    query PeopleRoles($id: ID!) {
      peopleRoleById(id: $id) {
        id
        ${columnsResponse}
      }
    }
  `;
}

function getPeoplesStatisticsRoleQuery(): DocumentNode {
  return gql`
    query GetPeoplesStatisticsRole {
      statisticsRole {
        data {
          name
          value
          description
        }
      }
    }
  `;
}

function createPeopleRole(): DocumentNode {
  return gql`
    mutation CreatePeople($request: CreatePeopleRoleRequestInput!) {
      createPeopleRole(request: $request) {
        data {
          id
        }
        success
        message
        errorMessage
      }
    }
  `;
}

function updatePeopleRole(): DocumentNode {
  const CREATE = gql`
    mutation UpdatePeopleRole(
      $id: ID!
      $request: UpdatePeopleRoleRequestInput!
    ) {
      updatePeopleRole(id: $id, request: $request) {
        success
        message
        errorMessage
      }
    }
  `;

  return CREATE;
}

function deletePeopleRole(): DocumentNode {
  const CREATE = gql`
    mutation DeletePeopleRole($id: ID!) {
      deletePeopleRole(id: $id) {
        success
        message
        errorMessage
      }
    }
  `;

  return CREATE;
}

function getPeopleRolesSelectQuery(columnsResponse?: string): DocumentNode {
  if (!columnsResponse) {
    columnsResponse = `
    name
    description`;
  }

  return gql`
    query PeopleRoles($skip: Int!, $take: Int!) {
      peopleRoles(skip: $skip, take: $take) {
        pageInfo {
          hasNextPage
          hasPreviousPage
        }
        totalCount
        items {
          id
          ${columnsResponse}
        }
      }
    }
  `;
}

export {
  createPeople, createPeopleRole, deletePeople, deletePeopleRole, getPeopleAvatarByIdQuery, getPeopleByIdQuery, getPeopleQuery, getPeopleRoleByIdQuery, getPeopleRoleQuery,
  getPeopleRolesSelectQuery, getPeoplesSelectQuery, getPeoplesStatisticsQuery, getPeoplesStatisticsRoleQuery, updatePeople, updatePeopleRole
};

