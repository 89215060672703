import { Button, Card, Form, Input } from "antd";
import { MdEdit } from "react-icons/md";
import style from "./UserGeneralData.module.scss";
import { UserGeneralDataFunctions } from "./UserGeneralDataFunctions";

interface UserGeneralDataProps {
  setName: React.Dispatch<React.SetStateAction<string | undefined>>;
}

export const UserGeneralData = (props: UserGeneralDataProps) => {
  const { setName } = props;
  const { form, formRef, onFinish, loading, edit, setEdit } =
    UserGeneralDataFunctions({ setName });

  return (
    <Card
      size={"small"}
      title={"Dados Gerais"}
      bordered={false}
      extra={
        <Button
          type="link"
          shape="circle"
          icon={<MdEdit />}
          onClick={() => setEdit(!edit)}
        />
      }>
      <Form
        form={form}
        ref={formRef}
        name="user-form"
        layout="vertical"
        disabled={!edit}
        onFinish={onFinish}
        autoComplete="off">
        <Form.Item
          label="Nome"
          name="displayName"
          rules={[
            { required: true, message: "O nome é obrigatório!" },
            {
              max: 50,
              message: "O nome pode ter no máximo 30 caracteres!",
            },
          ]}>
          <Input />
        </Form.Item>

        <Form.Item
          label="E-mail"
          name="email"
          rules={[
            { required: true, message: "O e-mail é obrigatório!" },
            {
              max: 50,
              message: "O e-mail pode ter no máximo 30 caracteres!",
            },
          ]}>
          <Input />
        </Form.Item>

        {edit && (
          <Form.Item className={style["button-save"]}>
            <Button type="primary" htmlType="submit" loading={loading}>
              Salvar
            </Button>
          </Form.Item>
        )}
      </Form>
    </Card>
  );
};
