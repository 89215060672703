import { AppRoute } from "../../models/appRoute.model";
import { Categories } from "./pages/category/Categories";
import { BlogRoutes } from "./routes.enum";
import { CategoryForm } from "./pages/category/category-form/CategoryForm";
import { Blogs } from "./pages/blog/Blogs";
import { BlogForm } from "./pages/blog/blog-form/Blog";
import { MdArticle, MdCategory } from "react-icons/md";

const getBlogsRoutes = (): AppRoute[] => [
  new AppRoute({
    path: BlogRoutes.Categories,
    component: <Categories />,
    icon: <MdCategory />,
    name: "Categorias",
    permission: ["admin-system", "admin"],
  }),
  new AppRoute({
    path: BlogRoutes.CategoryCreate,
    component: <CategoryForm />,
    permission: ["admin-system", "admin"],
  }),
  new AppRoute({
    path: BlogRoutes.CategoryEdit,
    component: <CategoryForm />,
    permission: ["admin-system", "admin"],
  }),

  new AppRoute({
    path: BlogRoutes.Blogs,
    component: <Blogs />,
    icon: <MdArticle />,
    name: "Blogs",
    permission: ["admin-system", "admin"],
  }),
  new AppRoute({
    path: BlogRoutes.BlogCreate,
    component: <BlogForm />,
    permission: ["admin-system", "admin"],
  }),
  new AppRoute({
    path: BlogRoutes.BlogEdit,
    component: <BlogForm />,
    permission: ["admin-system", "admin"],
  }),
];

export { getBlogsRoutes };
