import { useState } from "react";
import { useQuery } from "@apollo/client";
import { Skeleton } from "antd";
import { useMain } from "../../../../../../contexts/main";
import { clientPeople } from "../../../../../../services/graphql.service";
import { getPeopleAvatarByIdQuery } from "../../../../services/people.service";
import { UpdateAvatar } from "../../../../../../components/avatar";

interface UserAvatar {
  id: string;
}

export const PeopleAvatar: React.FC<UserAvatar> = (props) => {
  const { id } = props;
  const { errorMessage } = useMain();
  const [avatar, setAvatar] = useState<any>();
  const [src, setSrc] = useState<any>();

  const { loading } = useQuery(getPeopleAvatarByIdQuery(), {
    client: clientPeople,
    variables: { id },
    fetchPolicy: "no-cache",
    onCompleted: (result) => {
      const { data } = result.avatarById;
      setAvatar(data);
      setSrc(data?.url);
    },
    errorPolicy: "all",
    onError: ({ graphQLErrors, networkError }) => {
      if (!id) return;

      if (graphQLErrors)
        graphQLErrors.forEach(({ message, locations, path }) =>
          errorMessage(message)
        );
      if (networkError)
        errorMessage("Ocorreu um erro inesperado. Tente novamente mais tarde!");
    },
  });

  return (
    <>
      {loading && <Skeleton.Avatar active size={100} />}
      {!loading && (
        <UpdateAvatar
          bucketName="453821711304-sge-temp-files"
          collectionName={avatar?.collectionName}
          fieldName={avatar?.fieldName}
          src={src}
          setSrc={setSrc}
          id={id || ""}
          onCancel={() => {}}
        />
      )}
    </>
  );
};
