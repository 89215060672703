import { WarningFilled } from "@ant-design/icons";
import {
  Button,
  Card,
  Col,
  Form,
  List,
  Result,
  Row,
  Select,
  Tag,
  Typography
} from "antd";
import { useEffect, useState } from "react";
import { BiCheckDouble } from "react-icons/bi";
import { MdCheck, MdDelete, MdEdit } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { ColumnChart } from "../../../../components/charts/ColumnChart";
import { useWindowSize } from "../../../../contexts/useWindowSize";
import useUtils from "../../../../hooks/useUtils";
import { groupBy } from "../../../../utils/array";
import { EventTypeModel } from "../../../events/pages/event-type/EventTypes.functions";
import {
  EventModel,
  EventStatusEnum,
  getColorEventStatus,
  getDescriptionEventStatus,
  getEventName,
} from "../../../events/pages/events/Events.functions";
import { EventsRoutes } from "../../../events/routes.enum";
import { TablePendingEvents } from "./components/TablePendingEvents";
import { PendingEventsListFunctions } from "./PendingEventsList.functions";
import styles from "./PendingEventsList.module.scss";

export const PendingEventsList = () => {
  const {
    list,
    loadingGet,
    loading,
    total,
    loadMore,
    onClickValidateEvent,
    onClickConfirmedEvent,
    onClickDeleteEvent,
    dataChart,
    loadingChart,
    percent,
    loadingChartPercent,
    groups,
    loadingGroupsGet,
    group,
    setGroup,
    sectors,
    loadingSectorsGet,
    sectorsId,
    setSectorsId,
    filter,
    percentConfirm,
    loadingPercentConfirm,
    status,
    setStatus,
  } = PendingEventsListFunctions();

  const [width] = useWindowSize();
  const navigate = useNavigate();
  const { formatDateWithDayMonth } = useUtils();

  const loadMores =
    total > list.length && !loading ? (
      <div className={styles.loadMore}>
        <Button type="link" onClick={loadMore}>
          Carregar mais
        </Button>
      </div>
    ) : null;

  const [types, setTypes] = useState([]);

  useEffect(() => {
    setTypes(groupBy(list, "type", "name") || []);
  }, [list]);

  const actions = (item: EventModel) => {
    let listActions = [
      <Button
        type="default"
        onClick={() =>
          navigate(
            `${EventsRoutes.PendingEventEdit.replace(
              ":id",
              item.id
            )}?isPending=true`
          )
        }
        icon={<MdEdit />}
      >
        Editar
      </Button>,
    ];

    if (item?.status === EventStatusEnum.Pending) {
      listActions.push(
        <Button
          style={{ width: "120px" }}
          type="primary"
          ghost
          className={styles["button-success-ghost"]}
          onClick={() => onClickConfirmedEvent(item)}
          icon={<MdCheck />}
        >
          Confirmar
        </Button>
      );
    }

    if (item?.status === EventStatusEnum.Confirmed) {
      listActions.push(
        <Button
          style={{ width: "120px" }}
          type="primary"
          ghost
          className={styles["button-success-ghost"]}
          onClick={() => onClickValidateEvent(item)}
          icon={<BiCheckDouble />}
        >
          Validar
        </Button>
      );
    }

    listActions.push(
      <Button
        type="text"
        onClick={() => onClickDeleteEvent(item)}
        icon={<MdDelete color="red" />}
      ></Button>
    );
    return listActions;
  };

  const handleSetStatus = (newStatus: EventStatusEnum) => {
    if (newStatus === status) {
      setStatus(null);
      return;
    }

    setStatus(newStatus);
  };

  return (
    <Row gutter={[16, 16]} className={styles["pending-events"]}>
      <Col span={24}>
        <Card>
          <Form layout="vertical">
            <Row justify={"space-between"} align={"bottom"} gutter={[16, 16]}>
              <Col span={24} lg={22}>
                <Row gutter={[16, 16]}>
                  <Col span={24} lg={12}>
                    <Form.Item label="Reunião" name="group">
                      <Select
                        showSearch
                        allowClear
                        value={group?.id}
                        defaultValue={group?.id}
                        filterOption={(input, option) =>
                          (option?.label ?? "")
                            .toLowerCase()
                            .includes(input.toLowerCase())
                        }
                        onChange={(item, option: any) => setGroup(option?.item)}
                        options={groups}
                        loading={loadingGroupsGet}
                        style={{
                          width: "100%",
                        }}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={24} lg={12}>
                    <Form.Item label="Setor" name="sectorId">
                      <Select
                        mode="multiple"
                        showSearch
                        allowClear
                        value={sectorsId}
                        defaultValue={sectorsId}
                        filterOption={(input, option) =>
                          (option?.label ?? "")
                            .toLowerCase()
                            .includes(input.toLowerCase())
                        }
                        onChange={(item, option) => setSectorsId(item)}
                        options={sectors}
                        loading={loadingSectorsGet}
                        style={{
                          width: "100%",
                        }}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
              <Col span={24} lg={2}>
                <Button
                  type="primary"
                  onClick={filter}
                  loading={loadingGet || loadingChart || loadingChartPercent}
                >
                  Filtrar
                </Button>
              </Col>
            </Row>
          </Form>
        </Card>
      </Col>
      <Col span={24}>
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <Row gutter={[16, 16]}>
              <Col span={24}>
                <Card
                  title={
                    <div className={styles["header-title"]}>
                      Eventos pendentes por setor
                    </div>
                  }
                >
                  <Row gutter={[16, 16]}>
                    <Col span={24}>
                      {dataChart && (
                        <TablePendingEvents
                          data={dataChart}
                          loading={loadingChart}
                        />
                      )}
                    </Col>
                    <Col span={24}>
                      {dataChart && (
                        <ColumnChart data={dataChart} loading={loadingChart} />
                      )}
                    </Col>
                  </Row>
                </Card>
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
      <Col span={24}>
        <Card
          title={
            <div className={styles["header-title"]}>
              {<WarningFilled />} Estes eventos precisam da sua atenção
            </div>
          }
          extra={[
            <Row gutter={[16, 16]}>
              <Button
                style={{ width: "100px" }}
                type={status == EventStatusEnum.Pending ? "primary" : "text"}
                onClick={() => handleSetStatus(EventStatusEnum.Pending)}
              >
                {getDescriptionEventStatus(EventStatusEnum.Pending)}
              </Button>
              <Button
                style={{ width: "100px" }}
                type={status == EventStatusEnum.Confirmed ? "primary" : "text"}
                onClick={() => handleSetStatus(EventStatusEnum.Confirmed)}
              >
                {getDescriptionEventStatus(EventStatusEnum.Confirmed)}
              </Button>
              <Button
                style={{ width: "100px" }}
                type={status == EventStatusEnum.Validated ? "primary" : "text"}
                onClick={() => handleSetStatus(EventStatusEnum.Validated)}
              >
                {getDescriptionEventStatus(EventStatusEnum.Validated)}
              </Button>
            </Row>,
          ]}
        >
          <Row gutter={[16, 16]}>
            {types.length > 0 ? (
              types
                .sort(
                  (objA: EventTypeModel, objB: EventTypeModel) =>
                    Number(objA.order) - Number(objB.order)
                )
                .map((type: any, index: number) => (
                  <Col key={index} span={24}>
                    <Card
                      size={width <= 400 ? "small" : "default"}
                      title={
                        <Tag className={styles["title"]}>
                          <Typography.Text ellipsis>
                            {type?.name}
                          </Typography.Text>
                        </Tag>
                      }
                    >
                      <List
                        itemLayout={width <= 400 ? "vertical" : "horizontal"}
                        size="small"
                        dataSource={type?.events}
                        loading={loadingGet}
                        rowKey={(item) => item.id}
                        renderItem={(item: EventModel) => (
                          <List.Item actions={actions(item)}>
                            <div className={styles.item}>
                              <div className={styles.box}>
                                <Typography className={styles.label}>
                                  Status
                                </Typography>
                                <Typography className={styles.value}>
                                  <Tag
                                    color={getColorEventStatus(item?.status)}
                                  >
                                    {getDescriptionEventStatus(item?.status)}
                                  </Tag>
                                </Typography>
                              </div>
                              <div className={styles.box}>
                                <Typography className={styles.label}>
                                  Data
                                </Typography>
                                <Typography className={styles.value}>
                                  {formatDateWithDayMonth(item?.dateNotFormat)}
                                </Typography>
                              </div>
                              <div className={styles.box}>
                                <Typography className={styles.label}>
                                  Hora
                                </Typography>
                                <Typography className={styles.value}>
                                  {item?.hour}
                                </Typography>
                              </div>
                              <div className={styles.box}>
                                <Typography className={styles.label}>
                                  Localidade
                                </Typography>
                                <Typography className={styles.value}>
                                  {getEventName(item)}
                                </Typography>
                              </div>
                              <div className={styles.box}>
                                <Typography className={styles.label}>
                                  Ancião
                                </Typography>
                                <Typography className={styles.value}>
                                  {item?.people?.name ||
                                    "»»»»»»»»»»«««««««««««"}
                                </Typography>
                              </div>
                            </div>
                          </List.Item>
                        )}
                      />
                    </Card>
                  </Col>
                ))
            ) : (
              <Col span={24}>
                <Row justify={"center"}>
                  <Result title="Nenhum dado encontrado." />
                </Row>
              </Col>
            )}
          </Row>
          <Row justify={"center"}>{loadMores}</Row>
        </Card>
      </Col>
    </Row>
  );
};
