import { Button, Card, Col, Form, Row } from "antd";
import { MdEdit } from "react-icons/md";
import { PasswordField } from "../../../../../components";
import style from "./UserSecurity.module.scss";
import { UserSecurityFunctions } from "./UserSecurityFunctions";

export const UserSecurity = () => {
  const {
    form,
    validateMessages,
    onSubmit,
    loading,
    passwordVisible,
    setPasswordVisible,
    edit,
    setEdit,
  } = UserSecurityFunctions();

  return (
    <Card
      size={"small"}
      title={"Segurança"}
      className={style["card"]}
      extra={
        <Button
          type="link"
          shape="circle"
          icon={<MdEdit />}
          onClick={() => setEdit(!edit)}
        />
      }>
      <Form
        name="reset-password"
        layout="vertical"
        initialValues={{ remember: true }}
        disabled={!edit}
        autoComplete="off"
        validateMessages={validateMessages}
        form={form}
        className={style.form}
        onFinish={onSubmit}>
        <Row gutter={24} align={"bottom"}>
          <Col span={24} md={12} xl={8}>
            <PasswordField
              name="password"
              label="Nova senha"
              passwordVisible={passwordVisible}
              setPasswordVisible={setPasswordVisible}
              rules={[{ required: true }]}
            />
          </Col>
          <Col span={24} md={12} xl={8}>
            <PasswordField
              passwordVisible={passwordVisible}
              setPasswordVisible={setPasswordVisible}
              name="confirmPassword"
              dependencies={["password"]}
              label={`Confirmar nova senha`}
              rules={[
                {
                  required: true,
                  message: "Confirme a senha",
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue("password") === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      new Error("As senhas não correspondem")
                    );
                  },
                }),
              ]}
            />
          </Col>
        </Row>
        {edit && (
          <Row gutter={24} align={"bottom"} justify={"end"}>
            <Button
                style={{ marginBlock: "8px" }}
                loading={loading}
                type="primary"
                htmlType="submit">
                Salvar
              </Button>
          </Row>
        )}
      </Form>
    </Card>
  );
};
