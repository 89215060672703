import { FaMapPin } from "react-icons/fa6";
import { HiMiniRectangleGroup } from "react-icons/hi2";
import { SiGooglemeet } from "react-icons/si";
import { AppRoute } from "../../models/appRoute.model";
import { EventGroups } from "./pages/event-group/EventGroups";
import { EventGroupForm } from "./pages/event-group/event-group-form/EventGroupForm";
import { EventTypes } from "./pages/event-type/EventTypes";
import { EventTypeForm } from "./pages/event-type/event-type-form/EventTypeForm";
import { Events } from "./pages/events/Events";
import { EventForm } from "./pages/events/events-form/EventForm";
import { EventsRoutes } from "./routes.enum";

const getEventsRoutes = (): AppRoute[] => [
  new AppRoute({
    path: EventsRoutes.Events,
    component: <Events />,
    icon: <FaMapPin />,
    name: "Eventos",
    permission: ["admin-system", "admin", "user"],
  }),
  new AppRoute({
    path: EventsRoutes.EventCreate,
    component: <EventForm />,
    permission: ["admin-system", "admin"],
  }),
  new AppRoute({
    path: EventsRoutes.EventEdit,
    component: <EventForm />,
    permission: ["admin-system", "admin"],
  }),
  new AppRoute({
    path: EventsRoutes.PendingEventEdit,
    component: <EventForm />,
    permission: ["admin-system", "admin", "user"],
  }),

  new AppRoute({
    path: EventsRoutes.EventTypes,
    component: <EventTypes />,
    icon: <HiMiniRectangleGroup />,
    name: "Serviços",
    permission: ["admin-system", "admin"],
  }),
  new AppRoute({
    path: EventsRoutes.EventTypeCreate,
    component: <EventTypeForm />,
    permission: ["admin-system", "admin"],
  }),
  new AppRoute({
    path: EventsRoutes.EventTypeEdit,
    component: <EventTypeForm />,
    permission: ["admin-system", "admin"],
  }),

  new AppRoute({
    path: EventsRoutes.EventGroups,
    component: <EventGroups />,
    icon: <SiGooglemeet />,
    name: "Reuniões",
    permission: ["admin-system", "admin"],
  }),
  new AppRoute({
    path: EventsRoutes.EventGroupCreate,
    component: <EventGroupForm />,
    permission: ["admin-system", "admin"],
  }),
  new AppRoute({
    path: EventsRoutes.EventGroupEdit,
    component: <EventGroupForm />,
    permission: ["admin-system", "admin"],
  }),
];

export { getEventsRoutes };

