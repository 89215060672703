import { AuthModel } from "../../../models/AuthModel";
import { gql, DocumentNode } from "@apollo/client";

function getCategoryQuery(columnsResponse?: string): DocumentNode {
  const whereType = "CategoryModelFilterInput";
  const orderType = "CategoryModelSortInput";
  if (!columnsResponse) {
    columnsResponse = `
    code
    icon
    title
    description`;
  }

  return gql`
    query Categories($skip: Int!, $take: Int!, $where: ${whereType}!, $order: [${orderType}!]) {
      category(skip: $skip, take: $take, where: $where, order: $order) {
        pageInfo {
          hasNextPage
          hasPreviousPage
        }
        totalCount
        items {
          id
          ${columnsResponse}
        }
      }
    }
  `;
}

function getCategoriesSimpleSelectQuery(
  columnsResponse?: string
): DocumentNode {
  if (!columnsResponse) {
    columnsResponse = `
    code
    title`;
  }

  return gql`
    query Categories($skip: Int!, $take: Int!) {
      category(skip: $skip, take: $take) {
        pageInfo {
          hasNextPage
          hasPreviousPage
        }
        totalCount
        items {
          id
          ${columnsResponse}
        }
      }
    }
  `;
}

function getCategoryByIdQuery(columnsResponse?: string): DocumentNode {
  if (!columnsResponse) {
    columnsResponse = `
    code
    icon {
      path
    }
    title
    description`;
  }

  return gql`
    query Categories($id: ID!) {
      categoryById(id: $id) {
        id
        ${columnsResponse}
      }
    }
  `;
}

function createCategory(): DocumentNode {
  return gql`
    mutation CreateCategory($request: CreateCategoryRequestInput!) {
      createCategory(request: $request) {
        data {
          id
        }
        success
        message
        errorMessage
      }
    }
  `;
}

function updateCategory(): DocumentNode {
  const CREATE = gql`
    mutation UpdateCategory($id: ID!, $request: UpdateCategoryRequestInput!) {
      updateCategory(id: $id, request: $request) {
        success
        message
        errorMessage
      }
    }
  `;

  return CREATE;
}

function deleteCategory(): DocumentNode {
  const CREATE = gql`
    mutation DeleteCategory($id: ID!) {
      deleteCategory(id: $id) {
        success
        message
        errorMessage
      }
    }
  `;

  return CREATE;
}

function publishCategory(): DocumentNode {
  const CREATE = gql`
    mutation PublishedCategory($id: ID!) {
      publishedCategory(id: $id) {
        success
        message
        errorMessage
      }
    }
  `;

  return CREATE;
}

function getCategoryAvatarByIdQuery(): DocumentNode {
  return gql`
    query CategoryAvatar($id: ID!) {
      avatarById(id: $id) {
        success
        errorMessage
        message
        data {
          collectionName
          fieldName
          url
        }
      }
    }
  `;
}

function getBlogQuery(columnsResponse?: string): DocumentNode {
  const whereType = "BlogModelFilterInput";
  const orderType = "BlogModelSortInput";
  if (!columnsResponse) {
    columnsResponse = `
    code
    breadCrumb
    title
    category {
      code
      title
    }`;
  }

  return gql`
    query Blogs($skip: Int!, $take: Int!, $where: ${whereType}!, $order: [${orderType}!]) {
      blog(skip: $skip, take: $take, where: $where, order: $order) {
        pageInfo {
          hasNextPage
          hasPreviousPage
        }
        totalCount
        items {
          id
          ${columnsResponse}
        }
      }
    }
  `;
}

function getBlogsSimpleSelectQuery(columnsResponse?: string): DocumentNode {
  if (!columnsResponse) {
    columnsResponse = `
    code
    breadCrumb
    title
    content
    tags
    category {
      code
      title
    }`;
  }

  return gql`
    query Blogs($skip: Int!, $take: Int!) {
      blog(skip: $skip, take: $take) {
        pageInfo {
          hasNextPage
          hasPreviousPage
        }
        totalCount
        items {
          id
          ${columnsResponse}
        }
      }
    }
  `;
}

function getTagsSelectQuery(): DocumentNode {
  return gql`
    query Tags {
      allTagsAsync {
        data
      }
    }
  `;
}

function getBlogByIdQuery(columnsResponse?: string): DocumentNode {
  if (!columnsResponse) {
    columnsResponse = `
    code
    breadCrumb
    title
    content
    tags
    category {
      id
      code
      title
    }`;
  }

  return gql`
    query Blogs($id: ID!) {
      blogById(id: $id) {
        id
        ${columnsResponse}
      }
    }
  `;
}

function createBlog(): DocumentNode {
  return gql`
    mutation CreateBlog($request: CreateBlogRequestInput!) {
      createBlog(request: $request) {
        data {
          id
        }
        success
        message
        errorMessage
      }
    }
  `;
}

function updateBlog(): DocumentNode {
  const CREATE = gql`
    mutation UpdateBlog($id: ID!, $request: UpdateBlogRequestInput!) {
      updateBlog(id: $id, request: $request) {
        success
        message
        errorMessage
      }
    }
  `;

  return CREATE;
}

function deleteBlog(): DocumentNode {
  const CREATE = gql`
    mutation DeleteBlog($id: ID!) {
      deleteBlog(id: $id) {
        success
        message
        errorMessage
      }
    }
  `;

  return CREATE;
}

function publishBlog(): DocumentNode {
  const CREATE = gql`
    mutation PublishedBlog($id: ID!) {
      publishedBlog(id: $id) {
        success
        message
        errorMessage
      }
    }
  `;

  return CREATE;
}

export {
  getCategoryQuery,
  getCategoriesSimpleSelectQuery,
  getCategoryByIdQuery,
  getCategoryAvatarByIdQuery,
  createCategory,
  updateCategory,
  deleteCategory,
  publishCategory,
  getBlogQuery,
  getBlogsSimpleSelectQuery,
  getTagsSelectQuery,
  getBlogByIdQuery,
  createBlog,
  updateBlog,
  deleteBlog,
  publishBlog,
};
