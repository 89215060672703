import { PeopleRoleModel, PeopleRolesFunctions } from "./PeopleRoles.functions";
import { ProTable } from "../../../../components/table/pro-table/ProTable";

export const PeopleRoles = () => {
  const {
    columns,
    columnsMap,
    clientPeople,
    whereDefault,
    variables,
    setVariables,
    toolBarRender,
  } = PeopleRolesFunctions();

  return (
    <>
      <ProTable<PeopleRoleModel>
        headerTitle="Funções"
        client={clientPeople}
        queryName="peopleRoles"
        whereType="PeopleRoleModelFilterInput"
        whereDefault={whereDefault}
        orderType="PeopleRoleModelSortInput"
        variables={variables}
        setVariables={setVariables}
        columns={columns()}
        defaultColumnsMap={columnsMap}
        toolBarRender={toolBarRender}
      />
    </>
  );
};
