import React, { useEffect, useState } from "react";
import { useMain } from "../../../../../contexts/main";
import { useParams } from "react-router-dom";
import { PeopleRoutes } from "../../../routes.enum";

export const PeopleCreateAccountFunctions = () => {
  const [surname, setSurname] = useState<string>();
  const { handleChangeBreadcumb, setTitlePage } = useMain();
  const { id } = useParams();

  useEffect(() => {
    const title = !id ? "Nova pessoa" : "Alterar a pessoa";
    handleChangeBreadcumb([
      {
        title: "Home",
        path: "/",
      },
      {
        title: "Pessoas",
        path: PeopleRoutes.Peoples,
      },
      {
        title: title,
      },
    ]);

    setTitlePage(title);
  }, []);

  return { surname, setSurname, id };
};
