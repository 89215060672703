import { Column, ColumnConfig } from "@ant-design/plots";
import React, { FC } from "react";
import ReactDOM from "react-dom";
import { useThemeDetector } from "../../../theme";

interface ColumnChartProps {
  loading: boolean;
  data: {
    name: string;
    group: string;
    value: number;
  }[];
}

const ColumnChart: FC<ColumnChartProps> = (props) => {
  const isDarkTheme = useThemeDetector();
  const { loading, data } = props;

  const config = {
    theme: isDarkTheme ? "classicDark" : "classic",
    data: data,
    autoFit: true,
    xField: "group",
    yField: "value",
    colorField: "name",
    group: true,
    style: {
      inset: 5,
    },
    loading,
  } as ColumnConfig;
  return <Column {...config} />;
};

export { ColumnChart };
