import { useState, useEffect } from "react";
import type { ColumnsState, ProColumns } from "@ant-design/pro-components";
import { Avatar, Button, Modal, Space, Tag, Tooltip } from "antd";
import { FaLink, FaPencil, FaTrash } from "react-icons/fa6";
import { MdDelete, MdEdit, MdLink, MdPublish } from "react-icons/md";
import { useNavigate, useNavigation } from "react-router-dom";
import { ExclamationCircleFilled, PlusOutlined } from "@ant-design/icons";
import { useMutation } from "@apollo/client";
import { clientBlog } from "../../../../services/graphql.service";
import { useMain } from "../../../../contexts/main";
import { deleteCategory, publishCategory } from "../../services/blog.service";
import { BlogRoutes } from "../../routes.enum";
import { useAuth } from "../../../../contexts/auth";
import { ProColumnsProps } from "../../../../components/table/pro-table/ProTable";

export type CategoryModel = {
  id: string;
  icon: {
    path: string;
  };
  code: string;
  title: string;
  status: CategoryStatusEnum;
};

export interface Response<T> {
  nodes: T[];
  pageInfo: {
    hasNextPage: boolean;
    hasPreviousPage: boolean;
  };
  totalCount: number;
}

enum Sort {
  ASC = "ASC",
  DESC = "DESC",
}

export enum CategoryStatusEnum {
  InDraft = "IN_DRAFT",
  Published = "PUBLISHED",
}

export const getDescriptionStatus = (status: CategoryStatusEnum) => {
  if (status == CategoryStatusEnum.InDraft) return "Em rascunho";
  if (status == CategoryStatusEnum.Published) return "Publicada";
  return status;
};

export const getColorStatus = (status: CategoryStatusEnum) => {
  if (status == CategoryStatusEnum.InDraft) return "warning";
  if (status == CategoryStatusEnum.Published) return "success";

  return "warning";
};

const { confirm } = Modal;

export const CategoriesFunctions = () => {
  const navigate = useNavigate();
  const { errorMessage, handleChangeBreadcumb, setTitlePage } = useMain();
  const { hasPermission } = useAuth();
  const title = "Categorias";

  useEffect(() => {
    handleChangeBreadcumb([
      {
        title: "Home",
        path: "/",
      },
      {
        title: title,
      },
    ]);
    setTitlePage(title);
  }, []);

  const onClickCreate = () => {
    navigate(BlogRoutes.CategoryCreate);
  };

  const onClickEdit = (id: string) => {
    const url = BlogRoutes.CategoryEdit.replace(":id", id);
    navigate(url);
  };

  const [publishMutation] = useMutation(publishCategory(), {
    client: clientBlog,
    errorPolicy: "all",
    onCompleted: () => {
      setVariables({ ...variables });
    },
    onError: ({ graphQLErrors, networkError }) => {
      if (graphQLErrors)
        graphQLErrors.forEach(({ message, locations, path }) =>
          errorMessage(message)
        );
      if (networkError)
        errorMessage("Ocorreu um erro inesperado. Tente novamente mais tarde!");
    },
  });

  const onClickPublish = (id: string) => {
    return publishMutation({
      variables: {
        id,
      },
    });
  };

  const [deleteMutation] = useMutation(deleteCategory(), {
    client: clientBlog,
    errorPolicy: "all",
    onCompleted: () => {
      setVariables({ ...variables });
    },
    onError: ({ graphQLErrors, networkError }) => {
      if (graphQLErrors)
        graphQLErrors.forEach(({ message, locations, path }) =>
          errorMessage(message)
        );
      if (networkError)
        errorMessage("Ocorreu um erro inesperado. Tente novamente mais tarde!");
    },
  });

  const onClickDelete = (id: string) => {
    return deleteMutation({
      variables: {
        id,
      },
    });
  };

  const toolBarRender = () => [
    hasPermission(["admin", "admin-system"]) && (
      <Button
        key="button"
        icon={<PlusOutlined />}
        onClick={onClickCreate}
        type="primary">
        Novo
      </Button>
    ),
  ];

  const columns = (): ProColumnsProps<CategoryModel>[] => [
    {
      title: "Ícone",
      dataIndex: ["icon", "path"],
      width: 60,
      renderFormItem: (_, { defaultRender }) => {
        return defaultRender(_);
      },
      render: (_, record) => (
        <Avatar src={`https://s3.ccbbh.com.br/${record?.icon?.path}`} />
      ),
    },
    {
      title: "Url",
      dataIndex: "code",
      filters: true,
      onFilter: true,
      ellipsis: true,
      sorter: true,
    },
    {
      title: "Título",
      dataIndex: "title",
      filters: true,
      onFilter: true,
      ellipsis: true,
      sorter: true,
    },
    {
      title: "Status",
      dataIndex: "status",
      filters: true,
      onFilter: true,
      ellipsis: true,
      sorter: true,
      renderFormItem: (_, { defaultRender }) => {
        return defaultRender(_);
      },
      render: (_, record) => (
        <Tag color={getColorStatus(record.status)}>
          {getDescriptionStatus(record.status)}
        </Tag>
      ),
    },
    {
      title: "Ações",
      key: "actions",
      search: false,
      width: 180,
      align: "center",
      permission: ["admin", "admin-system"],
      render: (_, record) => [
        <Space>
          {record.status === CategoryStatusEnum.InDraft && (
            <Tooltip title="Publicar categoria">
              <Button
                type="link"
                shape="circle"
                icon={<MdPublish />}
                onClick={() => onClickPublish(record.id)}
              />
            </Tooltip>
          )}
          <Tooltip title="Editar categoria">
            <Button
              type="link"
              shape="circle"
              icon={<MdEdit />}
              onClick={() => onClickEdit(record.id)}
            />
          </Tooltip>
          <Tooltip title="Deletar categoria">
            <Button
              type="link"
              shape="circle"
              icon={<MdDelete />}
              onClick={() => showDeleteConfirm(record.id, record.title)}
            />
          </Tooltip>
        </Space>,
      ],
    },
  ];

  const whereDefault = {
    title: {
      contains: "",
    },
  };

  const [variables, setVariables] = useState({
    skip: 0,
    take: 10,
    where: whereDefault,
    order: { title: Sort.ASC },
  });

  const columnsMap = {
    icon: {
      show: true,
    },
    code: {
      show: true,
    },
    title: {
      disable: true,
      show: true,
    },
    status: {
      show: true,
    },
  } as Record<string, ColumnsState>;

  const showDeleteConfirm = (id: string, name: string) => {
    confirm({
      title: `Deseja deletar a categoria: ${name}?`,
      icon: <ExclamationCircleFilled />,
      content: "Você realmente deseja deletar este item?",
      okText: "Sim",
      okButtonProps: {
        style: { background: "var(--primary-color)" },
      },
      cancelText: "Não",
      onOk() {
        return onClickDelete(id);
      },
      onCancel() {},
    });
  };

  return {
    columns,
    columnsMap,
    clientBlog,
    whereDefault,
    variables,
    setVariables,
    toolBarRender,
  };
};
