import { Card, Col, Row } from "antd";
import { UserGeneralData } from "./user-general-data/UserGeneralData";
import { UserCreateAccountFunctions } from "./UserForm.functions";

export const UserForm = () => {
  const { surname, setSurname, id } = UserCreateAccountFunctions();

  return (
    <Row>
      <Col span={24}>
        <Row justify={"center"}>
          <Col span={24}>
            <Row>
              <Col span={24}>
                <Card bordered={false}>
                  <UserGeneralData setSurname={setSurname} />
                </Card>
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};
