import React, { useEffect, useState } from "react";
import { useMain } from "../../../../../contexts/main";
import { useNavigate, useParams } from "react-router-dom";
import { clientEvent } from "../../../../../services/graphql.service";
import { useMutation, useQuery } from "@apollo/client";
import { Form, FormInstance } from "antd";
import { EventsRoutes } from "../../../routes.enum";
import {
  createEventType,
  getEventTypeByIdQuery,
  updateEventType,
} from "../../../services/events.service";

export const EventTypeCreateFunctions = () => {
  const { errorMessage, loading, setLoading } = useMain();
  const { handleChangeBreadcumb, setTitlePage } = useMain();
  const { id } = useParams();
  const navigate = useNavigate();
  const title = !id ? "Nova serviço" : "Alterar o serviço";

  useEffect(() => {
    setLoading(false);
    handleChangeBreadcumb([
      {
        title: "Home",
        path: "/",
      },
      {
        title: "Serviços",
        path: EventsRoutes.EventTypes,
      },
      {
        title: title,
      },
    ]);

    setTitlePage(title);
  }, [id]);

  const [form] = Form.useForm<{
    name: string;
    description: string;
  }>();
  const formRef = React.useRef<FormInstance>(null);

  const { loading: loadingGet } = useQuery(getEventTypeByIdQuery(), {
    client: clientEvent,
    variables: { id },
    fetchPolicy: "no-cache",
    onCompleted: (data) => {
      const result = data.eventTypeById;
      formRef.current?.setFieldsValue(result);
    },
    errorPolicy: "all",
    onError: ({ graphQLErrors, networkError }) => {
      if (!id) return;

      if (graphQLErrors)
        graphQLErrors.forEach(({ message }) => errorMessage(message));
      if (networkError)
        errorMessage("Ocorreu um erro inesperado. Tente novamente mais tarde!");
    },
  });

  const [sendUpdateUserMutation] = useMutation(updateEventType(), {
    client: clientEvent,
  });
  const [sendCreateUserMutation] = useMutation(createEventType(), {
    client: clientEvent,
  });

  const onFinish = (values: any) => {
    setLoading(true);
    const { order, name } = values;

    if (id) {
      sendUpdateUserMutation({
        variables: {
          id,
          request: {
            order,
            name,
          },
        },
      }).then((result) => {
        if (result.errors) {
          console.log({ errors: result.errors });
          errorMessage("Houve um erro ao realizar a alterar o serviço.");
          setLoading(false);
          return;
        }

        const { success } = result.data.updateEventType;
        if (!success) {
          errorMessage("Houve um erro ao realizar a alterar o serviço.");
          setLoading(false);
          return;
        }

        setLoading(false);
        navigate(EventsRoutes.EventTypes);
      });
    } else {
      sendCreateUserMutation({
        variables: {
          request: {
            order,
            name,
          },
        },
      }).then((result) => {
        if (result.errors) {
          console.log({ errors: result.errors });
          errorMessage("Houve um erro ao realizar a alterar o serviço.");
          setLoading(false);
          return;
        }

        const { success, message } = result.data.createEventType;
        if (!success) {
          errorMessage(
            message ?? "Houve um erro ao realizar a alterar o serviço."
          );
          setLoading(false);
          return;
        }

        setLoading(false);
        navigate(EventsRoutes.EventTypes);
      });
    }
  };

  return { id, title, form, formRef, onFinish, loadingGet, loading };
};
