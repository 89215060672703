import { useMutation, useQuery } from "@apollo/client";
import { Form, FormInstance } from "antd";
import { OptionProps } from "antd/es/select";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useMain } from "../../../../../contexts/main";
import { SimpleModel } from "../../../../../models/simplemodel.interface";
import {
    clientAuth,
    clientLocal,
} from "../../../../../services/graphql.service";
import { AdministrationModel } from "../../../../locations/pages/administration/Administrations.functions";
import { RegionalModel } from "../../../../locations/pages/regional/Regionals.functions";
import { SectorModel } from "../../../../locations/pages/sector/Sectors.functions";
import {
    getAdministrationsSelectWithWhereQuery,
    getRegionalSelectQuery,
    getSectorsSelectWithWhereQuery,
} from "../../../../locations/services/locations.service";
import {
    getUserByIdQuery,
    updateUserRegional,
} from "../../../services/account.service";

interface UserAlocationsFunctionsProps {
  selectRegionals: [] | undefined;
  selectAdministrations: [] | undefined;
  selectSectors: [] | undefined;
  setSelectedRegionals: React.Dispatch<React.SetStateAction<[] | undefined>>;
  setSelectedAdministrations: React.Dispatch<
    React.SetStateAction<[] | undefined>
  >;
  setSelectedSectors: React.Dispatch<React.SetStateAction<[] | undefined>>;
}

export const UserAlocationsFunctions = (
  props: UserAlocationsFunctionsProps
) => {
  const {
    selectRegionals,
    selectAdministrations,
    selectSectors,
    setSelectedRegionals,
    setSelectedAdministrations,
    setSelectedSectors,
  } = props;
  const { errorMessage, setLoading } = useMain();
  const { id } = useParams();
  const [edit, setEdit] = useState<boolean>(!id);

  const [form] = Form.useForm<{
    surname: string;
    name: string;
    email: string;
  }>();
  const formRef = React.useRef<FormInstance>(null);

  const { refetch, loading: loadingGet } = useQuery(getUserByIdQuery(), {
    client: clientAuth,
    variables: { id },
    onCompleted: (data) => {
      const { data: user} = data.userById;
      console.log({user})
      setSelectedRegionals(user.regionals?.map((_: SimpleModel) => _.id));
      setSelectedAdministrations(
        user.administrations
          ?.map((_: SimpleModel) => _.id)
      );
      setSelectedSectors(
        user.sectors
          ?.map((_: SimpleModel) => _.id)
      );

      formRef.current?.setFieldsValue(user);
    },
    errorPolicy: "all",
    onError: ({ graphQLErrors, networkError }) => {
      if (!id) return;

      if (graphQLErrors)
        graphQLErrors.forEach(({ message, locations, path }) =>
          errorMessage(message)
        );
      if (networkError)
        errorMessage("Ocorreu um erro inesperado. Tente novamente mais tarde!");
    },
  });
  
  const [regionals, setRegionals] = useState<OptionProps[]>([]);
  const { loading: loadingRegionalsGet } = useQuery(getRegionalSelectQuery(), {
    client: clientLocal,
    fetchPolicy: "no-cache",
    variables: { skip: 0, take: 50 },
    onCompleted: (data) => {
      const { items } = data.regional;
      if (items) {
        const list = items?.map((_: RegionalModel) => ({
          label: _.name,
          value: _.id,
        }));
        setRegionals(list);
      }
    },
    errorPolicy: "all",
    onError: ({ graphQLErrors, networkError }) => {
      if (!id) return;

      if (graphQLErrors)
        graphQLErrors.forEach(({ message, locations, path }) =>
          errorMessage(message)
        );
      if (networkError)
        errorMessage("Ocorreu um erro inesperado. Tente novamente mais tarde!");
    },
  });

  const [administrations, setAdministrations] = useState<OptionProps[]>([]);
  const {
    refetch: refetchAdministrations,
    loading: loadingAdministrationsGet,
  } = useQuery(getAdministrationsSelectWithWhereQuery(), {
    client: clientLocal,
    fetchPolicy: "no-cache",
    variables: {
      skip: 0,
      take: 50,
      where: {
        regional: {
          id: {
            in: [],
          },
        },
      },
    },
    onCompleted: (data) => {
      const { items } = data.administrations;
      if (items) {
        const list = items?.map((_: AdministrationModel) => ({
          label: `${_.regional?.name} - ${_.name}`,
          value: _.id,
        }));
        setAdministrations([
          {
            label: "Todos",
            value: "todos-adm",
          },
          ...list,
        ]);
      }
    },
    errorPolicy: "all",
    onError: ({ graphQLErrors, networkError }) => {
      if (!id) return;

      if (graphQLErrors)
        graphQLErrors.forEach(({ message, locations, path }) =>
          errorMessage(message)
        );
      if (networkError)
        errorMessage("Ocorreu um erro inesperado. Tente novamente mais tarde!");
    },
  });

  useEffect(() => {
    if (selectRegionals && selectRegionals.length > 0) {
      refetchAdministrations({
        skip: 0,
        take: 50,
        where: {
          regional: {
            id: {
              in: selectRegionals,
            },
          },
        },
      }).then((result: any) => {
        const { items } = result.data.administrations;
        if (items) {
          const list = items?.map((_: AdministrationModel) => ({
            label: `${_.regional?.name} - ${_.name}`,
            value: _.id,
          }));
          setAdministrations([
            {
              label: "Todos",
              value: "todos-adm",
            },
            ...list,
          ]);
        }
      });
    }
  }, [selectRegionals]);

  const [sectors, setSectors] = useState<OptionProps[]>([]);
  const { refetch: refetchSectors, loading: loadingSectorsGet } = useQuery(
    getSectorsSelectWithWhereQuery(),
    {
      client: clientLocal,
      fetchPolicy: "no-cache",
      variables: {
        skip: 0,
        take: 50,
        where: {
          administration: {
            id: {
              in: [],
            },
          },
        },
      },
      onCompleted: (data) => {
        const { items } = data.sectors;
        if (items) {
          const list = items?.map((_: SectorModel) => ({
            label: `${_.administration?.parent?.name} - ${_.administration?.name} - ${_.name}`,
            value: _.id,
          }));
          setSectors([
            {
              label: "Todos",
              value: "todos-sec",
            },
            ...list,
          ]);
        }
      },
      errorPolicy: "all",
      onError: ({ graphQLErrors, networkError }) => {
        if (!id) return;

        if (graphQLErrors)
          graphQLErrors.forEach(({ message, locations, path }) =>
            errorMessage(message)
          );
        if (networkError)
          errorMessage(
            "Ocorreu um erro inesperado. Tente novamente mais tarde!"
          );
      },
    }
  );

  useEffect(() => {
    if (selectAdministrations && selectAdministrations.length > 0) {
      refetchSectors({
        skip: 0,
        take: 50,
        where: {
          administration: {
            id: {
              in: selectAdministrations,
            },
          },
        },
      }).then((result: any) => {
        const { items } = result.data.sectors;
        if (items) {
          const list = items?.map((_: SectorModel) => ({
            label: `${_.administration?.parent?.name} - ${_.administration?.name} - ${_.name}`,
            value: _.id,
          }));
          setSectors([
            {
              label: "Todos",
              value: "todos-sec",
            },
            ...list,
          ]);
        }
      });
    }
  }, [selectAdministrations]);

  const [sendUpdateUserRegionalMutation, { loading }] = useMutation(
    updateUserRegional(),
    {
      client: clientAuth,
    }
  );

  const updateRegionals = () => {
    sendUpdateUserRegionalMutation({
      variables: {
        id,
        request: {
          regionalIds: selectRegionals,
          sectorIds: selectSectors,
          administrationIds: selectAdministrations,
        },
      },
    }).then((result) => {
      if (result.errors) {
        errorMessage(
          "Houve um erro ao alocar o usuário as regionais selecionadas."
        );
        setLoading(false);
        return;
      }

      const { success } = result.data.updateUserRegional;
      if (!success) {
        errorMessage(
          "Houve um erro ao alocar o usuário as regionais selecionadas."
        );
        setLoading(false);
        return;
      }
      setLoading(false);
      setEdit(false);
    });
  };

  const onFinish = () => {
    setLoading(true);
    updateRegionals();
  };

  return {
    onFinish,
    loadingGet,
    edit,
    setEdit,
    form,
    loading,
    formRef,
    regionals,
    loadingRegionalsGet,
    administrations,
    loadingAdministrationsGet,
    sectors,
    loadingSectorsGet,
  };
};
