import React, { FunctionComponent, useEffect } from "react";
import { LocalStorageService } from "../../../../services/localStorage.service";
import { EnvironmentService } from "../../../../services/envoriment.service";

const Logout: FunctionComponent = () => {
  useEffect(() => {
    LocalStorageService.removeAllLocalStorage();
    setTimeout(() => {
      const origin = window.location.origin;
      let redirect = `?redirect=${origin}`;

      const environment = EnvironmentService.isDev
        ? "dev"
        : EnvironmentService.isStg
          ? "stg"
          : "";

      const environmentUrl: string = redirect
        .split("//")[1]
        .split(".")[0]
        .split("-")[1];
      if (origin.includes('localhost')) {
        window.location.href = `${origin}/login${redirect}`;
      } else {
        window.location.href = !environment ? `https://sge.ccbbh.com.br/login${redirect}` :
        `https://sge-${environmentUrl === "canary" ? "canary" : environment}.ccbbh.com.br/login${redirect}`;
      }

    }, 1000);
  }, []);

  return <div>Deslogando o seu usuário...</div>;
};

export { Logout };
