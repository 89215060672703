import { useMutation } from "@apollo/client";
import { Form, FormInstance } from "antd";
import React, { useEffect, useState } from "react";
import { useAuth } from "../../../../../contexts/auth";
import { useMain } from "../../../../../contexts/main";
import { clientAuth } from "../../../../../services/graphql.service";
import { updateUser } from "../../../services/user.service";

interface UserGeneralDataFunctionsProps {
  setName: React.Dispatch<React.SetStateAction<string | undefined>>;
}

export const UserGeneralDataFunctions = (
  props: UserGeneralDataFunctionsProps
) => {
  const { errorMessage, loading, setLoading } = useMain();
  const { auth, handleRenewTokens } = useAuth();
  const id = auth.currentUser?.uid;
  const { setName } = props;
  const [edit, setEdit] = useState<boolean>();

  const [form] = Form.useForm<{
    displayName: string;
    email: string;
  }>();
  const formRef = React.useRef<FormInstance>(null);
  const nameValue = Form.useWatch("displayName", form);

  useEffect(() => {
    setName(nameValue);
  }, [nameValue]);

  useEffect(() => {
    form.setFieldsValue({
      displayName: auth.currentUser?.displayName ?? "",
      email: auth.currentUser?.email ?? "",
    });
  }, [auth.currentUser]);

  const [sendUpdateUserMutation] = useMutation(updateUser(), {
    client: clientAuth,
  });

  const onFinish = (values: any) => {
    setLoading(true);
    const { displayName, email } = values;

    if (id) {
      sendUpdateUserMutation({
        variables: {
          id,
          request: {
            name: displayName,
            email,
          },
        },
      })
        .then((result) => {
          if (result.errors) {
            errorMessage("Houve um erro ao realizar a alterar o usuário.");
            return;
          }

          const { success, message } = result.data.updateUser;
          if (!success) {
            errorMessage(
              message ?? "Houve um erro ao realizar a alterar o usuário."
            );
            return;
          }

          auth.currentUser && handleRenewTokens(auth.currentUser);
        })
        .catch((error) => {
          errorMessage("Houve um erro ao realizar a alterar o usuário.");
          console.log({ error });
        })
        .finally(() => {
          setLoading(false);
          setEdit(false);
        });
    } else {
      errorMessage("Houve um erro ao alterar o seu usuário.");
    }
  };

  return {
    form,
    formRef,
    onFinish,
    loading,
    edit,
    setEdit,
  };
};
