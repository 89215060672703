import { RegionalModel, RegionalsFunctions } from "./Regionals.functions";
import { ProTable } from "../../../../components/table/pro-table/ProTable";

export const Regionals = () => {
  const {
    columns,
    columnsMap,
    clientLocal,
    whereDefault,
    variables,
    setVariables,
    toolBarRender,
  } = RegionalsFunctions();

  return (
    <>
      <ProTable<RegionalModel>
        client={clientLocal}
        queryName="regional"
        whereType="RegionalModelFilterInput"
        whereDefault={whereDefault}
        orderType="RegionalModelSortInput"
        variables={variables}
        setVariables={setVariables}
        columns={columns()}
        defaultColumnsMap={columnsMap}
        toolBarRender={toolBarRender}
      />
    </>
  );
};
