import { ProLayout } from "@ant-design/pro-components";
import { Button, Col, Result, Row } from "antd";
import React from "react";
import { AuthRoutes } from "../../routes.enum";

const NotAuthorized: React.FC = () => (
  <ProLayout
    layout={"top"}
    logo={"/images/logo.svg"}
    title="Sistema de Gestão de Eventos"
  >
    <Col span={24}>
      <Row
        justify={"center"}
        align="middle"
        style={{ height: "calc(100vh - 120px)" }}
      >
        <Result
          status="403"
          title="403"
          subTitle="Desculpe, você não está autorizado a acessar esta página."
          extra={
            <Button
              type="primary"
              onClick={() => {
                window.location.href = AuthRoutes.LOGIN;
              }}
            >
              Entrar com outra conta
            </Button>
          }
        />
      </Row>
    </Col>
  </ProLayout>
);

export { NotAuthorized };

