import { Card, Col, Descriptions, Row, Skeleton, Space, Tag } from "antd";
import Title from "antd/es/typography/Title";
import { UserCreateAccountFunctions } from "./MyUser.functions";
import { UserAvatar } from "./user-avatar/UserAvatar";
import { UserGeneralData } from "./user-general-data/UserGeneralData";
import { UserSecurity } from "./user-security/UserSecurity";

export const MyUser = () => {
  const { name, setName, regionals, administrations, sectors } =
    UserCreateAccountFunctions();

  return (
    <Row gutter={[24, 24]}>
      <Col span={24} lg={8}>
        <Row
          justify={"center"}
          style={{ position: "sticky", top: 0, gap: "24px" }}>
          <Row justify={"center"}>
            <Space direction="vertical">
              <Row justify={"center"}>
                {!name && <Skeleton.Avatar active size={150} />}
                {name && <UserAvatar />}
              </Row>
              <Row justify={"center"}>
                {!name && <Skeleton.Button />}
                {name && (
                  <Title level={3} style={{ textAlign: "center", margin: 0 }}>
                    {name}
                  </Title>
                )}
              </Row>
            </Space>
          </Row>
          <Row justify={"center"}>
            <Col span={24} lg={18}>
              <Card bordered={false} size={"small"}>
                <Descriptions layout="vertical">
                  <Descriptions.Item
                    span={24}
                    label="Regionais"
                    children={
                      <Row wrap gutter={[0, 10]}>
                        {regionals &&
                          regionals.map((regional) => (
                            <Tag
                              color={
                                regional.isPrincipal ? "blue-inverse" : "blue"
                              }>
                              {regional.name}
                            </Tag>
                          ))}
                      </Row>
                    }
                  />
                  <Descriptions.Item
                    span={24}
                    label="Administrações"
                    children={
                      <Row wrap gutter={[0, 10]}>
                        {administrations &&
                          administrations.map((administration) => (
                            <Tag
                              color={
                                administration.isPrincipal
                                  ? "geekblue-inverse"
                                  : "geekblue"
                              }>
                              {administration.name}
                            </Tag>
                          ))}
                      </Row>
                    }
                  />
                  <Descriptions.Item
                    span={24}
                    label="Setores"
                    children={
                      <Row wrap gutter={[0, 10]}>
                        {sectors &&
                          sectors.map((sector) => (
                            <Tag
                              color={
                                sector.isPrincipal ? "cyan-inverse" : "cyan"
                              }>
                              {sector.name}
                            </Tag>
                          ))}
                      </Row>
                    }
                  />
                </Descriptions>
              </Card>
            </Col>
          </Row>
        </Row>
      </Col>
      <Col span={24} lg={16}>
        <Card bordered={false} size={"small"}>
          <UserGeneralData setName={setName} />
          <UserSecurity />
        </Card>
      </Col>
    </Row>
  );
};
