import { Col, Row, Table } from "antd";
import { useEffect } from "react";
import { useMain } from "../../../../contexts/main";
import { UserModel, UsersAccountFunctions } from "./Users.functions";

export const UsersAccount = () => {
  const {
    columns,
    data,
    loading,
    toolBarRender
  } = UsersAccountFunctions();

  const { handleChangeBreadcumb } = useMain();

  useEffect(() => {
    handleChangeBreadcumb([
      {
        title: "Home",
        path: "/",
      },
      {
        title: "Usuários",
      },
    ]);
  }, []);

  return (
    <Row gutter={[16,16]}>
      <Col span={24}><Row justify={"end"}>{toolBarRender()}</Row></Col>
      <Col span={24}>
    <Table<UserModel>
      columns={columns()}
      showHeader
      rowKey={(record) => record.uid}
      dataSource={data}
      loading={loading}
    /></Col>
    </Row>
  );
};
