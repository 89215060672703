import React, { ReactNode } from "react";
import { createRoot } from "react-dom/client";
import App from "./App";
import "./global.css";
import "normalize.css";
import { MainProvider } from "./contexts/main";
import { AuthProvider } from "./contexts/auth";
import { BrowserRouter } from "react-router-dom";
import "./index.scss";
import { ThemeProvider } from "./theme";
import { ThemeProviderPlate } from "./theme-provider";

const root = createRoot(document.getElementById("root") as HTMLElement);

root.render(
  (
    <MainProvider>
      <AuthProvider>
        <ThemeProvider>
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </ThemeProvider>
      </AuthProvider>
    </MainProvider>
  ) as any
);
