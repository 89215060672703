import { Table, Typography } from "antd";
import type { ColumnsType } from "antd/es/table";
import React from "react";

interface TableParams {
    data: {
        name: string;
        group: string;
        value: number;
      }[];
    loading: boolean;
}

const columns: ColumnsType<any> = [
  {
    title: "Setor",
    dataIndex: "name",
    sorter: false,
    width: "80%",
  },
  {
    title: "Total",
    sorter: false,
    dataIndex: "value",
  },
];

const TablePendingEvents: React.FC<TableParams> = ({data, loading}) => {
  return (
    <Table
      columns={columns}
      rowKey={(record) => record.id}
      dataSource={data}
      loading={loading}
      summary={pageData => {
        let total = 0;

        data.forEach(({ value }) => {
          total += value;
        });

        return (
          <>
            <Table.Summary.Row >
              <Table.Summary.Cell index={pageData.length}><Typography.Text strong>Total</Typography.Text></Table.Summary.Cell>
              <Table.Summary.Cell index={pageData.length}>
                <Typography.Text strong>{total}</Typography.Text>
              </Table.Summary.Cell>
            </Table.Summary.Row>
          </>
        );
      }}
    />
  );
};

export { TablePendingEvents };

