import React from 'react';
import { Form, FormItemProps, InputProps } from 'antd';
import styles from './TextAreaField.module.scss';
import { Input } from 'antd';
import { TextAreaProps } from 'antd/es/input';
import classNames from 'classnames';

const { TextArea } = Input;

interface TextAreaFieldProps extends FormItemProps {
  inputProps?: TextAreaProps;
}

const TextAreaField: React.FC<TextAreaFieldProps> = (props) => {
  const { inputProps } = props;
  const _props = { ...props };
  delete _props.inputProps;

  return (
    <Form.Item
      {..._props}
      className={classNames(`${styles['field']} ${props.className}`)}
    >
      <TextArea {...inputProps} />
    </Form.Item>
  );
};

export { TextAreaField };
