enum BlogRoutes {
  Blogs = '/blogs',
  BlogCreate = '/blog',
  BlogEdit = '/blog/:id',
  
  Categories = '/categories',
  CategoryCreate = '/category',
  CategoryEdit = '/category/:id',
}

export { BlogRoutes };
