import { ExclamationCircleFilled, PlusOutlined } from "@ant-design/icons";
import { useMutation, useQuery } from "@apollo/client";
import { Avatar, Button, Space, Tooltip } from "antd";
import confirm from "antd/es/modal/confirm";
import type { ColumnsType } from "antd/es/table";
import { useEffect, useState } from "react";
import { MdDelete, MdEdit, MdPassword } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../../../contexts/auth";
import { useMain } from "../../../../contexts/main";
import { ApiResult } from "../../../../models/apiResult.interface";
import { SimpleModel } from "../../../../models/simplemodel.interface";
import { clientAuth } from "../../../../services/graphql.service";
import { generatePassword } from "../../../../utils/generate-password";
import { AccountRoutes } from "../../../account/routes.enum";
import { changePassword, deleteUser, getUserQuery } from "../../../account/services/account.service";

export interface UserModel {
  uid: string;
  displayName: string;
  email: string;
  phoneNumber: string;
  photoUrl: string;
  emailVerified: boolean;
  disabled: boolean;
}

export interface SimpleUserModel {
  id: string;
  name: string;
  email: string;
  avatar: string;
  regional: SimpleModel[];
}

export interface UserFilterResult {
  userFilterById: ApiResult<UserFilterModel>
}

export interface UserFilterModel {
  regionals: SimpleModel[];
  administrations: SimpleModel[];
  sectors: SimpleModel[];
}

export interface Response<T> {
  nodes: T[];
  pageInfo: {
    hasNextPage: boolean;
    hasPreviousPage: boolean;
  };
  totalCount: number;
}

enum Sort {
  ASC = "ASC",
  DESC = "DESC",
}

export const UsersAccountFunctions = () => {
  const navigate = useNavigate();
  const { hasPermission } = useAuth();
  const { errorMessage, setLoading, handleChangeBreadcumb, setTitlePage } = useMain();
  
  useEffect(() => {
    const title = "Usuários";
    handleChangeBreadcumb([
      {
        title: "Home",
        path: "/",
      },
      {
        title: title,
      },
    ]);

    setTitlePage(title);
  }, []);

  const onClickCreateUser = () => {
    navigate(AccountRoutes.UserCreateUser);
  };

  const onClickEditUser = (id: string) => {
    const url = AccountRoutes.UserEditUser.replace(":id", id);
    navigate(url);
  };

  const [deleteUserMutation] = useMutation(deleteUser(), {
    client: clientAuth,
  });
  const onClickDeleteUser = (id: string) => {
    setLoading(true);
    deleteUserMutation({
      variables: {
        id,
      },
    }).then(() => {
      setLoading(false);
    });
  };

  const [data, setData] = useState<UserModel[]>();

  const { refetch, loading } = useQuery(getUserQuery(), {
    client: clientAuth,
    fetchPolicy: "no-cache",
    onCompleted: (result) => {
      const { data } = result.users;
      setData(data);
    },
    errorPolicy: "all",
    onError: ({ graphQLErrors, networkError }) => {
      if (graphQLErrors)
        graphQLErrors.forEach(({ message, locations, path }) =>
          errorMessage(message)
        );
      if (networkError)
        errorMessage("Ocorreu um erro inesperado. Tente novamente mais tarde!");
    },
  });

  const toolBarRender = () => [
    hasPermission(["admin", "admin-system"]) && (
      <Button
        key="button"
        icon={<PlusOutlined />}
        onClick={onClickCreateUser}
        type="primary">
        Novo
      </Button>
    ),
  ];

  const [changePasswordMutation] = useMutation(changePassword(), {
    client: clientAuth,
  });

  const handleResetPassword = (email: string) => {    
    const password = generatePassword({
      length: 12,
      includeLowerCase: true,
      includeUpperCase: true,
      includeNumber: true,
      includeSymbols: false
    });
    changePasswordMutation({
      variables: {
        request: {
          email,
          password,
          confirmPassword: password,
        }
      }
    }).then(() => {
      console.log(password)
      confirm({
        title: "Senha gerada com sucesso!",
        content: `A nova senha para o email ${email} é ${password}.`,
        type: "success",
      });
    });
  }

  const showChangePasswordConfirm = (name: string, email: string) => {
    confirm({
      title: `Deseja gerar uma nova senha para ${name}?`,
      icon: <ExclamationCircleFilled />,
      content: `Você realmente deseja gerar uma nova senha para ${name}?`,
      okText: "Sim",
      okButtonProps: {
        style: { background: "var(--primary-color)" },
      },
      cancelText: "Não",
      onOk() {
        return handleResetPassword(email);
      },
      onCancel() {},
    });
  };

  const showDeleteConfirm = (id: string, name: string) => {
    confirm({
      title: `Deseja deletar o usuário: ${name}?`,
      icon: <ExclamationCircleFilled />,
      content: "Você realmente deseja deletar este item?",
      okText: "Sim",
      okButtonProps: {
        style: { background: "var(--primary-color)" },
      },
      cancelText: "Não",
      onOk() {
        return onClickDeleteUser(id);
      },
      onCancel() {},
    });
  };


  const columns = (): ColumnsType<UserModel> => [
    {
      title: "Apelido",
      dataIndex: "photoUrl",
      render(value, record, index) {
        return <Avatar  src={record.photoUrl} />
      },
    },
    {
      title: "Nome",
      dataIndex: "displayName",
      ellipsis: true,
    },
    {
      title: "E-mail",
      dataIndex: "email",
      ellipsis: true,
    },
    {
      title: "Telefone",
      dataIndex: "phoneNumber",
      ellipsis: true,
    },
    {
      title: "Ações",
      key: "actions",
      width: 180,
      align: "center",
      render: (_, record) => [
        <Space>
        <Tooltip title="Gerar nova senha">
          <Button
            type="link"
            shape="circle"
            icon={<MdPassword />}
            onClick={() => showChangePasswordConfirm(record.email, record.displayName)}
          />
        </Tooltip>
          <Tooltip title="Editar usuário">
            <Button
              type="link"
              shape="circle"
              icon={<MdEdit />}
              onClick={() => onClickEditUser(record.uid)}
            />
          </Tooltip>
          <Tooltip title="Deletar usuário">
            <Button
              type="link"
              shape="circle"
              icon={<MdDelete />}
              onClick={() => showDeleteConfirm(record.uid, record.displayName)}
            />
          </Tooltip>
        </Space>,
      ],
    },
  ];
  return {
    columns,
    toolBarRender,
    data,
    refetch,
    loading
  };
};
