import { Form } from "antd";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { UserRequestInterface } from "../../../interfaces/userRequest.interface";
import { AuthService } from "../../../services/auth.service";
import { useMutation } from "@apollo/client";
import { clientAuth } from "../../../../../services/graphql.service";
import { AuthRoutes } from "../../../routes.enum";
import { useMain } from "../../../../../contexts/main";

export const ForgotPasswordFormFunctions = () => {
  const [form] = Form.useForm();
  const [passwordVisible, setPasswordVisible] = useState(false);
  const { successMessage, errorMessage } = useMain();
  const navigate = useNavigate();
  const { forgotPassword } = AuthService();
  const [forgotPasswordMutation, { loading: loadingRegister }] = useMutation(
    forgotPassword(),
    {
      client: clientAuth,
    }
  );

  const handleLogin = () => {
    navigate("/login");
  };

  const validateMessages = {
    required: "${label} é obrigatorio!",
    types: {
      email: "${label} não é válido",
    },
  };

  const onSubmit = (values: UserRequestInterface) => {
    forgotPasswordMutation({
      variables: {
        request: {
          ...values,
        },
      },
    }).then((result) => {
      const { success, message } = result.data.forgotPassword;
      if (success) {
        navigate(AuthRoutes.LOGIN);
        successMessage("Siga as instruções enviadas no seu e-mail.");
      } else {
        if (message) errorMessage(`${message}`);
        else
          errorMessage(
            `Ocorreu um erro inesperado. Tente novamente mais tarde.`
          );
      }
    });
  };

  return {
    handleLogin,
    validateMessages,
    form,
    passwordVisible,
    setPasswordVisible,
    onSubmit,
    loadingRegister,
  };
};
