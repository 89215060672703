import React from 'react';
import { Select } from 'antd';
import style from './SearchInput.module.scss';

interface SearchInputInterface {
  defaultValue?: string[];
  placeholder?: string;
  label?: string;
  onSearch?: (value: string[]) => void;
}

const SearchInput: React.FC<SearchInputInterface> = (
  props: SearchInputInterface,
) => {
  let { label, placeholder, onSearch, defaultValue } = props;

  if (!placeholder) placeholder = label;

  const handleChange = (value: string[]) => {
    onSearch?.(value);
  };

  return (
    <Select
      showArrow
      showSearch
      mode='tags'
      className={style.input}
      defaultValue={defaultValue}
      placeholder={placeholder}
      onChange={handleChange}
    />
  );
};

export { SearchInput };
