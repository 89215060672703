import { StyleSheet, Text, View } from "@react-pdf/renderer";
import { FC } from "react";
import { EventGroupPrintResponse } from "../../../pages/print-report/PrintReports.functions";
import Header from "./Header";
import ItemsEvents from "./ItemsEvents";

const styles = StyleSheet.create({
  tableContainer: {
    width: "95mm",
    marginRight: "5mm",
    flexDirection: "row",
    flexWrap: "wrap",
  },
  tableContainerType: {
    width: "100%",
    height: "277mm",
    flexDirection: "column",
    flexWrap: "wrap",
  },
  type: {
    width: "100%",
    textAlign: "center",
    alignItems: "center",
    justifyContent: "center",
    textTransform: "uppercase",
    borderTop: "0.3mm solid black",
    borderBottom: "0.3mm solid black",
    fontSize: "2.7mm",
    fontWeight: 700,
    letterSpacing: "0.3mm",
    flexWrap: "wrap",
    paddingHorizontal: "10mm",
    paddingVertical: "1mm",
    marginTop: "1mm"
  },
});

interface ItemsTypesProps {
  print?: EventGroupPrintResponse | null;
}

const ItemsTypes: FC<ItemsTypesProps> = ({ print }) => {
  return (
    <View style={styles.tableContainerType}>
      {print && <Header print={print} />}
      {print?.types?.map((type, index) => (
        <View style={styles.tableContainer} key={`type-row-${index}`}>
          <Text style={styles.type}>{type.type}</Text>
          {type.events &&
            type.events?.length > 0 &&
            ItemsEvents({
              events: type.events,
            })}
        </View>
      ))}
    </View>
  );
};

export default ItemsTypes;
