import { DocumentNode, gql } from "@apollo/client";

function getUserQuery(columnsResponse?: string): DocumentNode {
  if (!columnsResponse) {
    columnsResponse = `
          disabled
          email
          emailVerified
          phoneNumber
          photoUrl
          customClaims {
            key
          }`;
  }

  return gql`
    query Users {
      users {
        data {
          uid
          displayName
          disabled
          ${columnsResponse}
        }
        success
      }
    }
  `;
}

function getUserByIdQuery(columnsResponse?: string): DocumentNode {
  if (!columnsResponse) {
    columnsResponse = `
      disabled
      email
      emailVerified
      phoneNumber
      photoUrl
      customClaims {
        key
      }`;
  }

  return gql`
    query Users($id: String!) {
      userById(id: $id) {
        data {
          user {
            uid
            displayName
            ${columnsResponse}
          }
          regionals {
            id
            name
            isPrincipal
          }
          administrations {
            id
            name
            isPrincipal
            parent {
              name
            }
          }
          sectors {
            id
            name
            isPrincipal
            parent {
              name
            }
          }
        }
        success
        message
      }
    }
  `;
}

function getUserFilterByIdQuery(): DocumentNode {
  return gql`
    query UserFilterById($id: String!) {
      userFilterById(id: $id) {
        data {
          regionals {
            id
            name
            isPrincipal
          }
          administrations {
            id
            name
            isPrincipal
            parent {
              id
              name
            }
          }
          sectors {
            id
            name
            isPrincipal
            parent {
              id
              name
            }
          }
          __typename
        }
        success
        message
        __typename
      }
    }
  `;
}

function getUserStatisticsQuery(): DocumentNode {
  return gql`
    query GetUsersStatistics {
      statistics {
        data {
          name
          value
          description
        }
      }
    }
  `;
}

function createUser(): DocumentNode {
  return gql`
    mutation CreateUser($request: CreateUserRequestInput!) {
      createUser(request: $request) {
        data {
          uid
        }
        success
        message
        errorMessage
      }
    }
  `;
}

function createUserByAdmin(): DocumentNode {
  return gql`
    mutation CreateUserByAdmin($request: CreateUserByAdminRequestInput!) {
      createUserByAdmin(request: $request) {
        success
        message
        errorMessage
      }
    }
  `;
}

function updateUser(): DocumentNode {
  const CREATE = gql`
    mutation UpdateUser($id: String!, $request: UpdateUserRequestInput!) {
      updateUser(id: $id, request: $request) {
        success
        message
        errorMessage
      }
    }
  `;

  return CREATE;
}

function updateUserByAdmin(): DocumentNode {
  const CREATE = gql`
    mutation UpdateUserByAdmin(
      $id: String!
      $request: UpdateUserByAdminRequestInput!
    ) {
      updateUserByAdmin(id: $id, request: $request) {
        success
        message
        errorMessage
      }
    }
  `;

  return CREATE;
}

function updateUserRegional(): DocumentNode {
  const CREATE = gql`
    mutation UpdateUserRegional(
      $id: String!
      $request: UpdateUserByAdminRequestInput!
    ) {
      updateUserRegional(id: $id, request: $request) {
        success
        message
        errorMessage
      }
    }
  `;

  return CREATE;
}

function updateUserPrincipalRegional(): DocumentNode {
  const CREATE = gql`
    mutation UpdateUserPrincipalRegional(
      $id: String!
      $request: UpdateUserPrincipalRegionalRequestInput!
    ) {
      updateUserPrincipalRegional(id: $id, request: $request) {
        success
        message
        errorMessage
      }
    }
  `;

  return CREATE;
}

function updateUserPrincipalAdministration(): DocumentNode {
  const CREATE = gql`
    mutation UpdateUserPrincipalAdministration(
      $id: String!
      $request: UpdateUserPrincipalAdministrationRequestInput!
    ) {
      updateUserPrincipalAdministration(id: $id, request: $request) {
        success
        message
        errorMessage
      }
    }
  `;

  return CREATE;
}

function updateUserPrincipalSector(): DocumentNode {
  const CREATE = gql`
    mutation UpdateUserPrincipalSector(
      $id: String!
      $request: UpdateUserPrincipalSectorRequestInput!
    ) {
      updateUserPrincipalSector(id: $id, request: $request) {
        success
        message
        errorMessage
      }
    }
  `;

  return CREATE;
}

function updateUserRole(): DocumentNode {
  const CREATE = gql`
    mutation UpdateRole(
      $id: String!
      $request: UpdateUserByAdminRequestInput!
    ) {
      updateUserByAdmin(id: $id, request: $request) {
        success
        message
        errorMessage
      }
    }
  `;

  return CREATE;
}

function deleteUser(): DocumentNode {
  const CREATE = gql`
    mutation DeleteUser($id: String!) {
      deleteUser(id: $id) {
        success
        message
        errorMessage
      }
    }
  `;

  return CREATE;
}

function changePassword(): DocumentNode {
  const CREATE = gql`
    mutation ChangePassword($request: ChangePasswordRequestInput!) {
      changePassword(request: $request) {
        errorMessage
        message
        success
      }
    }
  `;

  return CREATE;
}

export {
  changePassword, createUser,
  createUserByAdmin,
  deleteUser,
  getUserByIdQuery,
  getUserFilterByIdQuery,
  getUserQuery,
  getUserStatisticsQuery,
  updateUser,
  updateUserByAdmin,
  updateUserPrincipalAdministration,
  updateUserPrincipalRegional,
  updateUserPrincipalSector,
  updateUserRegional,
  updateUserRole
};

