import { FaUsersGear } from "react-icons/fa6";
import { AppRoute } from "../../models/appRoute.model";
import { UserForm } from "../account/pages/user-form/UserForm";
import { UsersAccount } from "../account/pages/users/Users";
import { AccountRoutes } from "./routes.enum";

const getAccountRoutes = (): AppRoute[] => [
  new AppRoute({
    path: AccountRoutes.Users,
    component: <UsersAccount />,
    icon: <FaUsersGear />,
    name: "Usuários",
    routes: [
    ],
  }),
  new AppRoute({
    path: AccountRoutes.UserCreateUser,
    component: <UserForm />,
    name: "Novo usuário",
    hideInMenu: true,
  }),
  new AppRoute({
    path: AccountRoutes.UserEditUser,
    component: <UserForm />,
    name: "Editar o usuário",
    hideInMenu: true,
  }),
];

export { getAccountRoutes };

