import { useMutation, useQuery } from "@apollo/client";
import { Form, FormInstance } from "antd";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useMain } from "../../../../../contexts/main";
import { SimpleModel } from "../../../../../models/simplemodel.interface";
import {
    clientEvent,
    clientLocal,
} from "../../../../../services/graphql.service";
import { RegionalModel } from "../../../../locations/pages/regional/Regionals.functions";
import { getRegionalSelectQuery } from "../../../../locations/services/locations.service";
import { EventsRoutes } from "../../../routes.enum";
import {
    createEventGroup,
    getEventGroupByIdQuery,
    updateEventGroup,
} from "../../../services/events.service";

export const EventGroupCreateFunctions = () => {
  const { errorMessage, loading, setLoading, setTitlePage } = useMain();
  const { handleChangeBreadcumb } = useMain();
  const { id } = useParams();
  const navigate = useNavigate();
  const title = !id ? "Nova reunião" : "Alterar a reunião";
  const [description, setDescription] = useState<string | null>();

  useEffect(() => {
    setLoading(false);
    handleChangeBreadcumb([
      {
        title: "Home",
        path: "/",
      },
      {
        title: "Reuniões",
        path: EventsRoutes.EventGroups,
      },
      {
        title: title,
      },
    ]);
    setTitlePage(title);
  }, [id]);

  const [form] = Form.useForm<{
    name: string;
    date: Date;
    regional: SimpleModel;
  }>();
  const formRef = React.useRef<FormInstance>(null);

  const { loading: loadingGet } = useQuery(getEventGroupByIdQuery(), {
    client: clientEvent,
    variables: { id },
    fetchPolicy: "no-cache",
    onCompleted: (data) => {
      const result = data.eventGroupById;
      result.date = result.date ? dayjs(new Date(result.date)) : null;
      result.start = result.start ? dayjs(new Date(result.start)) : null;
      result.end = result.end ? dayjs(new Date(result.end)) : null;
      result.regionalId = result.regional?.id;
      formRef.current?.setFieldsValue(result);
      if (
        result.description &&
        result.description != "{}" &&
        result.description != "null"
      )
        setDescription(result.description);
    },
    errorPolicy: "all",
    onError: ({ graphQLErrors, networkError }) => {
      if (!id) return;

      if (graphQLErrors)
        graphQLErrors.forEach(({ message, locations, path }) =>
          errorMessage(message)
        );
      if (networkError)
        errorMessage("Ocorreu um erro inesperado. Tente novamente mais tarde!");
    },
  });

  const [regionals, setRegionals] = useState<RegionalModel[]>([]);
  const { loading: loadingRegionalsGet } = useQuery(getRegionalSelectQuery(), {
    client: clientLocal,
    fetchPolicy: "no-cache",
    variables: { skip: 0, take: 50 },
    onCompleted: (data) => {
      const { items } = data.regional;
      if (items) {
        const list = items?.map((_: RegionalModel) => ({
          label: _.name,
          value: _.id,
        }));
        setRegionals(list);
      }
    },
    errorPolicy: "all",
    onError: ({ graphQLErrors, networkError }) => {
      if (!id) return;

      if (graphQLErrors)
        graphQLErrors.forEach(({ message, locations, path }) =>
          errorMessage(message)
        );
      if (networkError)
        errorMessage("Ocorreu um erro inesperado. Tente novamente mais tarde!");
    },
  });

  const [sendUpdateUserMutation] = useMutation(updateEventGroup(), {
    client: clientEvent,
  });
  const [sendCreateUserMutation] = useMutation(createEventGroup(), {
    client: clientEvent,
  });

  const onFinish = (values: any) => {
    setLoading(true);
    const { name, date, start, end, regionalId } = values;

    if (id) {
      sendUpdateUserMutation({
        variables: {
          id,
          request: {
            name,
            date,
            start,
            end,
            regionalId,
            description,
          },
        },
      }).then((result) => {
        if (result.errors) {
          console.log({ errors: result.errors });
          errorMessage("Houve um erro ao realizar a alterar a reunião.");
          setLoading(false);
          return;
        }

        const { success } = result.data.updateEventGroup;
        if (!success) {
          errorMessage("Houve um erro ao realizar a alterar a reunião.");
          setLoading(false);
          return;
        }

        setLoading(false);
        navigate(EventsRoutes.EventGroups);
      });
    } else {
      sendCreateUserMutation({
        variables: {
          request: {
            name,
            date,
            start,
            end,
            regionalId,
            description,
          },
        },
      }).then((result) => {
        if (result.errors) {
          console.log({ errors: result.errors });
          errorMessage("Houve um erro ao realizar a alterar a reunião.");
          setLoading(false);
          return;
        }

        const { success, message } = result.data.createEventGroup;
        if (!success) {
          errorMessage(
            message ?? "Houve um erro ao realizar a alterar a reunião."
          );
          setLoading(false);
          return;
        }

        setLoading(false);
        navigate(EventsRoutes.EventGroups);
      });
    }
  };

  return {
    id,
    title,
    form,
    formRef,
    onFinish,
    loadingGet,
    loading,
    regionals,
    loadingRegionalsGet,
    description,
    setDescription,
  };
};
