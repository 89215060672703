import {
  Button,
  Card,
  Col,
  Flex,
  Form,
  Input,
  Row,
  Skeleton
} from "antd";
import TextArea from "antd/es/input/TextArea";
import { CategoryFormFunctions } from "./CategoryForm.functions";
import style from "./CategoryForm.module.scss";
import { CategoryAvatar } from "./category-avatar/CategoryAvatar";

export const CategoryForm = () => {
  const { id, form, formRef, onFinish, loadingGet, loading } =
    CategoryFormFunctions();

  return (
    <Card className={style["form-card"]}>
      <Skeleton loading={loadingGet} active />
      <Form
        form={form}
        ref={formRef}
        hidden={loadingGet}
        className={style["form"]}
        name="user-form"
        layout="vertical"
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
        onFinish={onFinish}
        autoComplete="off">
        <Form.Item>
          <Row gutter={[16, 16]}>
            <Col span={24} lg={20}>
              <Row gutter={[16, 16]}>
                <Col span={24} lg={12}>
                  <Form.Item
                    label="URL"
                    name="code"
                    help="A URL é gerada automaticamente com base no título dado para o artigo.">
                    <Input disabled />
                  </Form.Item>
                </Col>
                <Col span={24} lg={12}>
                  <Form.Item
                    label="Título"
                    name="title"
                    rules={[
                      { required: true, message: "O título é obrigatório!" },
                      {
                        max: 50,
                        message: "O título pode ter no máximo 50 caracteres!",
                      },
                    ]}>
                    <Input />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={[16, 16]}>
                <Col span={24}>
                  <Form.Item label="Descrição" name="description">
                    <TextArea />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
            {id && (
              <Col span={24} lg={4}>
                <Flex justify={"center"} align="center">
                  <CategoryAvatar id={id} />
                </Flex>
              </Col>
            )}
          </Row>
        </Form.Item>

        <Form.Item className={style["button-save"]} wrapperCol={{ span: 16 }}>
          <Button type="primary" htmlType="submit" loading={loading}>
            Salvar
          </Button>
        </Form.Item>
      </Form>
    </Card>
  );
};
