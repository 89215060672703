import { useQuery } from "@apollo/client";
import { useEffect, useState } from "react";
import { useMain } from "../../../../contexts/main";
import { clientEvent } from "../../../../services/graphql.service";
import { EventGroupStatusEnum } from "../../../events/pages/event-group/EventGroups.functions";
import { getEventGroupsQuery, getEventsForPrintQuery } from "../../services/reports.service";

export interface EventGroupsResponse {
  administration: string;
  id: string;
  name: string;
  date: Date;
  actual: boolean;
  status: EventGroupStatusEnum;
}

export interface EventPrintResponse {
  date: string;
  hour: string;
  local?: string;
  people?: string;
}

export interface EventsByTypePrintResponse {
  type: string;
  order: number;
  events?: EventPrintResponse[];
}

export interface EventGroupPrintResponse {
  administration: string;
  name: string;
  date: Date;
  warning: string;
  types?: EventsByTypePrintResponse[];
}

export const PrintReportsFunctions = () => {
  const { errorMessage, handleChangeBreadcumb, loading, setLoading, setTitlePage } = useMain();
  const [printItem, setPrintItem] = useState<EventGroupPrintResponse | null>();
  const [groups, setGroups] = useState<EventGroupsResponse[]>();
  const [actualGroup, setActualGroup] = useState();

  useEffect(() => {
    const title = "Lista de batismos e diversos";
    handleChangeBreadcumb([
      {
        title: "Home",
        path: "/",
      },
      {
        title: title,
      },
    ]);

    setTitlePage(title);
  }, []);

  const onCompleted = (response: any) => {
    setPrintItem(response?.data?.printEvents?.data);
  };

  const { loading: loadingEvents, refetch } = useQuery(
    getEventsForPrintQuery(),
    {
      client: clientEvent,
      variables: {
        request: {
          eventGroupId: null,
        },
      },
      fetchPolicy: "no-cache",
      onCompleted: onCompleted,
      errorPolicy: "all",
      onError: ({ graphQLErrors, networkError }) => {
        if (graphQLErrors)
          graphQLErrors.forEach(({ message, locations, path }) =>
            errorMessage(message)
          );
        if (networkError)
          errorMessage(
            "Ocorreu um erro inesperado. Tente novamente mais tarde!"
          );
      },
    }
  );

  const onCompletedGroup = (response: any) => {
    setGroups(response?.eventGroups?.items);
    setActualGroup(response?.eventGroups?.items?.find((group: any) => group.actual)?.id);
  };

  const { loading: loadingGroup } = useQuery(
    getEventGroupsQuery(),
    {
      client: clientEvent,
      fetchPolicy: "no-cache",
      onCompleted: onCompletedGroup,
      errorPolicy: "all",
      onError: ({ graphQLErrors, networkError }) => {
        if (graphQLErrors)
          graphQLErrors.forEach(({ message, locations, path }) =>
            errorMessage(message)
          );
        if (networkError)
          errorMessage(
            "Ocorreu um erro inesperado. Tente novamente mais tarde!"
          );
      },
    }
  );

  useEffect(() => {
    setLoading(loadingEvents || loadingGroup);
  }, [loadingEvents, loadingGroup]);

  useEffect(() => {
    if(actualGroup) {
      refetch({
        request: {
          eventGroupId: actualGroup,
        },
      }).then((response) => {
        onCompleted(response);
      });
    }
  }, [actualGroup]);

  return {
    printItem,
    setPrintItem,
    groups,
    refetch,
    onCompleted,
    loading,
    actualGroup
  };
};
