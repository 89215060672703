import { CategoryModel, CategoriesFunctions } from "./Categories.functions";
import { ProTable } from "../../../../components/table/pro-table/ProTable";

export const Categories = () => {
  const {
    columns,
    columnsMap,
    clientBlog,
    whereDefault,
    variables,
    setVariables,
    toolBarRender,
  } = CategoriesFunctions();

  return (
    <>
      <ProTable<CategoryModel>
        client={clientBlog}
        queryName="category"
        whereType="CategoryModelFilterInput"
        whereDefault={whereDefault}
        orderType="CategoryModelSortInput"
        variables={variables}
        setVariables={setVariables}
        columns={columns()}
        defaultColumnsMap={columnsMap}
        toolBarRender={toolBarRender}
        onFormatColumnsMap={(columns) => {
          columns = columns?.replace(
            "icon",
            `icon {
              path
            }`
          );

          return columns;
        }}
      />
    </>
  );
};
