import {
  Avatar,
  Button,
  Card,
  Checkbox,
  Col,
  Form,
  Input,
  InputNumber,
  Row,
  Skeleton,
  Space,
} from "antd";
import { RegionalCreateFunctions } from "./RegionalForm.functions";
import style from "./RegionalForm.module.scss";

export const RegionalForm = () => {
  const { title, form, formRef, onFinish, loadingGet, loading } =
  RegionalCreateFunctions();

  return (
    <Card className={style["form-card"]}>
      <Skeleton loading={loadingGet} active />
      <Form
        form={form}
        ref={formRef}
        hidden={loadingGet}
        className={style["form"]}
        name="user-form"
        layout="vertical"
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
        onFinish={onFinish}
        autoComplete="off">
        <Form.Item>
          <Row gutter={[16, 16]}>
            <Col span={24} lg={18}>
              <Form.Item
                label="Nome"
                name="name"
                rules={[
                  { required: true, message: "O nome é obrigatório!" },
                  {
                    max: 50,
                    message: "O nome pode ter no máximo 30 caracteres!",
                  },
                ]}>
                <Input />
              </Form.Item>
            </Col>
          </Row>
        </Form.Item>

        <Form.Item label="Descrição" name="description">
          <Input.TextArea />
        </Form.Item>

        <Form.Item className={style["button-save"]} wrapperCol={{ span: 16 }}>
          <Button type="primary" htmlType="submit" loading={loading}>
            Salvar
          </Button>
        </Form.Item>
      </Form>
    </Card>
  );
};
