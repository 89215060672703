import { ProTable } from "../../../../components/table/pro-table/ProTable";
import style from "./PeopleManagement.module.scss";
import { PeopleModel, PeoplesFunctions } from "./Peoples.functions";

export const PeoplesManagement = () => {
  const {
    columns,
    columnsMap,
    clientPeople,
    whereDefault,
    variables,
    setVariables,
    toolBarRender,
  } = PeoplesFunctions();

  return (
    <>
      <ProTable<PeopleModel>
        headerTitle="Pessoas"
        client={clientPeople}
        queryName="peoples"
        whereType="PeopleModelFilterInput"
        whereDefault={whereDefault}
        orderType="PeopleModelSortInput"
        variables={variables}
        setVariables={setVariables}
        columns={columns()}
        defaultColumnsMap={columnsMap}
        toolBarRender={toolBarRender}
        onFormatColumnsMap={(columns) => {
          columns = columns?.replace(
            "local",
            `local {
                name
             }`
          );

          columns = columns?.replace(
            "role",
            `role {
                name
             }`
          );
          return columns;
        }}
      />
    </>
  );
};
