import { gql } from "@apollo/client";
import { DocumentNode } from "graphql";

function getEventsForPrintQuery(): DocumentNode {
  return gql`
    query EventsByGroupAndDate($request: GetEventsByPrintRequestInput!) {
      printEvents(request: $request) {
        data {
          administration
          name
          date
          warning
          types {
            type
            order
            events {
              date
              hour
              local
              people
            }
          }
        }
      }
    }
  `;
}

function getEventGroupsQuery(): DocumentNode {
  return gql`
    query EventGroups {
      eventGroups(skip: 0, take: 100, order: { date: DESC }) {
        pageInfo {
          hasNextPage
          hasPreviousPage
        }
        totalCount
        items {
          id
          regional {
            name
          }
          name
          date
          actual
          status
        }
      }
    }
  `;
}

export { getEventGroupsQuery, getEventsForPrintQuery };

