import { ExclamationCircleFilled, PlusOutlined } from "@ant-design/icons";
import type { ColumnsState } from "@ant-design/pro-components";
import { useMutation, useQuery } from "@apollo/client";
import { Button, Modal, Space, Tag, Tooltip } from "antd";
import { useEffect, useState } from "react";
import { MdDelete, MdEdit, MdPublish } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { ProColumnsProps } from "../../../../components/table/pro-table/ProTable";
import { useAuth } from "../../../../contexts/auth";
import { useMain } from "../../../../contexts/main";
import { clientBlog } from "../../../../services/graphql.service";
import { BlogRoutes } from "../../routes.enum";
import {
  deleteBlog,
  getTagsSelectQuery,
  publishBlog,
} from "../../services/blog.service";
import { CategoryModel } from "../category/Categories.functions";

export type BlogModel = {
  id: string;
  title: string;
  content: string;
  status: BlogStatusEnum;
  category: CategoryModel;
  tags: string[];
};

export interface Response<T> {
  nodes: T[];
  pageInfo: {
    hasNextPage: boolean;
    hasPreviousPage: boolean;
  };
  totalCount: number;
}

enum Sort {
  ASC = "ASC",
  DESC = "DESC",
}

export enum BlogStatusEnum {
  InDraft = "IN_DRAFT",
  Published = "PUBLISHED",
}

export const getDescriptionStatus = (status: BlogStatusEnum) => {
  if (status == BlogStatusEnum.InDraft) return "Em rascunho";
  if (status == BlogStatusEnum.Published) return "Publicado";
  return status;
};

export const getColorStatus = (status: BlogStatusEnum) => {
  if (status == BlogStatusEnum.InDraft) return "warning";
  if (status == BlogStatusEnum.Published) return "success";

  return "warning";
};

const { confirm } = Modal;

export const BlogsFunctions = () => {
  const navigate = useNavigate();
  const { errorMessage, handleChangeBreadcumb, setTitlePage } = useMain();
  const { hasPermission } = useAuth();
  const title = "Blogs";

  useEffect(() => {
    handleChangeBreadcumb([
      {
        title: "Home",
        path: "/",
      },
      {
        title: title,
      },
    ]);
    setTitlePage(title);
  }, []);

  const onClickCreate = () => {
    navigate(BlogRoutes.BlogCreate);
  };

  const onClickEdit = (id: string) => {
    const url = BlogRoutes.BlogEdit.replace(":id", id);
    navigate(url);
  };

  const [publishMutation] = useMutation(publishBlog(), {
    client: clientBlog,
    errorPolicy: "all",
    onCompleted: () => {
      setVariables({ ...variables });
    },
    onError: ({ graphQLErrors, networkError }) => {
      if (graphQLErrors)
        graphQLErrors.forEach(({ message, locations, path }) =>
          errorMessage(message)
        );
      if (networkError)
        errorMessage("Ocorreu um erro inesperado. Tente novamente mais tarde!");
    },
  });

  const onClickPublish = (id: string) => {
    return publishMutation({
      variables: {
        id,
      },
    });
  };

  const [deleteMutation] = useMutation(deleteBlog(), {
    client: clientBlog,
    errorPolicy: "all",
    onCompleted: () => {
      setVariables({ ...variables });
    },
    onError: ({ graphQLErrors, networkError }) => {
      if (graphQLErrors)
        graphQLErrors.forEach(({ message, locations, path }) =>
          errorMessage(message)
        );
      if (networkError)
        errorMessage("Ocorreu um erro inesperado. Tente novamente mais tarde!");
    },
  });

  const onClickDelete = (id: string) => {
    return deleteMutation({
      variables: {
        id,
      },
    });
  };

  const toolBarRender = () => [
    hasPermission(["admin", "admin-system"]) && (
      <Button
        key="button"
        icon={<PlusOutlined />}
        onClick={onClickCreate}
        type="primary">
        Novo
      </Button>
    ),
  ];

  const { refetch: refetchTags } = useQuery(getTagsSelectQuery(), {
    client: clientBlog,
    fetchPolicy: "no-cache",
    variables: { skip: 0, take: 50 },
    errorPolicy: "all",
    onError: ({ graphQLErrors, networkError }) => {
      if (graphQLErrors)
        graphQLErrors.forEach(({ message, locations, path }) =>
          errorMessage(message)
        );
      if (networkError)
        errorMessage("Ocorreu um erro inesperado. Tente novamente mais tarde!");
    },
  });

  const columns = (): ProColumnsProps<BlogModel>[] => [
    {
      title: "URL",
      dataIndex: "code",
      filters: true,
      onFilter: true,
      ellipsis: true,
      sorter: true,
    },
    {
      title: "Título",
      dataIndex: "title",
      filters: true,
      onFilter: true,
      ellipsis: true,
      sorter: true,
    },
    {
      title: "Status",
      dataIndex: "status",
      filters: true,
      onFilter: true,
      ellipsis: true,
      sorter: true,
      renderFormItem: (_, { defaultRender }) => {
        return defaultRender(_);
      },
      render: (_, record) => (
        <Tag color={getColorStatus(record?.status)}>
          {getDescriptionStatus(record?.status)}
        </Tag>
      ),
    },
    {
      title: "TAGs",
      dataIndex: "tags",
      valueType: "select",
      request: async (params) => {
        const result = await refetchTags();
        const { data } = result.data.allTagsAsync;
        if (data) {
          const list = data?.map((_: string) => ({
            label: _,
            value: _,
          }));
          return list;
        }
        return [];
      },
      filters: true,
      onFilter: true,
      ellipsis: true,
      sorter: true,
      renderFormItem: (_, { defaultRender }) => {
        return defaultRender(_);
      },
      render: (_, record) => record.tags?.map((t) => <Tag>{t}</Tag>),
    },
    {
      title: "Categoria",
      dataIndex: "category",
      search: false,
      renderFormItem: (_, { defaultRender }) => {
        return defaultRender(_);
      },
      render: (_, record) => record.category?.title,
    },
    {
      title: "Ações",
      key: "actions",
      search: false,
      width: 180,
      align: "center",
      permission: ["admin", "admin-system"],
      render: (_, record) => [
        <Space>
          {record.status === BlogStatusEnum.InDraft && (
            <Tooltip title="Publicar blog">
              <Button
                type="link"
                shape="circle"
                icon={<MdPublish />}
                onClick={() => onClickPublish(record.id)}
              />
            </Tooltip>
          )}
          <Tooltip title="Editar blog">
            <Button
              type="link"
              shape="circle"
              icon={<MdEdit />}
              onClick={() => onClickEdit(record.id)}
            />
          </Tooltip>
          <Tooltip title="Deletar blog">
            <Button
              type="link"
              shape="circle"
              icon={<MdDelete />}
              onClick={() => showDeleteConfirm(record.id, record.title)}
            />
          </Tooltip>
        </Space>,
      ],
    },
  ];

  const whereDefault = {
    title: {
      contains: "",
    },
  };

  const [variables, setVariables] = useState({
    skip: 0,
    take: 10,
    where: whereDefault,
    order: { title: Sort.ASC },
  });

  const columnsMap = {
    code: {
      show: true,
    },
    title: {
      disable: true,
      show: true,
    },
    status: {
      show: true,
    },
    tags: {
      show: true,
    },
    category: {
      show: true,
    },
  } as Record<string, ColumnsState>;

  const showDeleteConfirm = (id: string, name: string) => {
    confirm({
      title: `Deseja deletar ao blog: ${name}?`,
      icon: <ExclamationCircleFilled />,
      content: "Você realmente deseja deletar este item?",
      okText: "Sim",
      okButtonProps: {
        style: { background: "var(--primary-color)" },
      },
      cancelText: "Não",
      onOk() {
        return onClickDelete(id);
      },
      onCancel() {},
    });
  };

  return {
    columns,
    columnsMap,
    clientBlog,
    whereDefault,
    variables,
    setVariables,
    toolBarRender,
  };
};
