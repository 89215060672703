import React from "react";
import { AppRoute } from "../../models/appRoute.model";
import { PeopleRoutes } from "./routes.enum";
import { PeoplesManagement } from "./pages/people-management/Peoples";
import { HiOutlineUserGroup, HiOutlineUsers } from "react-icons/hi";
import { PeopleForm } from "./pages/people-management/people-form/PeopleForm";
import { PeopleRoles } from "./pages/people-role/PeopleRoles";
import { PeopleRoleForm } from "./pages/people-role/people-role-form/PeopleRoleForm";
import { FaSitemap } from "react-icons/fa6";
import { IoPeopleSharp } from "react-icons/io5";

const getPeopleRoutes = (): AppRoute[] => [
  new AppRoute({
    path: PeopleRoutes.Peoples,
    component: <PeoplesManagement />,
    icon: <IoPeopleSharp />,
    name: "Pessoas",
    permission: ["admin-system", "admin"],
  }),
  new AppRoute({
    path: PeopleRoutes.PeopleCreate,
    component: <PeopleForm />,
    permission: ["admin-system", "admin"],
  }),
  new AppRoute({
    path: PeopleRoutes.PeopleEdit,
    component: <PeopleForm />,
    permission: ["admin-system", "admin"],
  }),
  
  new AppRoute({
    path: PeopleRoutes.PeopleRoles,
    component: <PeopleRoles />,
    icon: <FaSitemap />,
    name: "Funções",
    permission: ["admin-system", "admin"],
  }),
  new AppRoute({
    path: PeopleRoutes.PeopleRoleCreate,
    component: <PeopleRoleForm />,
    permission: ["admin-system", "admin"],
  }),
  new AppRoute({
    path: PeopleRoutes.PeopleRoleEdit,
    component: <PeopleRoleForm />,
    permission: ["admin-system", "admin"],
  }),
];

export { getPeopleRoutes };
