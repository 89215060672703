import { CityModel, CitysFunctions } from "./Cities.functions";
import { ProTable } from "../../../../components/table/pro-table/ProTable";

export const Cities = () => {
  const {
    columns,
    columnsMap,
    clientLocal,
    whereDefault,
    orderDefault,
    variables,
    setVariables,
    toolBarRender,
  } = CitysFunctions();

  return (
    <>
      <ProTable<CityModel>
        client={clientLocal}
        queryName="cities"
        whereType="CityModelFilterInput"
        whereDefault={whereDefault}
        orderDefault={orderDefault}
        orderType="CityModelSortInput"
        variables={variables}
        setVariables={setVariables}
        columns={columns()}
        defaultColumnsMap={columnsMap}
        toolBarRender={toolBarRender}
      />
    </>
  );
};
