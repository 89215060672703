import { ProTable } from "../../../../components/table/pro-table/ProTable";
import { EventGroupModel, EventGroupsFunctions } from "./EventGroups.functions";

export const EventGroups = () => {
  const {
    columns,
    columnsMap,
    clientEvent,
    whereDefault,
    orderDefault,
    variables,
    setVariables,
    toolBarRender,
  } = EventGroupsFunctions();

  return (
    <>
      <ProTable<EventGroupModel>
        client={clientEvent}
        queryName="eventGroups"
        whereType="EventGroupModelFilterInput"
        whereDefault={whereDefault}
        orderType="EventGroupModelSortInput"
        orderDefault={orderDefault}
        variables={variables}
        setVariables={setVariables}
        columns={columns()}
        defaultColumnsMap={columnsMap}
        toolBarRender={toolBarRender}
        onFormatColumnsMap={(columns) => {
          columns = columns?.replace(
            "regional",
            `regional {
              id
              name
            }`
          );

          columns = `${columns}
            actual`;

          return columns;
        }}
      />
    </>
  );
};
