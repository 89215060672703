import { useMutation, useQuery } from "@apollo/client";
import { Form, FormInstance } from "antd";
import { OptionProps } from "antd/es/select";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useMain } from "../../../../../contexts/main";
import { SimpleModel } from "../../../../../models/simplemodel.interface";
import {
    clientEvent,
    clientLocal,
    clientPeople,
} from "../../../../../services/graphql.service";
import { HomeRoutes } from "../../../../home/routes.enum";
import { LocalModel } from "../../../../locations/pages/local/Locals.functions";
import {
    getLocalsSelectQuery
} from "../../../../locations/services/locations.service";
import { PeopleModel } from "../../../../peoples/pages/people-management/Peoples.functions";
import { getPeoplesSelectQuery } from "../../../../peoples/services/people.service";
import { EventsRoutes } from "../../../routes.enum";
import {
    createEvent,
    getEventByIdQuery,
    getEventGroupsSimpleSelectQuery,
    getEventTypesSelectQuery,
    updateEvent,
} from "../../../services/events.service";
import { EventGroupModel } from "../../event-group/EventGroups.functions";
import { EventTypeModel } from "../../event-type/EventTypes.functions";
import { HistoryModel } from "../Events.functions";

export const EventCreateFunctions = () => {
  const { errorMessage, successMessage, loading, setLoading, setTitlePage } =
    useMain();
  const { handleChangeBreadcumb } = useMain();
  const { id } = useParams();
  const navigate = useNavigate();
  const title = !id ? "Novo evento" : "Alterar o evento";
  const searchParams = new URLSearchParams(location.search);
  const isPending = (searchParams.get("isPending") as boolean | null) ?? false;

  useEffect(() => {
    setLoading(false);
    handleChangeBreadcumb([
      {
        title: "Home",
        path: "/",
      },
      {
        title: !isPending ? "Eventos" : "Eventos pendentes",
        path: !isPending ? EventsRoutes.Events : HomeRoutes.PENDING_EVENTS,
      },
      {
        title: title,
      },
    ]);
    setTitlePage(title);
  }, [id]);

  const [form] = Form.useForm<{
    name: string;
    date: Date;
    regional: SimpleModel;
  }>();
  const formRef = React.useRef<FormInstance>(null);

  const [histories, setHistories] = useState<HistoryModel[]>();
  const { loading: loadingGet } = useQuery(getEventByIdQuery(), {
    client: clientEvent,
    variables: { id },
    fetchPolicy: "no-cache",
    onCompleted: (data) => {
      const result = data.eventById;
      var dateString = result.date;

      var dateParts = dateString.split("/");

      var date = new Date(
        +dateParts[2],
        Number.parseInt(dateParts[1]) - 1,
        +dateParts[0]
      );
      result.date = dateString ? dayjs(date) : null;
      result.hour = result.hour;
      result.groupId = result.group?.id;
      result.typeId = result.type?.id;
      result.localId = result.local?.id;
      result.peopleId = result.people?.id;
      setHistories(result.histories);
      formRef.current?.setFieldsValue(result);

      setLoading(false);
    },
    errorPolicy: "all",
    onError: ({ graphQLErrors, networkError }) => {
      if (!id) return;

      if (graphQLErrors)
        graphQLErrors.forEach(({ message, locations, path }) =>
          errorMessage(message)
        );
      if (networkError)
        errorMessage("Ocorreu um erro inesperado. Tente novamente mais tarde!");

      setLoading(false);
    },
  });

  const [eventGroups, setEventGroups] = useState<OptionProps[]>([]);
  const { loading: loadingEventGroupsGet } = useQuery(
    getEventGroupsSimpleSelectQuery(),
    {
      client: clientEvent,
      fetchPolicy: "no-cache",
      variables: { skip: 0, take: 100 },
      onCompleted: (result) => {
        const { items } = result.eventGroups;
        if (items) {
          const list = items?.map((_: EventGroupModel) => ({
            label: `${_.regional?.name} - ${_.name}`,
            value: _.id,
          }));
          setEventGroups(list);
        }
      },
      errorPolicy: "all",
      onError: ({ graphQLErrors, networkError }) => {
        if (!id) return;

        if (graphQLErrors)
          graphQLErrors.forEach(({ message, locations, path }) =>
            errorMessage(message)
          );
        if (networkError)
          errorMessage(
            "Ocorreu um erro inesperado. Tente novamente mais tarde!"
          );
      },
    }
  );

  const [eventTypes, setEventTypes] = useState<OptionProps[]>([]);
  const { loading: loadingEventTypessGet } = useQuery(
    getEventTypesSelectQuery(),
    {
      client: clientEvent,
      fetchPolicy: "no-cache",
      variables: { skip: 0, take: 100 },
      onCompleted: (data) => {
        const { items } = data.eventTypes;
        if (items) {
          const list = items?.map((_: EventTypeModel) => ({
            label: _.name,
            value: _.id,
          }));
          setEventTypes(list);
        }
      },
      errorPolicy: "all",
      onError: ({ graphQLErrors, networkError }) => {
        if (!id) return;

        if (graphQLErrors)
          graphQLErrors.forEach(({ message, locations, path }) =>
            errorMessage(message)
          );
        if (networkError)
          errorMessage(
            "Ocorreu um erro inesperado. Tente novamente mais tarde!"
          );
      },
    }
  );

  const [locals, setLocals] = useState<OptionProps[]>([]);
  const { loading: loadingLocalsGet } = useQuery(getLocalsSelectQuery(), {
    client: clientLocal,
    fetchPolicy: "no-cache",
    variables: { skip: 0, take: 500 },
    onCompleted: (data) => {
      const { items } = data.locals;
      if (items) {
        const list = items?.map((_: LocalModel) => ({
          label: `${_.city?.surname || _.city?.cityName} - ${_.name}`,
          value: _.id,
        }));
        setLocals(list);
      }
    },
    errorPolicy: "all",
    onError: ({ graphQLErrors, networkError }) => {
      if (!id) return;

      if (graphQLErrors)
        graphQLErrors.forEach(({ message, locations, path }) =>
          errorMessage(message)
        );
      if (networkError)
        errorMessage("Ocorreu um erro inesperado. Tente novamente mais tarde!");
    },
  });

  const [peoples, setPeoples] = useState<OptionProps[]>([]);
  const { loading: loadingPeoplesGet } = useQuery(getPeoplesSelectQuery(), {
    client: clientPeople,
    fetchPolicy: "no-cache",
    variables: { skip: 0, take: 500, role: "Ancião" },
    onCompleted: (data) => {
      const { items } = data.peoples;
      if (items) {
        const list = items?.map((_: PeopleModel) => ({
          label: _.surname || _.name,
          value: _.id,
        }));
        setPeoples(list);
      }
    },
    errorPolicy: "all",
    onError: ({ graphQLErrors, networkError }) => {
      if (!id) return;

      if (graphQLErrors)
        graphQLErrors.forEach(({ message, locations, path }) =>
          errorMessage(message)
        );
      if (networkError)
        errorMessage("Ocorreu um erro inesperado. Tente novamente mais tarde!");
    },
  });

  const [sendUpdateMutation] = useMutation(updateEvent(), {
    client: clientEvent,
  });
  const [sendCreateMutation] = useMutation(createEvent(), {
    client: clientEvent,
  });

  const onFinish = (values: any) => {
    setLoading(true);
    const { groupId, typeId, name, date, hour, localId, peopleId } = values;

    if (id) {
      sendUpdateMutation({
        variables: {
          id,
          request: {
            groupId,
            typeId,
            name,
            date,
            hour,
            localId,
            peopleId,
          },
        },
      }).then((result) => {
        if (result.errors) {
          console.log({ errors: result.errors });
          errorMessage("Houve um erro ao alterar o evento.");
          setLoading(false);
          return;
        }

        const { success } = result.data.updateEvent;
        if (!success) {
          errorMessage("Houve um erro ao alterar o evento.");
          setLoading(false);
          return;
        }
        setLoading(false);
        navigate(!isPending ? EventsRoutes.Events : HomeRoutes.PENDING_EVENTS);
      });
    } else {
      sendCreateMutation({
        variables: {
          request: {
            groupId,
            typeId,
            name,
            date,
            hour,
            localId,
            peopleId,
          },
        },
      }).then((result) => {
        if (result.errors) {
          console.log({ errors: result.errors });
          errorMessage("Houve um erro ao criar o evento.");
          setLoading(false);
          return;
        }

        setLoading(false);
        const { success, message } = result.data.createEvent;
        if (!success) {
          errorMessage(message ?? "Houve um erro ao criar o evento.");
          return;
        }

        successMessage("Evento cadastrado com sucesso.");
        formRef.current?.getFieldInstance("localId")!.focus({
          cursor: "all",
        });
        setLoading(false);
      });
    }
  };

  return {
    id,
    title,
    form,
    formRef,
    onFinish,
    loadingGet,
    loading,
    eventGroups,
    loadingEventGroupsGet,
    eventTypes,
    loadingEventTypessGet,
    locals,
    loadingLocalsGet,
    peoples,
    loadingPeoplesGet,
    isPending,
    histories,
  };
};
