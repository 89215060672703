import { useMutation, useQuery } from "@apollo/client";
import { Form, FormInstance } from "antd";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAuth } from "../../../../../contexts/auth";
import { useMain } from "../../../../../contexts/main";
import { clientAuth } from "../../../../../services/graphql.service";
import { AccountRoutes } from "../../../../account/routes.enum";
import {
  createUserByAdmin,
  getUserByIdQuery,
  updateUserByAdmin,
} from "../../../../account/services/account.service";

interface UserGeneralDataFunctionsProps {
  setSurname: React.Dispatch<React.SetStateAction<string | undefined>>;
}

export const UserGeneralDataFunctions = (
  props: UserGeneralDataFunctionsProps
) => {
  const { errorMessage, loading, setLoading } = useMain();
  const { auth } = useAuth()
  const { setSurname } = props;
  const { id } = useParams();
  const [edit, setEdit] = useState<boolean>(!id);
  const navigate = useNavigate();

  const [selectRegionals, setSelectedRegionals] = useState<[]>();
  const [selectAdministrations, setSelectedAdministrations] = useState<[]>();
  const [selectSectors, setSelectedSectors] = useState<[]>();

  const [form] = Form.useForm<{
    surname: string;
    name: string;
    email: string;
  }>();
  const formRef = React.useRef<FormInstance>(null);

  const { loading: loadingGet } = useQuery(getUserByIdQuery(), {
    client: clientAuth,
    variables: { id },
    fetchPolicy: "no-cache",
    onCompleted: (data) => {
      const { data: {user} } = data.userById;
      formRef.current?.setFieldsValue(user);
    },
    errorPolicy: "all",
    onError: ({ graphQLErrors, networkError }) => {
      if (!id) return;

      if (graphQLErrors)
        graphQLErrors.forEach(({ message, locations, path }) =>
          errorMessage(message)
        );
      if (networkError)
        errorMessage("Ocorreu um erro inesperado. Tente novamente mais tarde!");
    },
  });

  const displayName = Form.useWatch("displayName", form);

  useEffect(() => {
    setSurname(displayName);
  }, [displayName]);

  const [sendUpdateUserMutation] = useMutation(updateUserByAdmin(), {
    client: clientAuth,
  });
  const [sendCreateUserMutation] = useMutation(createUserByAdmin(), {
    client: clientAuth,
  });

  const onFinish = (values: any) => {
    setLoading(true);
    const { displayName, email, geralRoleId } = values;

    if (id) {
      sendUpdateUserMutation({
        variables: {
          id,
          request: {
            name: displayName,
            geralRole: {
              key: "user",
              value: geralRoleId,
            },
            regionalIds: selectRegionals ?? [],
            administrationIds: selectAdministrations ?? [],
            sectorIds: selectSectors ?? [],
          },
        },
      })
        .then((result) => {
          if (result.errors) {
            errorMessage("Houve um erro ao realizar a alterar o usuário.");
            setLoading(false);
            return;
          }

          const { success, data, message } = result.data.updateUserByAdmin;
          if (!success) {
            errorMessage(
              message ?? "Houve um erro ao realizar a alterar o usuário."
            );
            setLoading(false);
            return;
          }
        })
        .finally(() => {
          setLoading(false);
          setEdit(false);
        });
    } else {
      sendCreateUserMutation({
        variables: {
          request: {
            name: displayName,
            email,
            geralRole: {
              key: "user",
              value: geralRoleId,
            },
            regionalIds: selectRegionals ?? [],
            administrationIds: selectAdministrations ?? [],
            sectorIds: selectSectors ?? [],
          },
        },
      }).then((result) => {
        if (result.errors) {
          errorMessage("Houve um erro ao realizar a alterar o usuário.");
          setLoading(false);
          return;
        }

        const { success, data, message } = result.data.createUserByAdmin;
        if (!success) {
          errorMessage(
            message ?? "Houve um erro ao realizar a alterar o usuário."
          );
          setLoading(false);
          return;
        }
        const newId = data?.id;
        const redirect = AccountRoutes.UserEditUser.replace(":id", newId);
        if (newId) {
          navigate(redirect);
        } else {
          navigate(AccountRoutes.Users);
        }
        setLoading(false);
        setEdit(false);
      });
    }
  };

  return {
    id,
    form,
    formRef,
    onFinish,
    loading,
    loadingGet,
    edit,
    setEdit,
    selectRegionals,
    selectAdministrations,
    selectSectors,
    setSelectedRegionals,
    setSelectedAdministrations,
    setSelectedSectors,
  };
};
