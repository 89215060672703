import { useMain } from "../../../../../contexts/main";
import { useUploadFiles } from "../../../../../hooks/useUploadFiles";
import { UploadAvatarField } from "../../../../data-entry/form-fields/upload-avatar-field";
import style from "./AvatarField.module.scss";

interface AvatarFieldProps {
  bucketName?: string;
  id?: string;
  collectionName?: string;
  fieldName?: string;
  src: string;
  setSrc?: React.Dispatch<React.SetStateAction<string | undefined>>;
}

const AvatarField = (props: AvatarFieldProps) => {
  const { src, setSrc } = props;
  const { loading } = useMain();
  const { handleUploadFirebase } = useUploadFiles();

  const customRequest = (requestOptions: any) => {
    handleUploadFirebase(requestOptions);
  };

  return (
    <div className={style.box}>
      {!loading && (
        <>
          <UploadAvatarField
            name="avatar"
            imgCropProps={
              {
                cropShape: "round",
                aspect: 1 / 1,
                modaltitle: "Ajustar a imagem",
                modalOk: "Salvar",
              } as any
            }
            uploadProps={{
              maxCount: 1,
              customRequest,
              defaultFileList: [
                {
                  url: src ?? "https://sge.ccbbh.com.br/images/logo.png",
                  uid: "",
                  name: "image.png",
                  status: "done",
                },
              ],
              beforeUpload(file) {
                return new Promise((resolve) => {
                  const reader = new FileReader();
                  reader.readAsDataURL(file);
                  reader.onload = () => {
                    const img = document.createElement("img");
                    img.src = reader.result as string;
                    setSrc && setSrc(reader.result as string);
                    img.onload = () => {
                      const canvas = document.createElement("canvas");
                      canvas.width = img.naturalWidth;
                      canvas.height = img.naturalHeight;
                      const ctx = canvas.getContext("2d")!;
                      ctx.drawImage(img, 0, 0);
                      canvas.toBlob((result) => resolve(result as any));
                    };
                  };
                });
              },
            }}
          />
        </>
      )}
    </div>
  );
};

export { AvatarField };

