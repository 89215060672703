enum AuthRoutes {
  LOGIN = '/login',
  AUTOLOGIN = '/auto-login',
  LOGOUT = '/logout',
  FORGOT_PASSWORD = '/forgot-password',
  RESET_PASSWORD = '/reset-password',
  REGISTER = '/register',
  NOT_AUTHORIZED = '/not-authorized',
  UNREGISTERED_USER = '/unregistered-user',
}

export { AuthRoutes };
