import { DocumentNode, gql } from "@apollo/client";

const AuthService = () => {
  function sendLogin(): DocumentNode {
    const LOGIN = gql`
      mutation Login($userName: String!, $password: String!) {
        login(request: { userName: $userName, password: $password }) {
          success
          message
          errorMessage
          data {
            accessToken
            refreshToken
            permissions
          }
        }
      }
    `;

    return LOGIN;
  }

  function createUser(): DocumentNode {
    const CREATE = gql`
      mutation CreateUser($createUserInput: CreateUserRequestInput!) {
        createUser(request: $createUserInput) {
          success
          message
          errorMessage
          data {
            uid
            displayName
          }
        }
      }
    `;

    return CREATE;
  }

  function forgotPassword(): DocumentNode {
    const CREATE = gql`
      mutation ForgotPassword($request: ForgotPasswordRequestInput!) {
        forgotPassword(request: $request) {
          success
          message
          errorMessage
        }
      }
    `;

    return CREATE;
  }

  function resetPassword(): DocumentNode {
    const CREATE = gql`
      mutation ResetPassword($request: ResetPasswordRequestInput!) {
        resetPassword(request: $request) {
          success
          message
          errorMessage
        }
      }
    `;

    return CREATE;
  }

  return {
    sendLogin,
    createUser,
    forgotPassword,
    resetPassword,
  };
};

export { AuthService };

