import { DocumentNode, gql } from "@apollo/client";

function updateUser(): DocumentNode {
  const CREATE = gql`
    mutation UpdateUser($id: String!, $request: UpdateUserRequestInput!) {
      updateUser(id: $id, request: $request) {
        success
        message
        errorMessage
      }
    }
  `;

  return CREATE;
}

function changePassword(): DocumentNode {
  const CREATE = gql`
    mutation ChangePassword($request: ChangePasswordRequestInput!) {
      changePassword(request: $request) {
        success
        message
        errorMessage
      }
    }
  `;

  return CREATE;
}

function updateUserContacts(): DocumentNode {
  const CREATE = gql`
    mutation UpdateUserContact($id: ID!, $request: UpdateContactsInput!) {
      updateUserContact(id: $id, request: $request) {
        success
        message
        errorMessage
      }
    }
  `;

  return CREATE;
}

export { changePassword, updateUser, updateUserContacts };

