import { Fragment } from "react";
import { BsCalendar2EventFill } from "react-icons/bs";
import { HiHome, HiMiniClipboardDocumentList } from "react-icons/hi2";
import { useRoutes } from "react-router-dom";
import PrivateRoute from "./components/private-route/PrivateRoute";
import { useAuth } from "./contexts/auth";
import { AppRoute } from "./models/appRoute.model";
import { getAccountRoutes } from "./modules/account/routes";
import { getAuthRoutes } from "./modules/auth/routes";
import { AuthRoutes } from "./modules/auth/routes.enum";
import { getBlogsRoutes } from "./modules/blog/routes";
import { getEventsRoutes } from "./modules/events/routes";
import { PendingEventsList } from "./modules/home/pages/pending-events-list/PendingEventsList";
import { getHomeRoutes } from "./modules/home/routes";
import { HomeRoutes } from "./modules/home/routes.enum";
import { getLocationsRoutes } from "./modules/locations/routes";
import { getMyUserRoutes } from "./modules/my-account/routes";
import { getPeopleRoutes } from "./modules/peoples/routes";
import { getReportsRoutes } from "./modules/reports/routes";
import { flattenTreeToList } from "./utils/array";

function App() {
  const { hasPermission } = useAuth();
  let routes: AppRoute[] = [
    ...getAuthRoutes(),
    ...getMyUserRoutes(),
    {
      name: "Home",
      icon: <HiHome />,
      component: <PendingEventsList />,
      path: HomeRoutes.HOME,
      permission: ["admin-system", "admin", "user"],
      routes: [...getHomeRoutes()],
    },
    {
      name: "Relatórios",
      icon: <HiMiniClipboardDocumentList />,
      path: "report",
      permission: ["admin-system", "admin", "user"],
      routes: [...getReportsRoutes()],
    },
    {
      name: "Eventos",
      icon: <BsCalendar2EventFill />,
      path: "geral-events",
      permission: ["admin-system", "admin", "user"],
      routes: [...getEventsRoutes()],
    },
    {
      name: "Cadastros",
      icon: <HiHome />,
      path: "creates",
      routes: [...getPeopleRoutes(), ...getLocationsRoutes()],
      permission: ["admin-system", "admin"],
    },
    {
      name: "Configurações",
      icon: <HiHome />,
      path: "settings",
      routes: [...getAccountRoutes()],
    },
    {
      name: "Base de conhecimentos",
      icon: <HiHome />,
      path: "knowlead",
      routes: [...getBlogsRoutes()],
    },
  ];

  routes = routes.filter((_) => _.notPermission || hasPermission(_.permission));

  routes = routes.map((_) => ({
    ..._,
    routes: _.routes?.filter(
      (_) => _.notPermission || hasPermission(_.permission)
    ),
  }));

  const cloneRoutes: AppRoute[] = [...routes];

  let routesReducer = flattenTreeToList<AppRoute>(routes, "routes");
  routesReducer = routesReducer.filter(
    (_) => _.notPermission || hasPermission(_.permission)
  );
  routesReducer
    .filter((f: AppRoute) => !f.notUseMainLayout)
    ?.forEach((route: AppRoute) => {
      route.component = (
        <PrivateRoute routes={cloneRoutes} component={route.component} />
      );
    });

  routesReducer
    .filter((f: AppRoute) => !f.notUseMainLayout)
    .forEach((route: AppRoute) => {
      route.component = <Fragment>{route.component}</Fragment>;
    });

  var fullRoutes = useRoutes(
    routesReducer.map((_: AppRoute) => ({
      path: _.path,
      element: _.component,
    }))
  );

  if (
    !hasPermission(["admin", "admin-user", "user"]) &&
    !Object.values(AuthRoutes).includes(window.location.pathname as AuthRoutes)
  ) {
    window.location.href = AuthRoutes.NOT_AUTHORIZED;
    return fullRoutes;
  }

  return fullRoutes;
}

export default App;
