import React, { useEffect, useState } from "react";
import { useMain } from "../../../../contexts/main";
import { useParams } from "react-router-dom";
import { AccountRoutes } from "../../routes.enum";

export const UserCreateAccountFunctions = () => {
  const [surname, setSurname] = useState<string>();
  const { handleChangeBreadcumb, setTitlePage } = useMain();
  const { id } = useParams();

  useEffect(() => {
    const title = !id ? "Novo Usuário" : "Alterar o usuário";
    handleChangeBreadcumb([
      {
        title: "Home",
        path: "/",
      },
      {
        title: "Usuários",
        path: AccountRoutes.Users,
      },
      {
        title: title,
      },
    ]);

    setTitlePage(title);
  }, []);

  return { surname, setSurname, id };
};
