import { ExclamationCircleFilled, PlusOutlined } from "@ant-design/icons";
import type { ColumnsState } from "@ant-design/pro-components";
import { useMutation, useQuery } from "@apollo/client";
import { Button, Modal, Space, Tag, Tooltip } from "antd";
import { useEffect, useState } from "react";
import { MdDelete, MdEdit } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { ProColumnsProps } from "../../../../components/table/pro-table/ProTable";
import { useAuth } from "../../../../contexts/auth";
import { useMain } from "../../../../contexts/main";
import { SimpleModel } from "../../../../models/simplemodel.interface";
import { clientLocal } from "../../../../services/graphql.service";
import { LocalStorageService } from "../../../../services/localStorage.service";
import { LocationsRoutes } from "../../routes.enum";
import {
    deleteAdministration,
    getRegionalSelectQuery,
} from "../../services/locations.service";
import { RegionalModel } from "../regional/Regionals.functions";

export type AdministrationModel = {
  id: string;
  name: string;
  description: string;
  regional: SimpleModel;
};

export interface Response<T> {
  nodes: T[];
  pageInfo: {
    hasNextPage: boolean;
    hasPreviousPage: boolean;
  };
  totalCount: number;
}

enum Sort {
  ASC = "ASC",
  DESC = "DESC",
}

const { confirm } = Modal;

export const AdministrationsFunctions = () => {
  const navigate = useNavigate();
  const { errorMessage, handleChangeBreadcumb, setTitlePage } = useMain();
  const { hasPermission } = useAuth();
  const title = "Administrações";
  const queryName = "administrations";

  useEffect(() => {
    handleChangeBreadcumb([
      {
        title: "Home",
        path: "/",
      },
      {
        title: title,
      },
    ]);
    setTitlePage(title);
  }, []);

  const onClickCreate = () => {
    navigate(LocationsRoutes.AdministrationCreate);
  };

  const onClickEdit = (id: string) => {
    const url = LocationsRoutes.AdministrationEdit.replace(":id", id);
    navigate(url);
  };

  const [deleteMutation] = useMutation(deleteAdministration(), {
    client: clientLocal,
    errorPolicy: "all",
    onCompleted: () => {
      setVariables({ ...variables });
    },
    onError: ({ graphQLErrors, networkError }) => {
      if (graphQLErrors)
        graphQLErrors.forEach(({ message, locations, path }) =>
          errorMessage(message)
        );
      if (networkError)
        errorMessage("Ocorreu um erro inesperado. Tente novamente mais tarde!");
    },
  });

  const onClickDelete = (id: string) => {
    return deleteMutation({
      variables: {
        id,
      },
    });
  };

  const toolBarRender = () => [
    hasPermission(["admin", "admin-system"]) && (
      <Button
        key="button"
        icon={<PlusOutlined />}
        onClick={onClickCreate}
        type="primary">
        Novo
      </Button>
    ),
  ];

  const { refetch: refetchRegionals } = useQuery(getRegionalSelectQuery(), {
    client: clientLocal,
    fetchPolicy: "no-cache",
    variables: { skip: 0, take: 50 },
    errorPolicy: "all",
    onError: ({ graphQLErrors, networkError }) => {
      if (graphQLErrors)
        graphQLErrors.forEach(({ message, locations, path }) =>
          errorMessage(message)
        );
      if (networkError)
        errorMessage("Ocorreu um erro inesperado. Tente novamente mais tarde!");
    },
  });

  const columns = (): ProColumnsProps<AdministrationModel>[] => [
    {
      title: "Regional",
      dataIndex: ["regional", "id"],
      valueType: "select",
      params: {},
      initialValue: LocalStorageService.getLocalStorage(`${queryName}.regional.id`),
      request: async (params) => {
        const result = await refetchRegionals();
        const { items } = result.data.regional;
        if (items) {
          const list = items?.map((_: RegionalModel) => ({
            label: `${_.name}`,
            value: _.id,
          }));
          return list;
        }
        return [];
      },
      renderFormItem: (_, { defaultRender }) => {
        return defaultRender(_);
      },
      render: (_, record) => <Tag color={"blue"}>{record.regional?.name}</Tag>,
    },
    {
      title: "Nome",
      dataIndex: "name",
      filters: true,
      onFilter: true,
      ellipsis: true,
      sorter: true,
    },
    {
      title: "Descrição",
      dataIndex: "description",
      filters: true,
      onFilter: true,
      ellipsis: true,
      sorter: true,
    },
    {
      title: "Ações",
      key: "actions",
      search: false,
      width: 180,
      align: "center",
      permission: ["admin", "admin-system"],
      render: (_, record) => [
        <Space>
          <Tooltip title="Editar administration">
            <Button
              type="link"
              shape="circle"
              icon={<MdEdit />}
              onClick={() => onClickEdit(record.id)}
            />
          </Tooltip>
          <Tooltip title="Deletar administration">
            <Button
              type="link"
              shape="circle"
              icon={<MdDelete />}
              onClick={() => showDeleteConfirm(record.id, record.name)}
            />
          </Tooltip>
        </Space>,
      ],
    },
  ];

  const whereDefault = {
    name: {
      contains: "",
    },
  };

  const [variables, setVariables] = useState({
    skip: 0,
    take: 10,
    where: whereDefault,
    order: { name: Sort.ASC },
  });

  const columnsMap = {
    regional: {
      show: true,
    },
    name: {
      disable: true,
      show: true,
    },
    description: {
      show: true,
    },
  } as Record<string, ColumnsState>;

  const showDeleteConfirm = (id: string, name: string) => {
    confirm({
      title: `Deseja deletar a administration: ${name}?`,
      icon: <ExclamationCircleFilled />,
      content: "Você realmente deseja deletar este item?",
      okText: "Sim",
      okButtonProps: {
        style: { background: "var(--primary-color)" },
      },
      cancelText: "Não",
      onOk() {
        return onClickDelete(id);
      },
      onCancel() {},
    });
  };

  return {
    queryName,
    columns,
    columnsMap,
    clientLocal,
    whereDefault,
    variables,
    setVariables,
    toolBarRender,
  };
};
