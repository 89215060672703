import React from 'react';
import { Form, FormItemProps, Input } from 'antd';
import styles from './PasswordField.module.scss';
import { PasswordProps } from 'antd/es/input';

interface PasswordFieldProps extends FormItemProps {
  passwordVisible?: boolean;
  setPasswordVisible?: React.Dispatch<React.SetStateAction<boolean>>;
  inputProps?: PasswordProps;
}

const PasswordField: React.FC<PasswordFieldProps> = (props) => {
  const { inputProps, passwordVisible, setPasswordVisible } = props;
  const _props = { ...props };
  delete _props.inputProps;
  delete _props.passwordVisible;
  delete _props.setPasswordVisible;

  return (
    <Form.Item className={styles['field']} {..._props}>
      <Input.Password
        className={styles['input']}
        visibilityToggle={{
          visible: passwordVisible,
          onVisibleChange: setPasswordVisible,
        }}
        {...inputProps}
      />
    </Form.Item>
  );
};

export { PasswordField };
