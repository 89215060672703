import { ProLayout } from "@ant-design/pro-components";
import { Button, Col, Result, Row } from "antd";
import React from "react";
import { AuthRoutes } from "../../routes.enum";

const UnregisteredUser: React.FC = () => (
  <ProLayout
    layout={"top"}
    logo={"/images/logo.svg"}
    title="Sistema de Gestão de Eventos"
  >
    <Col span={24}>
      <Row justify={"center"} align="middle" style={{ height: "calc(100vh - 120px)" }}>
        <Result
          status="403"
          title="Complete seu cadastro"
          subTitle="Desculpe, seu usuário não está cadastrado. Entre em contato com um administrador para a conclusão do seu cadastro"
          extra={
            <Button
              type="primary"
              onClick={() => {
                window.location.href = AuthRoutes.LOGIN;
              }}
            >
              Entrar com outra conta
            </Button>
          }
        />
      </Row>
    </Col>
  </ProLayout>
);

export { UnregisteredUser };

