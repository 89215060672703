import { Button as ButtonAntd, ButtonProps as ButtonPropsAntd } from "antd";
import style from './Button.module.scss';
import classNames from "classnames";

interface ButtonProps extends ButtonPropsAntd {
    className?: string;
}

export const Button = (props: ButtonProps) => {
    return (
        <ButtonAntd  {...props} type='primary' className={classNames(
            style.button, props.className
        )} />
    );
};