import {
  Avatar,
  Button,
  Card,
  Checkbox,
  Col,
  Form,
  Input,
  InputNumber,
  Row,
  Select,
  Skeleton,
  Space,
} from "antd";
import { LocalCreateFunctions } from "./LocalForm.functions";
import style from "./LocalForm.module.scss";

export const LocalForm = () => {
  const {
    title,
    form,
    formRef,
    onFinish,
    loadingGet,
    loading,
    sectors,
    loadingSectorsGet,
    cities,
    loadingCitysGet,
  } = LocalCreateFunctions();

  return (
    <Card className={style["form-card"]}>
      <Skeleton loading={loadingGet} active />
      <Form
        form={form}
        ref={formRef}
        hidden={loadingGet}
        className={style["form"]}
        name="user-form"
        layout="vertical"
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
        onFinish={onFinish}
        autoComplete="off">
        <Form.Item>
          <Row gutter={[16, 16]}>
            <Col span={24} lg={6}>
              <Form.Item label="Código" name="externalCode">
                <Input />
              </Form.Item>
            </Col>
            <Col span={24} lg={18}>
              <Form.Item
                label="Nome"
                name="name"
                rules={[
                  { required: true, message: "O nome é obrigatório!" },
                  {
                    max: 30,
                    message: "O nome pode ter no máximo 30 caracteres!",
                  },
                ]}>
                <Input />
              </Form.Item>
            </Col>
          </Row>
        </Form.Item>

        <Form.Item>
          <Row gutter={[16, 16]}>
            <Col span={24} lg={12}>
              <Form.Item
                label="Cidade"
                name="cityId"
                rules={[
                  { required: true, message: "A cidade é obrigatório!" },
                ]}>
                <Select
                  showSearch
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  options={cities}
                  loading={loadingCitysGet}
                  style={{
                    width: "100%",
                  }}
                />
              </Form.Item>
            </Col>
            <Col span={24} lg={12}>
              <Form.Item label="Setor" name="sectorId">
                <Select
                  showSearch
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  options={sectors}
                  loading={loadingSectorsGet}
                  style={{
                    width: "100%",
                  }}
                />
              </Form.Item>
            </Col>
          </Row>
        </Form.Item>

        <Form.Item label="Descrição" name="description">
          <Input.TextArea />
        </Form.Item>

        <Form.Item className={style["button-save"]} wrapperCol={{ span: 16 }}>
          <Button type="primary" htmlType="submit" loading={loading}>
            Salvar
          </Button>
        </Form.Item>
      </Form>
    </Card>
  );
};
