import { DocumentNode, gql } from "@apollo/client";

function getRegionalQuery(columnsResponse?: string): DocumentNode {
  const whereType = "RegionalModelFilterInput";
  const orderType = "RegionalModelSortInput";
  if (!columnsResponse) {
    columnsResponse = `
    name
    description`;
  }

  return gql`
    query Regionals($skip: Int!, $take: Int!, $where: ${whereType}!, $order: [${orderType}!]) {
      regional(skip: $skip, take: $take, where: $where, order: $order) {
        pageInfo {
          hasNextPage
          hasPreviousPage
        }
        totalCount
        items {
          id
          ${columnsResponse}
        }
      }
    }
  `;
}

function getRegionalSelectQuery(columnsResponse?: string): DocumentNode {
  if (!columnsResponse) {
    columnsResponse = `
    name
    description`;
  }

  return gql`
    query Regionals($skip: Int!, $take: Int!) {
      regional(skip: $skip, take: $take) {
        pageInfo {
          hasNextPage
          hasPreviousPage
        }
        totalCount
        items {
          id
          ${columnsResponse}
        }
      }
    }
  `;
}

function getRegionalByIdQuery(columnsResponse?: string): DocumentNode {
  if (!columnsResponse) {
    columnsResponse = `
    name
    description`;
  }

  return gql`
    query Regionals($id: ID!) {
      regionalById(id: $id) {
        id
        ${columnsResponse}
      }
    }
  `;
}

function getRegionalsStatisticsQuery(): DocumentNode {
  return gql`
    query GetRegionalsStatistics {
      regionalStatistics {
        data {
          name
          value
          description
        }
      }
    }
  `;
}

function createRegional(): DocumentNode {
  return gql`
    mutation CreateRegional($request: CreateRegionalRequestInput!) {
      createRegional(request: $request) {
        data {
          id
        }
        success
        message
        errorMessage
      }
    }
  `;
}

function updateRegional(): DocumentNode {
  const CREATE = gql`
    mutation UpdateRegional($id: ID!, $request: UpdateRegionalRequestInput!) {
      updateRegional(id: $id, request: $request) {
        success
        message
        errorMessage
      }
    }
  `;

  return CREATE;
}

function deleteRegional(): DocumentNode {
  const CREATE = gql`
    mutation DeleteRegional($id: ID!) {
      deleteRegional(id: $id) {
        success
        message
        errorMessage
      }
    }
  `;

  return CREATE;
}

function getAdministrationQuery(columnsResponse?: string): DocumentNode {
  const whereType = "AdministrationModelFilterInput";
  const orderType = "AdministrationModelSortInput";
  if (!columnsResponse) {
    columnsResponse = `
    name
    description`;
  }

  return gql`
    query Administrations($skip: Int!, $take: Int!, $where: ${whereType}!, $order: [${orderType}!]) {
      administrations(skip: $skip, take: $take, where: $where, order: $order) {
        pageInfo {
          hasNextPage
          hasPreviousPage
        }
        totalCount
        items {
          id
          ${columnsResponse}
        }
      }
    }
  `;
}

function getAdministrationsSelectQuery(columnsResponse?: string): DocumentNode {
  if (!columnsResponse) {
    columnsResponse = `
    name
    description
    regional {
      id
      name
    }`;
  }

  return gql`
    query Adminisrtations($skip: Int!, $take: Int!) {
      administrations(skip: $skip, take: $take) {
        pageInfo {
          hasNextPage
          hasPreviousPage
        }
        totalCount
        items {
          id
          ${columnsResponse}
        }
      }
    }
  `;
}

function getAdministrationsSelectWithWhereQuery(columnsResponse?: string): DocumentNode {
  const whereType = "AdministrationModelFilterInput";
  if (!columnsResponse) {
    columnsResponse = `
    name
    description
    regional {
      id
      name
    }`;
  }

  return gql`
    query Adminisrtations($skip: Int!, $take: Int!, $where: ${whereType}!) {
      administrations(skip: $skip, take: $take, where: $where) {
        pageInfo {
          hasNextPage
          hasPreviousPage
        }
        totalCount
        items {
          id
          ${columnsResponse}
        }
      }
    }
  `;
}


function getAdministrationByIdQuery(columnsResponse?: string): DocumentNode {
  if (!columnsResponse) {
    columnsResponse = `
    name
    description
    regional {
      id
      name
    }`;
  }

  return gql`
    query Administrations($id: ID!) {
      administrationById(id: $id) {
        id
        ${columnsResponse}
      }
    }
  `;
}

function getAdministrationsStatisticsQuery(): DocumentNode {
  return gql`
    query GetAdministrationsStatistics {
      statistics {
        data {
          name
          value
          description
        }
      }
    }
  `;
}

function createAdministration(): DocumentNode {
  return gql`
    mutation CreateAdministration($request: CreateAdministrationRequestInput!) {
      createAdministration(request: $request) {
        data {
          id
        }
        success
        message
        errorMessage
      }
    }
  `;
}

function updateAdministration(): DocumentNode {
  const CREATE = gql`
    mutation UpdateAdministration(
      $id: ID!
      $request: UpdateAdministrationRequestInput!
    ) {
      updateAdministration(id: $id, request: $request) {
        success
        message
        errorMessage
      }
    }
  `;

  return CREATE;
}

function deleteAdministration(): DocumentNode {
  const CREATE = gql`
    mutation DeleteAdministration($id: ID!) {
      deleteAdministration(id: $id) {
        success
        message
        errorMessage
      }
    }
  `;

  return CREATE;
}

function getSectorQuery(columnsResponse?: string): DocumentNode {
  const whereType = "SectorModelFilterInput";
  const orderType = "SectorModelSortInput";
  if (!columnsResponse) {
    columnsResponse = `
    name
    description
    administration {
      id
      name
      parent {
        id
        name
      }
    }`;
  }

  return gql`
    query Sectors($skip: Int!, $take: Int!, $where: ${whereType}!, $order: [${orderType}!]) {
      sectors(skip: $skip, take: $take, where: $where, order: $order) {
        pageInfo {
          hasNextPage
          hasPreviousPage
        }
        totalCount
        items {
          id
          ${columnsResponse}
        }
      }
    }
  `;
}

function getSectorsSelectQuery(columnsResponse?: string): DocumentNode {
  if (!columnsResponse) {
    columnsResponse = `
    name
    description
    administration {
      id
      name
      parent {
        id
        name
      }
    }`;
  }

  return gql`
    query Sectors($skip: Int!, $take: Int!) {
      sectors(skip: $skip, take: $take) {
        pageInfo {
          hasNextPage
          hasPreviousPage
        }
        totalCount
        items {
          id
          ${columnsResponse}
        }
      }
    }
  `;
}

function getSectorsSelectWithWhereQuery(columnsResponse?: string): DocumentNode {
  const whereType = "SectorModelFilterInput";
  if (!columnsResponse) {
    columnsResponse = `
    name
    description
    administration {
      id
      name
      parent {
        id
        name
      }
    }`;
  }

  return gql`
    query Sectors($skip: Int!, $take: Int!, $where: ${whereType}!) {
      sectors(skip: $skip, take: $take, where: $where) {
        pageInfo {
          hasNextPage
          hasPreviousPage
        }
        totalCount
        items {
          id
          ${columnsResponse}
        }
      }
    }
  `;
}

function getSectorByIdQuery(columnsResponse?: string): DocumentNode {
  if (!columnsResponse) {
    columnsResponse = `
    name
    description
    administration {
      id
      id
      name
      parent {
        id
        name
      }
    }`;
  }

  return gql`
    query Sectors($id: ID!) {
      sectorById(id: $id) {
        id
        ${columnsResponse}
      }
    }
  `;
}

function getSectorsStatisticsQuery(): DocumentNode {
  return gql`
    query GetSectorsStatistics {
      statistics {
        data {
          name
          value
          description
        }
      }
    }
  `;
}

function createSector(): DocumentNode {
  return gql`
    mutation CreateSector($request: CreateSectorRequestInput!) {
      createSector(request: $request) {
        data {
          id
        }
        success
        message
        errorMessage
      }
    }
  `;
}

function updateSector(): DocumentNode {
  const CREATE = gql`
    mutation UpdateSector($id: ID!, $request: UpdateSectorRequestInput!) {
      updateSector(id: $id, request: $request) {
        success
        message
        errorMessage
      }
    }
  `;

  return CREATE;
}

function deleteSector(): DocumentNode {
  const CREATE = gql`
    mutation DeleteSector($id: ID!) {
      deleteSector(id: $id) {
        success
        message
        errorMessage
      }
    }
  `;

  return CREATE;
}

function getLocalQuery(columnsResponse?: string): DocumentNode {
  const whereType = "LocalModelFilterInput";
  const orderType = "LocalModelSortInput";
  if (!columnsResponse) {
    columnsResponse = `
    externalCode
    name
    description
    city {
      id
      cityName
      surname
      state
      country
    }
    sector {
      id
      name
      parent {
        id
        name
          parent {
            id
            name
          }
      }
    }`;
  }

  return gql`
    query Locals($skip: Int!, $take: Int!, $where: ${whereType}!, $order: [${orderType}!]) {
      locals(skip: $skip, take: $take, where: $where, order: $order) {
        pageInfo {
          hasNextPage
          hasPreviousPage
        }
        totalCount
        items {
          id
          ${columnsResponse}
        }
      }
    }
  `;
}

function getLocalsSelectQuery(columnsResponse?: string): DocumentNode {
  if (!columnsResponse) {
    columnsResponse = `
    externalCode
    id
    code
    name
    description
    city {
      id
      cityName
      surname
      state
      country
    }`;
  }

  return gql`
    query Locals($skip: Int!, $take: Int!) {
      locals(skip: $skip, take: $take) {
        pageInfo {
          hasNextPage
          hasPreviousPage
        }
        totalCount
        items {
          id
          ${columnsResponse}
        }
      }
    }
  `;
}

function getLocalByIdQuery(columnsResponse?: string): DocumentNode {
  if (!columnsResponse) {
    columnsResponse = `
    externalCode
    name
    description
    city {
      id
      cityName
      surname
      state
      country
    }
    sector {
      id
      name
      parent {
        id
        name
          parent {
            id
            name
          }
      }
    }`;
  }

  return gql`
    query Locals($id: ID!) {
      localById(id: $id) {
        id
        ${columnsResponse}
      }
    }
  `;
}

function getLocalsStatisticsQuery(): DocumentNode {
  return gql`
    query GetLocalsStatistics {
      localStatistics {
        data {
          name
          value
          description
        }
      }
    }
  `;
}

function createLocal(): DocumentNode {
  return gql`
    mutation CreateLocal($request: CreateLocalRequestInput!) {
      createLocal(request: $request) {
        data {
          id
        }
        success
        message
        errorMessage
      }
    }
  `;
}

function updateLocal(): DocumentNode {
  const CREATE = gql`
    mutation UpdateLocal($id: ID!, $request: UpdateLocalRequestInput!) {
      updateLocal(id: $id, request: $request) {
        success
        message
        errorMessage
      }
    }
  `;

  return CREATE;
}

function deleteLocal(): DocumentNode {
  const CREATE = gql`
    mutation DeleteLocal($id: ID!) {
      deleteLocal(id: $id) {
        success
        message
        errorMessage
      }
    }
  `;

  return CREATE;
}

function getCitiesQuery(columnsResponse?: string): DocumentNode {
  const whereType = "LocalModelFilterInput";
  const orderType = "LocalModelSortInput";
  if (!columnsResponse) {
    columnsResponse = `
    surname
    country
    state
    cityName`;
  }

  return gql`
    query Cities($skip: Int!, $take: Int!, $where: ${whereType}!, $order: [${orderType}!]) {
      cities(skip: $skip, take: $take, where: $where, order: $order) {
        pageInfo {
          hasNextPage
          hasPreviousPage
        }
        totalCount
        items {
          id
          ${columnsResponse}
        }
      }
    }
  `;
}

function getCitiesSelectQuery(columnsResponse?: string): DocumentNode {
  if (!columnsResponse) {
    columnsResponse = `
    cityName
    surname
    state
    country`;
  }

  return gql`
    query Cities($skip: Int!, $take: Int!) {
      cities(skip: $skip, take: $take) {
        pageInfo {
          hasNextPage
          hasPreviousPage
        }
        totalCount
        items {
          id
          ${columnsResponse}
        }
      }
    }
  `;
}

function getCityByIdQuery(columnsResponse?: string): DocumentNode {
  if (!columnsResponse) {
    columnsResponse = `
    cityName
    surname
    state
    country`;
  }

  return gql`
    query Cities($id: ID!) {
      cityById(id: $id) {
        id
        ${columnsResponse}
      }
    }
  `;
}

function getCitiesStatisticsQuery(): DocumentNode {
  return gql`
    query GetCitiesStatistics {
      statistics {
        data {
          name
          value
          description
        }
      }
    }
  `;
}

function createCity(): DocumentNode {
  return gql`
    mutation CreateCity($request: CreateCityRequestInput!) {
      createCity(request: $request) {
        data {
          id
        }
        success
        message
        errorMessage
      }
    }
  `;
}

function updateCity(): DocumentNode {
  const CREATE = gql`
    mutation UpdateCity($id: ID!, $request: UpdateCityRequestInput!) {
      updateCity(id: $id, request: $request) {
        success
        message
        errorMessage
      }
    }
  `;

  return CREATE;
}

function deleteCity(): DocumentNode {
  const CREATE = gql`
    mutation DeleteCity($id: ID!) {
      deleteCity(id: $id) {
        success
        message
        errorMessage
      }
    }
  `;

  return CREATE;
}

export {
  createAdministration, createCity, createLocal, createRegional, createSector, deleteAdministration, deleteCity, deleteLocal, deleteRegional, deleteSector, getAdministrationByIdQuery, getAdministrationQuery,
  getAdministrationsSelectQuery,
  getAdministrationsSelectWithWhereQuery, getAdministrationsStatisticsQuery, getCitiesQuery,
  getCitiesSelectQuery, getCitiesStatisticsQuery, getCityByIdQuery, getLocalByIdQuery, getLocalQuery,
  getLocalsSelectQuery, getLocalsStatisticsQuery, getRegionalByIdQuery, getRegionalQuery,
  getRegionalSelectQuery, getRegionalsStatisticsQuery, getSectorByIdQuery, getSectorQuery,
  getSectorsSelectQuery,
  getSectorsSelectWithWhereQuery, getSectorsStatisticsQuery, updateAdministration, updateCity, updateLocal, updateRegional, updateSector
};

