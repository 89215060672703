import React, { useState } from 'react';
import { Input, InputProps, InputRef } from 'antd';
import style from './TextInput.module.scss';
import classNames from 'classnames';

interface TextInputProps
  extends React.ForwardRefExoticComponent<
    InputProps & React.RefAttributes<InputRef>
  > {
  type?: string | undefined;
  defaultValue?: string | number | readonly string[] | undefined;
  placeholder?: string;
  label?: string;
}

const TextInput: React.FC<TextInputProps> = (props) => {
  let { label, defaultValue, placeholder, type } = props;

  if (!placeholder) placeholder = label;

  return (
    <Input
      type={type}
      defaultValue={defaultValue}
      placeholder={placeholder}
      className={style['input']}
      {...props}
    />
  );
};

export type { TextInputProps };
export { TextInput };
