import { AppRoute } from "../../models/appRoute.model";
import { Login } from "./pages";
import { ForgotPassword } from "./pages/forgot-password/ForgotPassword";
import { Logout } from "./pages/login-redirect/Logout";
import { NotAuthorized } from "./pages/not-authorized/NotAuthorized";
import { ResetPassword } from "./pages/reset-password/ResetPassword";
import { UnregisteredUser } from "./pages/unregistered-user/UnregisteredUser";
import { AuthRoutes } from "./routes.enum";

const getAuthRoutes = (): AppRoute[] => [
  new AppRoute({
    path: AuthRoutes.LOGIN,
    component: <Login />,
    notUseMainLayout: true,
    notPermission: true,
  }),
  new AppRoute({
    path: AuthRoutes.LOGOUT,
    component: <Logout />,
    notUseMainLayout: true,
    notPermission: true,
  }),
  new AppRoute({
    path: AuthRoutes.FORGOT_PASSWORD,
    component: <ForgotPassword />,
    notUseMainLayout: true,
    notPermission: true,
  }),
  new AppRoute({
    path: AuthRoutes.RESET_PASSWORD,
    component: <ResetPassword />,
    notUseMainLayout: true,
    notPermission: true,
  }),
  new AppRoute({
    path: AuthRoutes.NOT_AUTHORIZED,
    component: <NotAuthorized />,
    notUseMainLayout: true,
    notPermission: true,
  }),
  new AppRoute({
    path: AuthRoutes.UNREGISTERED_USER,
    component: <UnregisteredUser />,
    notUseMainLayout: true,
    notPermission: true,
  }),
];

export { getAuthRoutes };

