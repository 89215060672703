import { Button, Form, Modal } from "antd";
import React from "react";
import { UploadField } from "./upload-field/UploadField";
import { UpdateAvatarModalFunctions } from "./UpdateFileModal.functions";
import style from "./UpdateFileModal.module.scss";
import { IAvatarRequest } from "../../../../models/AvatarRequest.interface";

interface UpdateFileModalProps {
  bucketName: string;
  id: string;
  collectionName: string;
  fieldName: string;
  src: string;
  setSrc: React.Dispatch<React.SetStateAction<string | undefined>>;
  onCancel: () => void;
  open: boolean;
  updateFile?: (data: IAvatarRequest, params?: any) => Promise<Response>;
  params?: any;
}

const UpdateFileModal: React.FC<UpdateFileModalProps> = (
  props: UpdateFileModalProps
) => {
  const {
    bucketName,
    id,
    collectionName,
    fieldName,
    src,
    setSrc,
    onCancel,
    open,
    updateFile,
    params,
  } = props;
  const { } = UpdateAvatarModalFunctions(
    updateFile,
    params
  );

  return (
    <Modal
      title={"Atualizar arquivo"}
      onCancel={onCancel}
      open={open}
      centered
      maskClosable={false}
      width={290}
      footer={null}>
      <UploadField
        bucketName={bucketName}
        id={id}
        collectionName={collectionName}
        fieldName={fieldName}
        src={src}
        setSrc={setSrc}
      />
    </Modal>
  );
};

export { UpdateFileModal };
