enum EventsRoutes {
  Events = '/events',
  EventCreate = '/event',
  EventEdit = '/event/:id',
  PendingEventEdit = '/pending-event/:id',
  
  EventTypes = '/eventTypes',
  EventTypeCreate = '/eventType',
  EventTypeEdit = '/eventType/:id',
  
  EventGroups = '/eventGroups',
  EventGroupCreate = '/eventGroup',
  EventGroupEdit = '/eventGroup/:id',
}

export { EventsRoutes };

