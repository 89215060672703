import { DocumentNode, gql } from "@apollo/client";

function getEventGroupQuery(columnsResponse?: string): DocumentNode {
  const whereType = "EventGroupModelFilterInput";
  const orderType = "EventGroupModelSortInput";
  if (!columnsResponse) {
    columnsResponse = `
    name
    date
    regional {
      id
      name
    }`;
  }

  return gql`
    query EventGroups($skip: Int!, $take: Int!, $where: ${whereType}!, $order: [${orderType}!]) {
      eventGroups(skip: $skip, take: $take, where: $where, order: $order) {
        pageInfo {
          hasNextPage
          hasPreviousPage
        }
        totalCount
        items {
          id
          ${columnsResponse}
        }
      }
    }
  `;
}

function getEventGroupByCodeQuery(): DocumentNode {
  return gql`
    query EventGroups($code: String!) {
      eventGroups(skip: 0, take: 1, where: { code: { eq: $code } }, order: {
        date: DESC
      }) {
        pageInfo {
          hasNextPage
          hasPreviousPage
        }
        totalCount
        items {
          id
          code
          name
          date
          start
          end
          description
          regional {
            id
            name
          }
        }
      }
    }
  `;
}

function getEventGroupsSimpleSelectQuery(
  columnsResponse?: string
): DocumentNode {
  if (!columnsResponse) {
    columnsResponse = `
    code
    name
    regional {
      id
      name
    }`;
  }

  return gql`
    query EventGroups($skip: Int!, $take: Int!) {
      eventGroups(skip: $skip, take: $take, order: {
        date: DESC
      }) {
        pageInfo {
          hasNextPage
          hasPreviousPage
        }
        totalCount
        items {
          id
          ${columnsResponse}
        }
      }
    }
  `;
}

function getEventGroupsSelectQuery(columnsResponse?: string): DocumentNode {
  if (!columnsResponse) {
    columnsResponse = `
    code
    name
    date
    start
    end
    description
    regional {
      id
      name
    }`;
  }

  return gql`
    query EventGroups($skip: Int!, $take: Int!) {
      eventGroups(skip: $skip, take: $take, order: {
        date: DESC
      }) {
        pageInfo {
          hasNextPage
          hasPreviousPage
        }
        totalCount
        items {
          id
          ${columnsResponse}
        }
      }
    }
  `;
}

function getEventGroupByIdQuery(columnsResponse?: string): DocumentNode {
  if (!columnsResponse) {
    columnsResponse = `
    name
    date
    start
    end
    description
    regional {
      id
      name
    }`;
  }

  return gql`
    query EventGroups($id: ID!) {
      eventGroupById(id: $id) {
        id
        ${columnsResponse}
      }
    }
  `;
}

function getEventGroupsStatisticsQuery(): DocumentNode {
  return gql`
    query GetEventGroupsStatistics {
      statistics {
        data {
          name
          value
          description
        }
      }
    }
  `;
}

function createEventGroup(): DocumentNode {
  return gql`
    mutation CreateEventGroup($request: CreateEventGroupRequestInput!) {
      createEventGroup(request: $request) {
        data {
          id
        }
        success
        message
        errorMessage
      }
    }
  `;
}

function updateEventGroup(): DocumentNode {
  const CREATE = gql`
    mutation UpdateEventGroup(
      $id: ID!
      $request: UpdateEventGroupRequestInput!
    ) {
      updateEventGroup(id: $id, request: $request) {
        success
        message
        errorMessage
      }
    }
  `;

  return CREATE;
}

function deleteEventGroup(): DocumentNode {
  const CREATE = gql`
    mutation DeleteEventGroup($id: ID!) {
      deleteEventGroup(id: $id) {
        success
        message
        errorMessage
      }
    }
  `;

  return CREATE;
}

function publishEventGroup(): DocumentNode {
  const CREATE = gql`
    mutation PublishEventGroup($id: ID!) {
      published(id: $id) {
        success
        message
        errorMessage
      }
    }
  `;

  return CREATE;
}

function setActualEventGroup(): DocumentNode {
  const CREATE = gql`
    mutation SetActualEventGroup($id: ID!) {
      setActual(id: $id) {
        success
        message
        errorMessage
      }
    }
  `;

  return CREATE;
}

function getEventTypeQuery(columnsResponse?: string): DocumentNode {
  const whereType = "EventTypeModelFilterInput";
  const orderType = "EventTypeModelSortInput";
  if (!columnsResponse) {
    columnsResponse = `
    order
    name`;
  }

  return gql`
    query EventTypes($skip: Int!, $take: Int!, $where: ${whereType}!, $order: [${orderType}!]) {
      eventTypes(skip: $skip, take: $take, where: $where, order: $order) {
        pageInfo {
          hasNextPage
          hasPreviousPage
        }
        totalCount
        items {
          id
          ${columnsResponse}
        }
      }
    }
  `;
}

function getEventTypesSelectQuery(columnsResponse?: string): DocumentNode {
  if (!columnsResponse) {
    columnsResponse = `
    order
    name`;
  }

  return gql`
    query EventTypes($skip: Int!, $take: Int!) {
      eventTypes(skip: $skip, take: $take) {
        pageInfo {
          hasNextPage
          hasPreviousPage
        }
        totalCount
        items {
          id
          ${columnsResponse}
        }
      }
    }
  `;
}

function getEventTypeByIdQuery(columnsResponse?: string): DocumentNode {
  if (!columnsResponse) {
    columnsResponse = `
    order
    name`;
  }

  return gql`
    query EventTypes($id: ID!) {
      eventTypeById(id: $id) {
        id
        ${columnsResponse}
      }
    }
  `;
}

function getEventTypesStatisticsQuery(): DocumentNode {
  return gql`
    query GetEventTypesStatistics {
      statistics {
        data {
          name
          value
          description
        }
      }
    }
  `;
}

function createEventType(): DocumentNode {
  return gql`
    mutation CreateEventType($request: CreateEventTypeRequestInput!) {
      createEventType(request: $request) {
        data {
          id
        }
        success
        message
        errorMessage
      }
    }
  `;
}

function updateEventType(): DocumentNode {
  const CREATE = gql`
    mutation UpdateEventType($id: ID!, $request: UpdateEventTypeRequestInput!) {
      updateEventType(id: $id, request: $request) {
        success
        message
        errorMessage
      }
    }
  `;

  return CREATE;
}

function deleteEventType(): DocumentNode {
  const CREATE = gql`
    mutation DeleteEventType($id: ID!) {
      deleteEventType(id: $id) {
        success
        message
        errorMessage
      }
    }
  `;

  return CREATE;
}

function getEventQuery(columnsResponse?: string): DocumentNode {
  const whereType = "EventModelFilterInput";
  const orderType = "EventModelSortInput";
  if (!columnsResponse) {
    columnsResponse = `
    group {
      id
      name
      parent {
        name
      }
    }
    type {
      order
      name
    }
    date
    hour
    name
    local {
      name
      city {
        cityName
        surname
      }
    }
    people {
      name
    }`;
  }

  return gql`
    query Events($skip: Int!, $take: Int!, $where: ${whereType}!, $order: [${orderType}!]) {
      events(skip: $skip, take: $take, where: $where, order: $order) {
        pageInfo {
          hasNextPage
          hasPreviousPage
        }
        totalCount
        items {
          id
          ${columnsResponse}
        }
      }
    }
  `;
}

function getEventByGroupAndDateQuery(columnsResponse?: string): DocumentNode {
  if (!columnsResponse) {
    columnsResponse = `
    type {
      order
      name
    }
    date
    dateNotFormat
    hour
    name
    local {
      name
      city {
        cityName
        surname
      }
    }
    people {
      name
    }`;
  }

  return gql`
    query EventsByGroupAndDate($request: GetByDateEventRequestInput!) {
      byGroupAndDate(request: $request) {
        id
        ${columnsResponse}
      }
    }
  `;
}

function getEventsSelectQuery(columnsResponse?: string): DocumentNode {
  if (!columnsResponse) {
    columnsResponse = `
    name
    description
    parent {
      id
      name
    }`;
  }

  return gql`
    query Adminisrtations($skip: Int!, $take: Int!) {
      events(skip: $skip, take: $take) {
        pageInfo {
          hasNextPage
          hasPreviousPage
        }
        totalCount
        items {
          id
          ${columnsResponse}
        }
      }
    }
  `;
}

function getEventByIdQuery(columnsResponse?: string): DocumentNode {
  if (!columnsResponse) {
    columnsResponse = `
    group {
      id
      name
    }
    type {
      id
      order
      name
    }
    date
    hour
    name
    local {
      id
      name
    }
    people {
      id
      name
    }
    histories {
      updatedAt
      updatedBy {
        email
         name
         regional {
           name
         }
      }
      detailedCompare {
        propertyName
        before
        after
      }
    }
    `;
  }

  return gql`
    query Events($id: ID!) {
      eventById(id: $id) {
        id
        ${columnsResponse}
      }
    }
  `;
}

function getEventsStatisticsQuery(): DocumentNode {
  return gql`
    query GetEventsStatistics {
      statistics {
        data {
          name
          value
          description
        }
      }
    }
  `;
}

function createEvent(): DocumentNode {
  return gql`
    mutation CreateEvent($request: CreateEventRequestInput!) {
      createEvent(request: $request) {
        data {
          id
        }
        success
        message
        errorMessage
      }
    }
  `;
}

function updateEvent(): DocumentNode {
  const CREATE = gql`
    mutation UpdateEvent($id: ID!, $request: UpdateEventRequestInput!) {
      updateEvent(id: $id, request: $request) {
        success
        message
        errorMessage
      }
    }
  `;

  return CREATE;
}

function deleteEvent(): DocumentNode {
  const CREATE = gql`
    mutation DeleteEvent($id: ID!) {
      deleteEvent(id: $id) {
        success
        message
        errorMessage
      }
    }
  `;

  return CREATE;
}

export {
  createEvent, createEventGroup, createEventType, deleteEvent, deleteEventGroup, deleteEventType, getEventByGroupAndDateQuery, getEventByIdQuery, getEventGroupByCodeQuery, getEventGroupByIdQuery, getEventGroupQuery, getEventGroupsSelectQuery,
  getEventGroupsSimpleSelectQuery, getEventGroupsStatisticsQuery, getEventQuery, getEventsSelectQuery, getEventsStatisticsQuery, getEventTypeByIdQuery, getEventTypeQuery,
  getEventTypesSelectQuery, getEventTypesStatisticsQuery, publishEventGroup, setActualEventGroup, updateEvent, updateEventGroup, updateEventType
};

