import { FaPrint } from "react-icons/fa6";
import { AppRoute } from "../../models/appRoute.model";
import { PrintReports } from "./pages/print-report/PrintReports";
import { ReportsRoutes } from "./routes.enum";

const getReportsRoutes = (): AppRoute[] => [
  new AppRoute({
    path: ReportsRoutes.PrintReports,
    component: <PrintReports />,
    icon: <FaPrint />,
    name: "Impressão",
    permission: ["admin-system", "admin", "user"],
  }),
];

export { getReportsRoutes };

