import { LocalModel, LocalsFunctions } from "./Locals.functions";
import { ProTable } from "../../../../components/table/pro-table/ProTable";

export const Locals = () => {
  const {
    queryName,
    columns,
    columnsMap,
    clientLocal,
    whereDefault,
    variables,
    setVariables,
    toolBarRender,
  } = LocalsFunctions();

  return (
    <>
      <ProTable<LocalModel>
        client={clientLocal}
        queryName={queryName}
        whereType="LocalModelFilterInput"
        whereDefault={whereDefault}
        orderType="LocalModelSortInput"
        variables={variables}
        setVariables={setVariables}
        columns={columns()}
        defaultColumnsMap={columnsMap}
        toolBarRender={toolBarRender}
        onFormatColumnsMap={(columns) => {
          columns = columns?.replace(
            "sectors",
            `sector {
              id
              name
              parent {
                id
                name                
                parent {
                  id
                  name
                }
              }
            }`
          );

          columns = columns?.replace(
            "city",
            `city {
              id
              surname
              cityName
            }`
          );

          return columns;
        }}
      />
    </>
  );
};
