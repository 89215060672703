import React, { useEffect, useState } from "react";
import { Form, FormInstance } from "antd";
import { useMutation, useQuery } from "@apollo/client";
import { useNavigate, useParams } from "react-router-dom";
import { useMain } from "../../../../../../contexts/main";
import { clientLocal, clientPeople } from "../../../../../../services/graphql.service";
import {
  createPeople,
  getPeopleByIdQuery,
  getPeopleRolesSelectQuery,
  updatePeople,
} from "../../../../services/people.service";
import { PeopleRoutes } from "../../../../routes.enum";
import { PeopleRoleModel } from "../../../people-role/PeopleRoles.functions";
import { getLocalsSelectQuery } from "../../../../../locations/services/locations.service";

interface PeoplesGeneralDataFunctionsProps {
  setSurname: React.Dispatch<React.SetStateAction<string | undefined>>;
}

export const PeoplesGeneralDataFunctions = (
  props: PeoplesGeneralDataFunctionsProps
) => {
  const { errorMessage, loading, setLoading } = useMain();
  const { setSurname } = props;
  const { id } = useParams();
  const [edit, setEdit] = useState<boolean>(!id);
  const navigate = useNavigate();

  const [form] = Form.useForm<{
    surname: string;
    name: string;
    email: string;
  }>();
  const formRef = React.useRef<FormInstance>(null);

  const { loading: loadingGet } = useQuery(getPeopleByIdQuery(), {
    client: clientPeople,
    variables: { id },
    fetchPolicy: "no-cache",
    onCompleted: (data) => {
      const people = data.peopleById;
      formRef.current?.setFieldsValue(people);
    },
    errorPolicy: "all",
    onError: ({ graphQLErrors, networkError }) => {
      if (!id) return;

      if (graphQLErrors)
        graphQLErrors.forEach(({ message, locations, path }) =>
          errorMessage(message)
        );
      if (networkError)
        errorMessage("Ocorreu um erro inesperado. Tente novamente mais tarde!");
    },
  });

  const [peopleRoles, setPeopleRoles] = useState<PeopleRoleModel[]>([]);
  const { loading: loadingPeopleRolesGet } = useQuery(
    getPeopleRolesSelectQuery(),
    {
      client: clientPeople,
      fetchPolicy: "no-cache",
      variables: { skip: 0, take: 50 },
      onCompleted: (data) => {
        const { items } = data.peopleRoles;
        if (items) {
          const list = items?.map((_: PeopleRoleModel) => ({
            label: _.name,
            value: _.id,
          }));
          setPeopleRoles(list);
        }
      },
      errorPolicy: "all",
      onError: ({ graphQLErrors, networkError }) => {
        if (!id) return;

        if (graphQLErrors)
          graphQLErrors.forEach(({ message, locations, path }) =>
            errorMessage(message)
          );
        if (networkError)
          errorMessage(
            "Ocorreu um erro inesperado. Tente novamente mais tarde!"
          );
      },
    }
  );

  const [locals, setLocals] = useState<PeopleRoleModel[]>([]);
  const { loading: loadingLocalsGet } = useQuery(getLocalsSelectQuery(), {
    client: clientLocal,
    fetchPolicy: "no-cache",
    variables: { skip: 0, take: 50 },
    onCompleted: (data) => {
      const { items } = data.locals;
      if (items) {
        const list = items?.map((_: PeopleRoleModel) => ({
          label: _.name,
          value: _.id,
        }));
        setLocals(list);
      }
    },
    errorPolicy: "all",
    onError: ({ graphQLErrors, networkError }) => {
      if (!id) return;

      if (graphQLErrors)
        graphQLErrors.forEach(({ message, locations, path }) =>
          errorMessage(message)
        );
      if (networkError)
        errorMessage("Ocorreu um erro inesperado. Tente novamente mais tarde!");
    },
  });

  const surnameValue = Form.useWatch("surname", form);

  useEffect(() => {
    setSurname(surnameValue);
  }, [surnameValue]);

  const [sendUpdatePeopleMutation] = useMutation(updatePeople(), {
    client: clientPeople,
  });
  const [sendCreatePeopleMutation] = useMutation(createPeople(), {
    client: clientPeople,
  });

  const onFinish = (values: any) => {
    setLoading(true);
    const { name, surname, email, localId, roleId } = values;

    if (id) {
      sendUpdatePeopleMutation({
        variables: {
          id,
          request: {
            name,
            surname,
            email,
            localId,
            roleId,
          },
        },
      }).then((result) => {
        if (result.errors) {
          console.log({ errors: result.errors });
          errorMessage("Houve um erro ao realizar a alterar a pessoa.");
          setLoading(false);
          return;
        }

        const { success } = result.data.updatePeople;
        if (!success) {
          errorMessage("Houve um erro ao realizar a alterar a pessoa.");
          setLoading(false);
          return;
        }
        setLoading(false);
        setEdit(false);
      });
    } else {
      sendCreatePeopleMutation({
        variables: {
          request: {
            name,
            surname,
            email,
            localId,
            roleId,
          },
        },
      }).then((result) => {
        if (result.errors) {
          console.log({ errors: result.errors });
          errorMessage("Houve um erro ao realizar a alterar a pessoa.");
          setLoading(false);
          return;
        }

        const { success, data, message } = result.data.createPeople;
        if (!success) {
          errorMessage(
            message ?? "Houve um erro ao realizar a alterar a pessoa."
          );
          setLoading(false);
          return;
        }
        setLoading(false);
        setEdit(false);
        navigate(PeopleRoutes.Peoples);
      });
    }
  };

  return {
    form,
    formRef,
    onFinish,
    loading,
    loadingGet,
    edit,
    setEdit,
    peopleRoles,
    loadingPeopleRolesGet,
    locals,
    loadingLocalsGet,
  };
};
