
class LocalStorageService {
  public static localStorageToken: string = "mc-token";
  public static localStoragePermissions: string = "permissions";
  public static localStorageClaims: string = "claims";

  public static getLocalStorage = (name: string): string | undefined => {
    return localStorage.getItem(name) || "";
  };

  public static getLocalStorageJSON = <T,>(name: string): T | null => {
    const result = localStorage.getItem(name) || "";

    if (!result || result === "") {
      return null;
    }

    if (typeof result === "object") {
      return result as T;
    }

    const resultType = JSON.parse(result) as T;
    return resultType;
  };

  public static setLocalStorage = (name: string, value: string): void => {
    localStorage.setItem(name, value);
  };

  public static setLocalStorageJSON = (name: string, value: any): void =>
    LocalStorageService.setLocalStorage(
      name,
      JSON.stringify(value)
    );

  public static removeLocalStorage = (name: string): void =>
    localStorage.removeItem(name);

  public static removeAllLocalStorage = (): void => localStorage.clear();
}

export { LocalStorageService };

