import React, { useEffect, useState } from "react";
import { useMain } from "../../../../../contexts/main";
import { useNavigate, useParams } from "react-router-dom";
import { clientLocal } from "../../../../../services/graphql.service";
import { useMutation, useQuery } from "@apollo/client";
import {
  createCity,
  getCityByIdQuery,
  updateCity,
} from "../../../services/locations.service";
import { Form, FormInstance } from "antd";
import { LocationsRoutes } from "../../../routes.enum";

export const CityCreateFunctions = () => {
  const { errorMessage, loading, setLoading, setTitlePage } = useMain();
  const { handleChangeBreadcumb } = useMain();
  const { id } = useParams();
  const navigate = useNavigate();
  const title = !id ? "Nova cidade" : "Alterar a cidade";

  useEffect(() => {
    setLoading(false);
    handleChangeBreadcumb([
      {
        title: "Home",
        path: "/",
      },
      {
        title: "Cidades",
        path: LocationsRoutes.Cities,
      },
      {
        title: title,
      },
    ]);
    setTitlePage(title);
  }, [id]);

  const [form] = Form.useForm<{
    name: string;
    description: string;
    required: boolean;
    price: string;
  }>();
  const formRef = React.useRef<FormInstance>(null);

  const { loading: loadingGet } = useQuery(getCityByIdQuery(), {
    client: clientLocal,
    variables: { id },
    fetchPolicy: "no-cache",
    onCompleted: (data) => {
      const user = data.cityById;
      formRef.current?.setFieldsValue(user);
    },
    errorPolicy: "all",
    onError: ({ graphQLErrors, networkError }) => {
      if (!id) return;

      if (graphQLErrors)
        graphQLErrors.forEach(({ message, locations, path }) =>
          errorMessage(message)
        );
      if (networkError)
        errorMessage("Ocorreu um erro inesperado. Tente novamente mais tarde!");
    },
  });

  const [sendUpdateUserMutation] = useMutation(updateCity(), {
    client: clientLocal,
  });
  const [sendCreateUserMutation] = useMutation(createCity(), {
    client: clientLocal,
  });

  const onFinish = (values: any) => {
    setLoading(true);
    const { cityName, surname, state, country } = values;

    if (id) {
      sendUpdateUserMutation({
        variables: {
          id,
          request: {
            cityName,
            surname,
            state,
            country,
          },
        },
      }).then((result) => {
        if (result.errors) {
          console.log({ errors: result.errors });
          errorMessage("Houve um erro ao alterar a cidade.");
          setLoading(false);
          return;
        }

        const { success } = result.data.updateCity;
        if (!success) {
          errorMessage("Houve um erro ao alterar a cidade.");
          setLoading(false);
          return;
        }

        setLoading(false);
        navigate(LocationsRoutes.Cities);
      });
    } else {
      sendCreateUserMutation({
        variables: {
          request: {
            cityName,
            surname,
            state,
            country,
          },
        },
      }).then((result) => {
        if (result.errors) {
          console.log({ errors: result.errors });
          errorMessage("Houve um erro ao alterar a cidade.");
          setLoading(false);
          return;
        }

        const { success, message } = result.data.createCity;
        if (!success) {
          errorMessage(message ?? "Houve um erro ao alterar a cidade.");
          setLoading(false);
          return;
        }

        setLoading(false);
        navigate(LocationsRoutes.Cities);
      });
    }
  };

  return { id, title, form, formRef, onFinish, loadingGet, loading };
};
