import {
  Button,
  Card,
  Col,
  DatePicker,
  Form,
  Input,
  List,
  Row,
  Select,
  Skeleton,
  Tag,
  Timeline,
  Typography
} from "antd";
import dayjs from "dayjs";
import { EventCreateFunctions } from "./EventForm.functions";
import style from "./EventForm.module.scss";

export const EventForm = () => {
  const {
    title,
    form,
    formRef,
    onFinish,
    loadingGet,
    loading,
    eventGroups,
    loadingEventGroupsGet,
    eventTypes,
    loadingEventTypessGet,
    locals,
    loadingLocalsGet,
    peoples,
    loadingPeoplesGet,
    isPending,
    histories,
  } = EventCreateFunctions();

  return (
    <Row gutter={[16, 16]}>
      <Col span={24}>
        <Card className={style["form-card"]}>
          <Skeleton loading={loadingGet} active />
          <Form
            form={form}
            ref={formRef}
            hidden={loadingGet}
            className={style["form"]}
            name="user-form"
            layout="vertical"
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
            onFinish={onFinish}
            autoComplete="off">
            <Form.Item>
              <Row gutter={[16, 16]}>
                <Col span={24} lg={10}>
                  <Form.Item
                    label="Reunião"
                    name="groupId"
                    rules={[
                      { required: true, message: "O campo reunião é obrigatório!" },
                    ]}>
                    <Select
                      showSearch
                      allowClear
                      filterOption={(input, option) =>
                        (option?.label ?? "")
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                      options={eventGroups}
                      loading={loadingEventGroupsGet}
                      style={{
                        width: "100%",
                      }}
                      disabled={isPending}
                    />
                  </Form.Item>
                </Col>
                <Col span={24} lg={14}>
                  <Form.Item
                    label="Serviço"
                    name="typeId"
                    rules={[
                      { required: true, message: "O campo serviço é obrigatório!" },
                    ]}>
                    <Select
                      showSearch
                      allowClear
                      filterOption={(input, option) =>
                        (option?.label ?? "")
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                      filterSort={(optionA, optionB) =>
                        (optionA?.label ?? "")
                          .toLowerCase()
                          .localeCompare((optionB?.label ?? "").toLowerCase())
                      }
                      options={eventTypes}
                      loading={loadingEventTypessGet}
                      style={{
                        width: "100%",
                      }}
                      disabled={isPending}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Form.Item>
            <Form.Item>
              <Row gutter={[16, 16]}>
                <Col span={24} lg={10}>
                  <Form.Item
                    label="Congregação"
                    name="localId"
                    rules={[
                      {
                        required: true,
                        message: "O campo congregação é obrigatório!",
                      },
                    ]}>
                    <Select
                      showSearch
                      allowClear
                      filterOption={(input, option) =>
                        (option?.label ?? "")
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                      filterSort={(optionA, optionB) =>
                        (optionA?.label ?? "")
                          .toLowerCase()
                          .localeCompare((optionB?.label ?? "").toLowerCase())
                      }
                      options={locals}
                      loading={loadingLocalsGet}
                      style={{
                        width: "100%",
                      }}
                      disabled={isPending}
                    />
                  </Form.Item>
                </Col>
                <Col span={24} lg={14}>
                  <Form.Item label="Nome na impressão" name="name">
                    <Input disabled={isPending} />
                  </Form.Item>
                </Col>
              </Row>
            </Form.Item>
            <Form.Item>
              <Row gutter={[16, 16]}>
                <Col span={24} lg={5}>
                  <Form.Item
                    label="Data"
                    name="date"
                    rules={[
                      { required: true, message: "O campo data é obrigatório!" },
                    ]}>
                    <DatePicker
                      style={{
                        width: "100%",
                      }}
                      format={"DD/MM/YYYY"}
                    />
                  </Form.Item>
                </Col>
                <Col span={24} lg={5}>
                  <Form.Item
                    label="Hora"
                    name="hour"
                    rules={[
                      { required: true, message: "O campo hora é obrigatório!" },
                    ]}>
                    <Input
                      style={{
                        width: "100%",
                      }}
                      type={"time"}
                    />
                  </Form.Item>
                </Col>
                <Col span={24} lg={14}>
                  <Form.Item label="Ancião" name="peopleId">
                    <Select
                      showSearch
                      allowClear
                      filterOption={(input, option) =>
                        (option?.label ?? "")
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                      filterSort={(optionA, optionB) =>
                        (optionA?.label ?? "")
                          .toLowerCase()
                          .localeCompare((optionB?.label ?? "").toLowerCase())
                      }
                      options={peoples}
                      loading={loadingPeoplesGet}
                      style={{
                        width: "100%",
                      }}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Form.Item>

            <Form.Item
              className={style["button-save"]}
              wrapperCol={{ span: 16 }}>
              <Button type="primary" htmlType="submit" loading={loading}>
                Salvar
              </Button>
            </Form.Item>
          </Form>
        </Card>
      </Col>
      {histories && histories?.length > 0 && (
        <Col span={24}>
          <Card title={<Tag>Histórico</Tag>}>
            <Timeline
              items={histories?.map((item) => ({
                children: (
                  <Row gutter={[16, 16]}>
                    <Col span={24}>
                      <Typography.Text style={{ opacity: 0.6 }}>
                        {item.updatedBy.name} alterou o evento em{" "}
                        {dayjs(new Date(item.updatedAt)).format(
                          "DD/MM/YYYY, HH:mm:ss"
                        )}
                      </Typography.Text>
                    </Col>
                    <Col span={24}>
                      <List
                        dataSource={item.detailedCompare}
                        renderItem={(detail) => (
                          <List.Item>
                            <List.Item.Meta
                              title={detail.propertyName}
                              description={
                                <Typography.Text>
                                  <Typography.Text
                                    style={{ fontWeight: "200", opacity: 0.8 }}>
                                    De{" "}
                                  </Typography.Text>
                                  <Typography.Text strong>
                                    {detail.before}
                                  </Typography.Text>
                                  <Typography.Text
                                    style={{ fontWeight: "200", opacity: 0.8 }}>
                                    , para{" "}
                                  </Typography.Text>
                                  <Typography.Text strong>
                                    {detail.after}
                                  </Typography.Text>
                                </Typography.Text>
                              }
                            />
                          </List.Item>
                        )}
                      />
                    </Col>
                  </Row>
                ),
              }))}
            />
          </Card>
        </Col>
      )}
    </Row>
  );
};
