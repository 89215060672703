import React from 'react';
import { Form, FormItemProps } from 'antd';
import { TextInput } from '../../fields/inputs/text/TextInput';
import styles from './TextField.module.scss';

interface TextFieldProps extends FormItemProps {
  inputProps?: any;
}

const TextField: React.FC<TextFieldProps> = (props) => {
  const { inputProps } = props;
  const _props = { ...props };
  delete _props.inputProps;

  return (
    <Form.Item {..._props} className={styles['field']}>
      <TextInput {...inputProps} />
    </Form.Item>
  );
};

export { TextField };
