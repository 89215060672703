import { useState, useEffect } from "react";
import type { ColumnsState, ProColumns } from "@ant-design/pro-components";
import { Avatar, Button, Modal, Space, Tag, Tooltip } from "antd";
import { FaLink, FaPencil, FaTrash } from "react-icons/fa6";
import { MdDelete, MdEdit, MdLink } from "react-icons/md";
import { useNavigate, useNavigation } from "react-router-dom";
import { ExclamationCircleFilled, PlusOutlined } from "@ant-design/icons";
import { useMutation } from "@apollo/client";
import { clientLocal } from "../../../../services/graphql.service";
import { useMain } from "../../../../contexts/main";
import { LocationsRoutes } from "../../routes.enum";
import { deleteRegional } from "../../services/locations.service";
import { useAuth } from "../../../../contexts/auth";
import { ProColumnsProps } from "../../../../components/table/pro-table/ProTable";

export type RegionalModel = {
  id: string;
  name: string;
  description: string;
};

export interface Response<T> {
  nodes: T[];
  pageInfo: {
    hasNextPage: boolean;
    hasPreviousPage: boolean;
  };
  totalCount: number;
}

enum Sort {
  ASC = "ASC",
  DESC = "DESC",
}

const { confirm } = Modal;

export const RegionalsFunctions = () => {
  const navigate = useNavigate();
  const { errorMessage, handleChangeBreadcumb, setTitlePage } = useMain();
  const { hasPermission } = useAuth();
  const title = "Regionais";

  useEffect(() => {
    handleChangeBreadcumb([
      {
        title: "Home",
        path: "/",
      },
      {
        title: "Regionais",
      },
    ]);
    setTitlePage(title);
  }, []);

  const onClickCreate = () => {
    navigate(LocationsRoutes.RegionalCreate);
  };

  const onClickEdit = (id: string) => {
    const url = LocationsRoutes.RegionalEdit.replace(":id", id);
    navigate(url);
  };

  const [deleteMutation] = useMutation(deleteRegional(), {
    client: clientLocal,
    errorPolicy: "all",
    onCompleted: () => {
      setVariables({ ...variables });
    },
    onError: ({ graphQLErrors, networkError }) => {
      if (graphQLErrors)
        graphQLErrors.forEach(({ message, locations, path }) =>
          errorMessage(message)
        );
      if (networkError)
        errorMessage("Ocorreu um erro inesperado. Tente novamente mais tarde!");
    },
  });

  const onClickDelete = (id: string) => {
    return deleteMutation({
      variables: {
        id,
      },
    });
  };

  const toolBarRender = () => [
    hasPermission(["admin", "admin-system"]) && (
      <Button
        key="button"
        icon={<PlusOutlined />}
        onClick={onClickCreate}
        type="primary">
        Novo
      </Button>
    ),
  ];

  const columns = (): ProColumnsProps<RegionalModel>[] => [
    {
      title: "Nome",
      dataIndex: "name",
      filters: true,
      onFilter: true,
      ellipsis: true,
      sorter: true,
    },
    {
      title: "Descrição",
      dataIndex: "description",
      filters: true,
      onFilter: true,
      ellipsis: true,
      sorter: true,
    },
    {
      title: "Ações",
      key: "actions",
      search: false,
      width: 180,
      align: "center",
      permission: ["admin", "admin-system"],
      render: (_, record) => [
        <Space>
          <Tooltip title="Editar regional">
            <Button
              type="link"
              shape="circle"
              icon={<MdEdit />}
              onClick={() => onClickEdit(record.id)}
            />
          </Tooltip>
          <Tooltip title="Deletar regional">
            <Button
              type="link"
              shape="circle"
              icon={<MdDelete />}
              onClick={() => showDeleteConfirm(record.id, record.name)}
            />
          </Tooltip>
        </Space>,
      ],
    },
  ];

  const whereDefault = {
    name: {
      contains: "",
    },
  };

  const [variables, setVariables] = useState({
    skip: 0,
    take: 10,
    where: whereDefault,
    order: { name: Sort.ASC },
  });

  const columnsMap = {
    name: {
      disable: true,
      show: true,
    },
    description: {
      show: true,
    },
  } as Record<string, ColumnsState>;

  const showDeleteConfirm = (id: string, name: string) => {
    confirm({
      title: `Deseja deletar a regional: ${name}?`,
      icon: <ExclamationCircleFilled />,
      content: "Você realmente deseja deletar este item?",
      okText: "Sim",
      okButtonProps: {
        style: { background: "var(--primary-color)" },
      },
      cancelText: "Não",
      onOk() {
        return onClickDelete(id);
      },
      onCancel() {},
    });
  };

  return {
    columns,
    columnsMap,
    clientLocal,
    whereDefault,
    variables,
    setVariables,
    toolBarRender,
  };
};
