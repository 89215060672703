import { Col, Divider, Form, Row } from "antd";
import { FcGoogle } from "react-icons/fc";
import { Button, PasswordField, TextField } from "../../../../../components";
import { LoginFormFunctions } from "./LoginForm.functions";
import style from "./LoginForm.module.scss";

export const LoginForm = () => {
  const {
    validateMessages,
    form,
    passwordVisible,
    setPasswordVisible,
    handleForgotPassword,
    loading,
    login,
    loginWithGoogle,
  } = LoginFormFunctions();

  return (
    <Col xs={24} className={style.content}>
      <img src="/images/full-logo.svg" className={style.logo} />
      <Form
        name="login"
        layout="vertical"
        initialValues={{ remember: true }}
        autoComplete="off"
        validateMessages={validateMessages}
        form={form}
        className={style.form}
        onFinish={login}
      >
        <TextField
          name="userName"
          label="Usuário"
          rules={[{ required: true }]}
        />
        <PasswordField
          name="password"
          label="Senha"
          passwordVisible={passwordVisible}
          setPasswordVisible={setPasswordVisible}
          rules={[{ required: true }]}
        />
        <a className={style["forgot-password"]} onClick={handleForgotPassword}>
          Esqueci minha senha
        </a>
        <Row>
          <Col span={24}>
            <Row justify={"center"}>
              <Button
                loading={loading}
                type="primary"
                className={style.button}
                htmlType="submit"
              >
                Entrar
              </Button>
            </Row>
          </Col>
          <Col span={24}>
            <Row justify={"center"}>
              <Divider />
            </Row>
          </Col>
          <Col span={24}>
            <Row justify={"center"}>
              <Button
                icon={<FcGoogle size={22} />}
                ghost
                onClick={loginWithGoogle}
              >
                Logar com o Google
              </Button>
            </Row>
          </Col>
        </Row>
      </Form>
    </Col>
  );
};
