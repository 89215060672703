import { MdAccountBox, MdLocationCity } from "react-icons/md";
import { GiBrazil, GiModernCity } from "react-icons/gi";
import { AppRoute } from "../../models/appRoute.model";
import { LocationsRoutes } from "./routes.enum";
import { Regionals } from "./pages/regional/Regionals";
import { RegionalForm } from "./pages/regional/regional-form/RegionalForm";
import { Administrations } from "./pages/administration/Administrations";
import { AdministrationForm } from "./pages/administration/administration-form/AdministrationForm";
import { Sectors } from "./pages/sector/Sectors";
import { SectorForm } from "./pages/sector/sector-form/SectorForm";
import { Locals } from "./pages/local/Locals";
import { LocalForm } from "./pages/local/local-form/LocalForm";
import { FaMapLocationDot, FaPeopleRoof } from "react-icons/fa6";
import { Cities } from "./pages/city/Cities";
import { CityForm } from "./pages/city/city-form/CityForm";

const getLocationsRoutes = (): AppRoute[] => [
  new AppRoute({
    path: LocationsRoutes.Cities,
    component: <Cities />,
    icon: <GiModernCity />,
    name: "Cidades",
    permission: ["admin-system", "admin"],
  }),
  new AppRoute({
    path: LocationsRoutes.CityCreate,
    component: <CityForm />,
    permission: ["admin-system", "admin"],
  }),
  new AppRoute({
    path: LocationsRoutes.CityEdit,
    component: <CityForm />,
    permission: ["admin-system", "admin"],
  }),
  new AppRoute({
    path: LocationsRoutes.Regionals,
    component: <Regionals />,
    icon: <GiBrazil />,
    name: "Regionais",
    permission: ["admin-system", "admin"],
  }),
  new AppRoute({
    path: LocationsRoutes.RegionalCreate,
    component: <RegionalForm />,
    permission: ["admin-system", "admin"],
  }),
  new AppRoute({
    path: LocationsRoutes.RegionalEdit,
    component: <RegionalForm />,
    permission: ["admin-system", "admin"],
  }),
  new AppRoute({
    path: LocationsRoutes.Administrations,
    component: <Administrations />,
    icon: <MdLocationCity />,
    name: "Administrações",
    permission: ["admin-system", "admin"],
  }),
  new AppRoute({
    path: LocationsRoutes.AdministrationCreate,
    component: <AdministrationForm />,
    permission: ["admin-system", "admin"],
  }),
  new AppRoute({
    path: LocationsRoutes.AdministrationEdit,
    component: <AdministrationForm />,
    permission: ["admin-system", "admin"],
  }),
  new AppRoute({
    path: LocationsRoutes.Sectors,
    component: <Sectors />,
    icon: <FaMapLocationDot />,
    name: "Setores",
    permission: ["admin-system", "admin"],
  }),
  new AppRoute({
    path: LocationsRoutes.SectorCreate,
    component: <SectorForm />,
    permission: ["admin-system", "admin"],
  }),
  new AppRoute({
    path: LocationsRoutes.SectorEdit,
    component: <SectorForm />,
    permission: ["admin-system", "admin"],
  }),
  new AppRoute({
    path: LocationsRoutes.Locals,
    component: <Locals />,
    icon: <FaPeopleRoof />,
    name: "Casas de oração",
    permission: ["admin-system", "admin"],
  }),
  new AppRoute({
    path: LocationsRoutes.LocalCreate,
    component: <LocalForm />,
    permission: ["admin-system", "admin"],
  }),
  new AppRoute({
    path: LocationsRoutes.LocalEdit,
    component: <LocalForm />,
    permission: ["admin-system", "admin"],
  }),
];

export { getLocationsRoutes };
