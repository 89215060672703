import {
  Button,
  Card,
  Col,
  DatePicker,
  Form,
  Input,
  Row,
  Select,
  Skeleton,
} from "antd";
import { EventGroupCreateFunctions } from "./EventGroupForm.functions";
import style from "./EventGroupForm.module.scss";

import { CkEditorFull } from "../../../../../components/editor/ckeditor/Ckeditor";

export const EventGroupForm = () => {
  const {
    title,
    form,
    formRef,
    onFinish,
    loadingGet,
    loading,
    regionals,
    loadingRegionalsGet,
    description,
    setDescription,
  } = EventGroupCreateFunctions();

  return (
    <Card className={style["form-card"]}>
      <Skeleton loading={loadingGet} active />
      <Form
        form={form}
        ref={formRef}
        hidden={loadingGet}
        className={style["form"]}
        name="user-form"
        layout="vertical"
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
        onFinish={onFinish}
        autoComplete="off"
      >
        <Form.Item>
          <Row gutter={[16, 16]}>
            <Col span={24} lg={6}>
              <Form.Item
                label="Nome"
                name="name"
                rules={[
                  { required: true, message: "O nome é obrigatório!" },
                  {
                    max: 50,
                    message: "O nome pode ter no máximo 30 caracteres!",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={24} lg={6}>
              <Form.Item
                label="Regional"
                name="regionalId"
                rules={[
                  { required: true, message: "A regional é obrigatório!" },
                ]}
              >
                <Select
                  options={regionals}
                  loading={loadingRegionalsGet}
                  style={{
                    width: "100%",
                  }}
                />
              </Form.Item>
            </Col>
          </Row>
        </Form.Item>

        <Form.Item>
          <Row gutter={[16, 16]}>
            <Col span={24} lg={4}>
              <Form.Item
                label="Data"
                name="date"
                rules={[{ required: true, message: "A data é obrigatório!" }]}
              >
                <DatePicker style={{ width: "100%" }} format={"DD/MM/YYYY"} />
              </Form.Item>
            </Col>
            <Col span={24} lg={4}>
              <Form.Item
                label="Data inicial para impressão"
                name="start"
                rules={[
                  {
                    required: true,
                    message: "A data inicial para impressão é obrigatório!",
                  },
                ]}
              >
                <DatePicker style={{ width: "100%" }} format={"DD/MM/YYYY"} />
              </Form.Item>
            </Col>
            <Col span={24} lg={4}>
              <Form.Item
                label="Data final para impressão"
                name="end"
                rules={[
                  {
                    required: true,
                    message: "A data final para impressão é obrigatório!",
                  },
                ]}
              >
                <DatePicker style={{ width: "100%" }} format={"DD/MM/YYYY"} />
              </Form.Item>
            </Col>
          </Row>
        </Form.Item>

        <Form.Item>
          <CkEditorFull
            data={description ?? "<p></p>"}
            onChange={(_event, editor) => {
              setDescription(editor.getData());
            }}
          />
        </Form.Item>

        <Form.Item className={style["button-save"]} wrapperCol={{ span: 16 }}>
          <Button type="primary" htmlType="submit" loading={loading}>
            Salvar
          </Button>
        </Form.Item>
      </Form>
    </Card>
  );
};
