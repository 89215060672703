import { useMain } from "../../../../../contexts/main";
import { useUploadFiles } from "../../../../../hooks/useUploadFiles";
import { UploadFileField } from "../../../../data-entry/form-fields/upload-file-field";
import style from "./UploadField.module.scss";

interface UploadFieldProps {
  bucketName: string;
  id: string;
  collectionName: string;
  fieldName: string;
  src: string;
  setSrc: React.Dispatch<React.SetStateAction<string | undefined>>;
}

const UploadField = (props: UploadFieldProps) => {
  const { bucketName, id, collectionName, fieldName, src, setSrc } = props;
  const { loading } = useMain();
  const { handleUploadS3 } = useUploadFiles();

  const customRequest = (customRequestOptions: any) => {
    handleUploadS3(
      bucketName,
      id,
      collectionName,
      fieldName,
      customRequestOptions,
      "avatar"
    );
    return {
      abort() {},
    };
  };

  return (
    <div className={style.box}>
      {!loading && (
        <>
          <UploadFileField
            name="avatar"
            uploadProps={{
              maxCount: 1,
              customRequest,
              defaultFileList: [
                {
                  url: src ?? "https://auth-dev.eversys.io/images/logo.png",
                  uid: "",
                  name: "image.png",
                  status: "done",
                },
              ],
              beforeUpload(file) {
                return new Promise((resolve) => {
                  const reader = new FileReader();
                  reader.readAsDataURL(file);
                  reader.onload = () => {
                    const img = document.createElement("img");
                    img.src = reader.result as string;
                    setSrc(reader.result as string);
                    img.onload = () => {
                      const canvas = document.createElement("canvas");
                      canvas.width = img.naturalWidth;
                      canvas.height = img.naturalHeight;
                      const ctx = canvas.getContext("2d")!;
                      ctx.drawImage(img, 0, 0);
                      canvas.toBlob((result) => resolve(result as any));
                    };
                  };
                });
              },
            }}
          />
        </>
      )}
    </div>
  );
};

export { UploadField };

