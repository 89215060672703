import { BlogModel, BlogsFunctions } from "./Blogs.functions";
import { ProTable } from "../../../../components/table/pro-table/ProTable";

export const Blogs = () => {
  const {
    columns,
    columnsMap,
    clientBlog,
    whereDefault,
    variables,
    setVariables,
    toolBarRender,
  } = BlogsFunctions();

  return (
    <>
      <ProTable<BlogModel>
        client={clientBlog}
        queryName="blog"
        whereType="BlogModelFilterInput"
        whereDefault={whereDefault}
        orderType="BlogModelSortInput"
        variables={variables}
        setVariables={setVariables}
        columns={columns()}
        defaultColumnsMap={columnsMap}
        toolBarRender={toolBarRender}
        onFormatColumnsMap={(columns) => {
          columns = columns?.replace(
            "category",
            `category {
              id
              title
            }`
          );

          return columns;
        }}
      />
    </>
  );
};
