import {
  Avatar,
  Button,
  Card,
  Checkbox,
  Col,
  Form,
  Input,
  InputNumber,
  Row,
  Select,
  Skeleton,
  Space,
} from "antd";
import { SectorCreateFunctions } from "./SectorForm.functions";
import style from "./SectorForm.module.scss";

export const SectorForm = () => {
  const { title, form, formRef, onFinish, loadingGet, loading, regionals, loadingRegionalsGet } =
  SectorCreateFunctions();

  return (
    <Card className={style["form-card"]}>
      <Skeleton loading={loadingGet} active />
      <Form
        form={form}
        ref={formRef}
        hidden={loadingGet}
        className={style["form"]}
        name="user-form"
        layout="vertical"
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
        onFinish={onFinish}
        autoComplete="off">
        <Form.Item>
          <Row gutter={[16, 16]}>
            <Col span={24} lg={16}>
              <Form.Item
                label="Nome"
                name="name"
                rules={[
                  { required: true, message: "O nome é obrigatório!" },
                  {
                    max: 50,
                    message: "O nome pode ter no máximo 30 caracteres!",
                  },
                ]}>
                <Input />
              </Form.Item>
            </Col>
            <Col span={24} lg={8}>
              <Form.Item
                label="Administração"
                name="administrationId"
                rules={[{ required: true, message: "A administração é obrigatório!" }]}>
                <Select
                  options={regionals}
                  loading={loadingRegionalsGet}
                  style={{
                    width: "100%",
                  }}
                />
              </Form.Item>
            </Col>
          </Row>
        </Form.Item>

        <Form.Item label="Descrição" name="description">
          <Input.TextArea />
        </Form.Item>
        
        <Form.Item className={style["button-save"]} wrapperCol={{ span: 16 }}>
          <Button type="primary" htmlType="submit" loading={loading}>
            Salvar
          </Button>
        </Form.Item>
      </Form>
    </Card>
  );
};
