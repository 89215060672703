import { DocumentNode, gql } from "@apollo/client";

function getPendingEventsQuery(): DocumentNode {
  return gql`
    query ListPendingEvents($skip: Int!, $take: Int!, $where: EventModelFilterInput!) {
  events(
    skip: $skip
    take: $take
    where: $where
    order: [{type: {order: ASC}}, {group: {name: ASC}}, {type: {name: ASC}}, {date: ASC}]
  ) {
    totalCount
    items {
      id
      name
      dateNotFormat
      date
      status
      hour
      group {
        name
        parent {
          name
        }
      }
      type {
        order
        name
      }
      local {
        name
        city {
          surname
          cityName
        }
      }
      people {
        name
      }
    }
  }
}
  `;
}

function getPendingBySectorStatisticsAsync(): DocumentNode {
  return gql`
    query GetPendingBySectorStatisticsAsync(
      $groupId: String!
      $sectorsId: [String!]
    ) {
      pendingBySectorStatistics(groupId: $groupId, sectorsId: $sectorsId) {
        data {
          name
          group
          value
        }
        success
        message
      }
    }
  `;
}

function getPendingStatisticsAsync(): DocumentNode {
  return gql`
    query GetPendingStatisticsAsync($groupId: String!, $sectorsId: [String!]) {
      pendingStatistics(groupId: $groupId, sectorsId: $sectorsId) {
        data {
          name
          value
        }
        success
        message
      }
    }
  `;
}

function pendingConfirmStatistics(): DocumentNode {
  return gql`
    query GetPendingConfirmStatisticsAsync($groupId: String!, $sectorsId: [String!]) {
      pendingConfirmStatistics(groupId: $groupId, sectorsId: $sectorsId) {
        data {
          name
          value
        }
        success
        message
      }
    }
  `;
}

function updateStatusEvent(): DocumentNode {
  const CREATE = gql`
    mutation UpdateStatusEvent($id: ID!, $status: EventStatusEnum!) {
      updateStatusEvent(id: $id, status: $status) {
        success
        message
        errorMessage
      }
    }
  `;

  return CREATE;
}

export {
  getPendingBySectorStatisticsAsync, getPendingEventsQuery, getPendingStatisticsAsync,
  pendingConfirmStatistics, updateStatusEvent
};

