import type {
  ActionType,
  ColumnsState,
  ProColumns,
} from "@ant-design/pro-components";
import { ProTable as ProTableAntd } from "@ant-design/pro-components";
import { ApolloClient, NormalizedCacheObject } from "@apollo/client";
import React from "react";
import { ProTableFunctions } from "./ProTable.functions";
import styles from "./ProTable.module.scss";

export type ProColumnsProps<T = any, ValueType = "text"> =
  | ProColumns<T, ValueType>
  | {
      permission: string[];
    };

export interface ProTableProps<T> {
  headerTitle?: string;
  client: ApolloClient<NormalizedCacheObject>;
  queryName: string;
  whereType: string;
  whereDefault: {};
  orderDefault?: {};
  orderType: string;
  columns: ProColumnsProps<T>[];
  defaultColumnsMap: Record<string, ColumnsState>;
  variables: React.SetStateAction<any>;
  setVariables: React.Dispatch<React.SetStateAction<any>>;
  onFormatColumnsMap?: (columns: string | undefined) => string | undefined;
  toolBarRender?:
    | false
    | ((
        action: ActionType | undefined,
        rows: {
          selectedRowKeys?: (string | number)[] | undefined;
          selectedRows?: T[] | undefined;
        }
      ) => React.ReactNode[])
    | undefined;
}

const ProTable = <T extends {}>(props: ProTableProps<T>): JSX.Element => {
  const {
    headerTitle,
    columns,
    defaultColumnsMap,
    setVariables,
    variables,
    client,
    queryName,
    whereType,
    whereDefault,
    orderDefault,
    orderType,
    onFormatColumnsMap,
    toolBarRender,
  } = props;
  const { request, setColumnsMap, actionRef, hasPermission } =
    ProTableFunctions({
      client,
      variables,
      setVariables,
      queryName,
      whereType,
      whereDefault,
      orderDefault,
      orderType,
      columns,
      defaultColumnsMap,
      onFormatColumnsMap,
    });

  return (
    <ProTableAntd<T>
      columns={
        columns.filter(
          (column: any) => !column.permission || hasPermission(column.permission)
        ) as ProColumns<T>[]
      }
      actionRef={actionRef}
      sticky
      className={styles["root-table"]}
      cardBordered
      showSorterTooltip
      request={request}
      columnsState={{
        defaultValue: defaultColumnsMap,
        onChange(value) {
          var columns = [];

          for (var column in value) {
            if (value[column].show) {
              columns.push(column);
            }
          }
          setColumnsMap(columns);
        },
      }}
      rowKey="id"
      search={{
        labelWidth: "auto",
      }}
      options={{
        setting: {
          listsHeight: 400,
        },
      }}
      pagination={{
        pageSize: variables?.take ?? 10,
        onShowSizeChange(current, size) {
          setVariables((actual: any) => ({
            ...actual,
            take: size,
            skip: current - 1,
          }));
        },
        pageSizeOptions: [5, 10, 20, 30, 50],
        showSizeChanger: true,
      }}
      dateFormatter="string"
      headerTitle={headerTitle}
      toolBarRender={toolBarRender}
    />
  );
};

export { ProTable };

