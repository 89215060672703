import React, { useState } from "react";

import {
  FormItemProps,
  Upload,
  Form,
  UploadProps,
  UploadFile,
  Modal,
} from "antd";
import ImgCrop, { ImgCropProps } from "antd-img-crop";
import { RcFile } from "antd/es/upload";
import style from "./UploadFileField.module.scss";

type ImgCropUploadProps = {
  uploadProps: UploadProps;
};

interface IUploadFileFieldProps extends FormItemProps, ImgCropUploadProps {}

const getBase64 = (file: RcFile): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = (error) => reject(error);
  });

const defaultFileList = [
  {
    uid: "-1",
    name: "image.png",
    status: "done",
    url: "https://auth-dev.eversys.io/images/logo.png",
  },
] as any;

const UploadFileField = (props: IUploadFileFieldProps) => {
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [previewTitle, setPreviewTitle] = useState("");

  const handlePreview = async (file: UploadFile) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj as RcFile);
    }

    setPreviewImage(file.url || (file.preview as string));
    setPreviewOpen(true);
    setPreviewTitle(
      file.name || file.url!.substring(file.url!.lastIndexOf("/") + 1)
    );
  };

  const handleCancel = () => setPreviewOpen(false);

  return (
    <Form.Item {...props} className={style["field"]}>
      <Upload
        listType="picture-circle"
        defaultFileList={defaultFileList}
        {...props.uploadProps}
        onPreview={
          props.uploadProps.onPreview!
            ? props.uploadProps.onPreview
            : handlePreview
        }>
        + Upload
      </Upload>
      <Modal
        open={previewOpen}
        title={previewTitle}
        footer={null}
        onCancel={handleCancel}>
        <img alt="Preview image" style={{ width: "100%" }} src={previewImage} />
      </Modal>
    </Form.Item>
  );
};

export { UploadFileField };
export type { IUploadFileFieldProps };
