import style from "./ResetPassword.module.scss";
import ImageRegister from "../../../../components/images/register/image-register.svg";
import { ResetPasswordForm } from "./reset-password-form/ResetPasswordForm";
import { ConfigProvider, theme } from "antd";
import { useWindowSize } from "../../../../contexts/useWindowSize";

const { defaultAlgorithm } = theme;

export const ResetPassword = () => {

  return (
    <ConfigProvider
      theme={{
        algorithm: defaultAlgorithm,
        token: {
          colorPrimary: "#1890FF",
          colorLink: "#1890FF",
          borderRadius: 10,
        },
      }}>
      <div
        className={style.main}>
        <img src={ImageRegister} className={style.image} />
        <ResetPasswordForm />
      </div>
    </ConfigProvider>
  );
};
