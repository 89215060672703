export function flattenTreeToList<T>(tree: T[], field: string): T[] {
  const result: T[] = [];
  for (let i = 0; i < tree.length; i++) {
    result.push(tree[i]);

    const chieldren = (tree[i] as any)[field];
    if (Array.isArray(chieldren)) {
      result.push(...flattenTreeToList<T>(chieldren, field));
    }
  }
  return result;
}

export const groupBy = (data: any, key: string, key1: string) => {
  return data.reduce(function (acc: any, item: any) {
    if (!Array.isArray(acc)) acc = [];
    acc = acc || [];
    const exist = acc.find((_: any) => _.name === item[key][key1]);
    if (exist) {
      exist.events.push(item);
    } else {
      acc.push({
        name: item[key][key1],
        order: item[key]["order"],
        events: [item],
      });
    }
    return Array.isArray(acc) ? acc : [];
  }, {});
};
